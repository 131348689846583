import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 获取项目中所有成员
export function get_project_members(projectId) {
    const data = {
        projectId
    }
    return request({
        url: `/${serviceConfig['project-service']}/allProjectMember`,
        method: 'get',
        params: data,
    })
}

// 修改项目成员
export function update_project_member(accountId, projectId, jobId, roleId) {
    const data = {
        accountId,
        projectId,
        jobId: jobId,
        projectRole: roleId
    }
    return request({
        url: `/${serviceConfig['project-service']}/project/projectMember`,
        method: 'put',
        data: data,
    })
}

// 添加项目成员
export function add_project_member(projectId, addMemberList) {
    const data = {
        "memberDetailDtoList": addMemberList,
        projectId
    }
    return request({
        url: `/${serviceConfig['project-service']}/projectMember`,
        method: 'post',
        data: data,
    })
}

// 删除项目成员
export function delete_project_member(projectId, accountId) {
    return request({
        url: `/${serviceConfig['project-service']}/${projectId}/projectMember/${accountId}`,
        method: 'delete',
    })
}