import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'
// 修改用户信息
export function update_user_info(data) {
  return request({
    url: `/${serviceConfig['account-service']}/userInfo`,

    method: "put",
    data: data,
  });
}

// 删除用户
export function delete_user(accountId) {
  return request({
    url: `/${serviceConfig['account-service']}/user`,

    method: "delete",
    data: {
      accountId,
    },
  });
}

// 获取租户用户列表
export function get_tenant_user_list(params) {
  return request({
    url: `/${serviceConfig['account-service']}/tenant/usersPageList`,
    params,
    method: "get",

  });
}
// 获取租户用户列表(不分页)
export function get_tenant_user_list_nopage(params) {
  return request({
    url: `/${serviceConfig['account-service']}/tenant/usersList`,
    params,
    method: "get",

  });
}

//获取租户下所有用户组列表(不分页)
export function get_tenant_user_group_list_nopage() {
  return request({
    url: `${serviceConfig['account-service']}/userGroups/all`,
    method: "get",

  });
}

// 获取租户机器人列表(不分页)
export function getSpecialUsers(params) {
  return request({
    url: `/${serviceConfig['account-service']}/specialUsers`,
    params,
    method: "get",

  });
}
// 模糊搜索用户列表
export function name_search_list(params) {
  return request({
    url: `/${serviceConfig['account-service']}/tenant/usersPageFuzzyList?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}&fuzzyName=${params.fuzzyName}`,
    method: "get",

  });
}
//根据excel批量导入用户
export function importUserMultiple(params) {
  return request({
    url: `/${serviceConfig['account-service']}/users/excelImportUsers`,
    method: "post",

    data: params
  });
}

// 管理员重置成员密码
export function reset_user_password(data) {
  return request({
    url: `/${serviceConfig['account-service']}/resetPassword`,
    method: "put",

    data
  });
}
// 记录最后登录时间
export function activated() {
  return request({
    url: `/${serviceConfig['account-service']}/activated`,
    method: "post",
  });
}
// 设置语言
export function setLanguage(params) {
  return request({
    url: `/${serviceConfig['account-service']}/language`,
    method: "PUT",
    params
  });
}
// 注销用户
export function deregister(params) {
  return request({
    url: `/${serviceConfig['account-service']}/tenant`,
    method: "DELETE",
    params
  });
}