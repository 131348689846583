import config from "@/../config/index";
export default {
  tip: 'No permission. Please contact the administrator',
  loading: {
    loading1: 'Loading at full split ',
    loading2: 'Loading...',
    loading3: 'The data is being loaded...',
    loading7: 'Uploading images...',
    loading4: 'Diagrams are being configured. Please do not refresh...',
    loading5: 'Uploading',
    loading5: 'Please be patient as this may take some time.'
  },
  placeholder: {
    dropdown: 'Please select',
    input: 'Please enter content',
    date: 'Select a date',
    name: 'Please enter a name',
    time: 'Please select a time',
    number: 'Please enter a number',
    table1: 'No data available now',
    table2: 'No data',
    search: 'Search',
    notNull: 'This field cannot be empty.',
    notLength: 'No more than 5 characters long.',
    onlyAlge: 'Letters only.',
    onlyAlgeNum: 'Must start/end with a letter or number; can include underscores.',
    null: 'Nothing',
    open: 'Open platform',
    index: 'You do not have permission to view the current page or the current page does not exist.',
  },
  btn: {
    cancelBtn: 'Cancel',
    confirmBtn: 'Confirm',
    addBtn: 'Add',
    newBtn: 'Create',
    movebtn: 'Move',
    importBtn: 'Import',
    exportBtn: 'Export',
    pasteBtn: 'Paste',
    copyBtn: 'Copy',
    editBtn: 'Edit',
    exitEditBtn: 'Exit',
    sendBtn: 'Send',
    saveBtn: 'Save',
    uploadBtn: 'Upload',
    uploadFile: 'Upload file',
    deleteBtn: 'Delete',
    ResetPassword: 'Reset password',
    forceDeleteBtn: 'Delete permanently',
    revertBtn: 'Recover',
    optionBtn: 'Option',
    batchBtn: 'Batch operating',
    exitBtn: 'Exit',
    modify: 'Modify',
    executeBtn: 'Execute',
    startBtn: 'Start',
    endBtn: 'End',
    completeBtn: 'Complete',
    closeBtn: 'Close',
    jumpBtn: 'Skip',
    replyBtn: 'Reply',
    exitExecuteBtn: 'Exit execution mode',
    returnExeBtn: 'Return to execution',
    next: 'next',
    prev: 'previous',
    noLongerDisplayed: 'Never show',
    Customize: 'Customize',
    view: 'view',
    insert: 'insert',
    export_tem: 'Export template',
    tem: 'Customize export template'
  },
  model_view: {
    de3f: 'Default template',
    tip: 'When exporting files from the system, you can choose the template pre-set here. You can also upload custom templates',
    tip2: "Only supports Word and Excel files",
    tip3: 'Duplicate file upload failed',
    tip4: 'Please select file type and status',
    font_size: "Font Size",
    font_decoration: "Font Decoration",
    font_weight: "Font Weight",
    text_align: "Text Alignment",
    start: "L",
    center: "C",
    end: "R",
    up: "T",
    down: "B",
    justify_content: "Horizontal Position",
    align_items: "Vertical Position",
    font_color: "Font Color",
    font_style: "Italic",
    line_height: "Line Height",
    font_indent: "Indentation",
    background_color: "Background Color",
    border_color: "Border Color",
    border_weight: "Border Width",
    border_type: "Border Style",
    border_radius: "Border Radius",
    shadow_color: "Shadow Color",
    shadow_x: "X Offset",
    shadow_y: "Y Offset",
    shadow_blur: "Blur",
    shadow_size: "Spread",
    to_top: "To Front",
    to_bottom: "To Back",
    up_Zindex: "Up",
    down_Zindex: "Down",
    stroke: "Stroke Color",
    italicoo: 'Enable',
    italicno: 'Disable',
    strokeWidth: "Stroke Width",
    targetMarker: "Arrow Style",
    strokeDasharray: "Line Style",
    connector: "Connector Style",
    add_link_visiable: "Add Link",
    remove_link_visiable: "Remove Link",
    is_show_model_dialog: "Add Template",
    remove_link: "Are you sure you want to remove the link?",
    t1: "Text settings",
    t2: "Background Settings",
    t3: "Position Settings",
    t4: "Edge Settings",
    t5: "Table Settings",
    base: "Basic",
    base1: "Rounded Rectangle",
    base2: "Diamond",
    base3: "Circle",
    base4: "Ellipse",
    base5: "Square",
    base6: "Text",
    base7: "Arrow",
    base8: "Trapezoid",
    base9: "Triangle",
    base10: "", // This should ideally have a meaningful label  
    base11: "Curve",
    base12: "Draw", // This should ideally have a meaningful label  
    base13: "Table",
    base14: "People",
    cata1: 'Presentation',
    cata2: 'Collaborative',
    cata3: 'Development',
    cata4: 'Other',
    cata5: '',
    high1: "Export",
    high2: "Flowchart",
    high3: "Timeline Point",
    high4: "Timeline",
    high5: "Swimlane Diagram",
    high6: "Four Quadrants",
    high7: "Architecture Diagram",
    high8: "Mind map",
    high9: "Web browser",
    high10: "iPhone",
    high11: "Organizational Chart",
    high12: "Tourist Route Map",
    high13: "",
    high14: "",
    high15: "",
    font_decoration1: {
      none: "None",
      overline: "Overline",
      line: "Line-through", // Note: "line" was changed to "Line-through" for clarity  
      underline: "Underline",
    },
    border_type1: {
      none: "None",
      dotted: "Dotted",
      dashed: "Dashed",
      solid: "Solid",
      double: "Double",
      groove: "Groove",
      ridge: "Ridge",
      inset: "Inset",
      outset: "Outset",
    },
    operate_dialog_name: {
      node: "Node Settings",
      edge: "Edge Settings",
      table: "Table Settings",
    },
    targetMarker1: {
      null: "None",
      block: "Solid Arrow",
      classic: "Classic Arrow",
      diamond: "Diamond Arrow",
      cross: "Cross Arrow",
      circle: "Circle Arrow",
      circlePlus: "Circle Plus Arrow",
      ellipse: "Ellipse",
    },
    strokeLinejoin: {
      normal: "Normal Routing",
      orth: "Orthogonal Routing",
      oneSide: "One-sided Routing",
      manhattan: "Manhattan Routing",
      metro: "Metro Routing",
      er: "Zigzag Routing",
    },
    strokeDasharray1: {
      n1: "Solid",
      n2: "Dashed",
      // Note: Consider using more descriptive keys like "solid" and "dashed" instead of "n1" and "n2"  
    },
    connector1: {
      n1: 'Normal',
      n2: 'Rounded',
      n3: 'Jump',
      // Note: Consider using more descriptive keys that match their intended function  
    },
  },
  menu: { // Left menu
    file: "File",
    node: "Node",
    release: "Release",
    baseline: 'Baseline',
    version: 'Version',
    test: 'Test',
    board: 'Agile',
    chart: 'Dashboard',
    bin: 'Recycle Bin',
    setting: 'Settings',
    projectDetails: 'About Project',
    user: 'User',
    permission: 'Permission',
    settingFile: 'File',
    fileType: 'File Type',
    fieldLab: 'Field Library',
    changeRequest: 'Change Request',
    unfold: 'Unfold',
    reviewSettings: 'Review Settings',
    permissionSettings: 'Permission Settings',
    workbench: 'Workbench',
    Notify: 'Notify',
    userManagement: 'User Management',
    projectManagement: 'Project Management',
    thirdParty: 'Third Party Application Management',
    certificateManagement: 'License Management',
    wordview: 'Word View',
    FunctionalSafety: 'Safety & Security',
    DFMEA: 'DFMEA',
    asyncTask: 'Task list',
    question: "User manual",
    shortcuts: 'Shortcuts',
    addShortcut: 'Add shortcut',
    link: 'Link',
    name: 'Name'
  },

  homeTopBar: { // Homepage topBar
    newProjectForm: { // New project form
      title: ' Add project',
      name: 'Project name',
      key: 'Project KEY',
      agileType: 'Agile type',
      template: 'Project template',
      avatar: 'Project avatar',
      member: 'Project member',
      editBtn: 'Modify',
      agileDesc: 'Scrum: a model of agile development, which includes the concept of Sprint.',
      kanban: 'kanban: a model of agile development, which does not include the concept of Sprint.',
      tempDesc: 'You cannot select an agile type after having selected a template.',
      message: {
        nameError: 'Item name already exists',
        idError: 'Item ID already exists',
        keyError: "Project Key already exists",
        nonError: 'Current project does not exist',
        avatarError: 'Upload an avatar image no larger than 1MB in size!',
        uploadError: 'Upload failed',
        uploadError2: 'There was an upload error and the server ran away',
        addSuccess: 'Added',
        addError: 'Failed to add',
      }
    },
    newFileForm: { // New file form
      tab1: 'New file',
      tab2: 'Batch import',
      tab3: 'New related file',
      name: 'Please enter the file name',
      type: 'Please select the file type',
      plan: 'Please select the test plan',
      case: 'Please select the test case',
      field1: 'Assignee',
      field2: 'Description',
      newNode: 'New node',
      excel: {
        title: 'Operating notes',
        p1: '1. The node location and node name columns are required and deleting or leaving them empty will cause import failed.',
        p2: '2. The values of the fields in excel are user definable, when importing, the user has to specify the correspondence with the fields in the floating window.',
        p3: 'Please download the import template:',
        p4: 'Import Template.xlsx',
        p5: 'Excel import notice:',
        p6: '3.The imported Excel file cannot exceed 20MB.',
        p7: 'Word import notice:',
        p8: '1.The imported file format must be docx.',
        p9: '2.The imported Word file should not exceed 20MB.',
        p10: 'Import Template.docx',
        p11: 'xmind import notice:',
        p12: '1.The imported file format must be xmind.',
        p13: 'freemind import notice:',
        p14: '1.The imported file format must be mm.',
        excelField: 'Excel fields',
        windowField: 'Floating window field',
        sysField: 'System fields',
        select: 'Please select the floating window field'
      },
      mgpt: {
        requirement: 'Requirement',
        repositoryUrl: 'Repository url',
        repositoryType: 'Repository type',
        tip1: 'If you specify a repository address, we will deposit the generated software code directly into the repository, please check the codes on the branch of feature/mgpt; if no repository is specified, the code will be placed in the project wiki by default',
        tip2: 'Presonal access token， generally set them in the setting page，please check the documentation of each Git software. e.g. gitlab- Click avatar - Edit profile - Access Tokens - Add new token - api',
        mgptM1: 'Please enter requirement',
        mgptM2: 'Please enter repository type',
        mgptM3: 'Please enter token',
      },
      limit: 'The uploaded file can’t exceed 20MB',
      message: 'Created!',
      templatePlace: 'Click the button on the right to select a template'
    },
    menu: { // Avatar drop-down menu
      profile: ' Profile',
      blue: 'blue（default）',
      dark: 'dark',
      light: 'light',
      logout: 'Log out'
    },
    asyncTask: {
      index: "No.",
      type: "Type",
      status: "Status",
      startTime: "Start time",
      endTime: "End time",
      errorMessage: "Error message",
      response: "Result",
      message: 'The task is in progress. Please enter the task list in the upper right corner to check progress.'
    },
    search: 'Search',
    file: 'File',
    node: 'Node',
    knowledge: 'Article',
    language: 'Simplified chinese'
  },
  hara: {
    lang: "en",
    tip: 'Fault database',
    tip1: 'Project administrators can customize more fault data to better meet product and industry requirements.',
    label1: "Function",
    label2: "Malfunction",
    label3: "Risk",
    label4: "Security objectives",
    export: "HARA analysis",
    notice: "HARA:  You received a HARA security objective:  ",
    ms1: 'Purchase tips',
    ms2: 'Hara is a paid feature, please contact customer service to activate.',
    p1: "Please enter the malfunction",
    p2: "Please enter the risk",
    p3: "Please enter the security objectives",
    p4: "Please enter a number",
    p5: "Please enter the security status",
    t1: "Risk Analysis",
    t2: "Severity",
    t3: "Exposure",
    t4: "Controllability",
    t5: "Functional security level",
    t6: "Security status",
    t7: "Security level",
    ReceivedTasks: "Received security objectives",
    MyAssignedTasks: "My assigned security objectives",
    OptimizationSteps: "All security objectives",
  },
  tara: {
    tara_lang: "en",
    set: 'Settings',
    edit: 'Edit',
    tip1: 'Project administrators can customize more attack paths to better meet product and industry requirements.',
    tip2: 'Project administrators can add more risk management measures to better meet product and industry requirements.',
    tip: "In preview mode, click on the header below to quickly locate the corresponding position",
    notice: "TARA: You have received a TARA risk disposal assignment. TARA assets: ",
    ms1: 'Purchase tips',
    ms2: 'Tara is a paid feature, please contact customer service to activate.',
    MyAssignedTasks: "Created tasks",
    OptimizationSteps: 'All risk measures',
    export: "TARA Analysis",
    null: "None",
    t1: "Safe asset",
    t2: "Risk treatment decision",
    t3: "Acceptance",
    t4: "Reduction",
    t5: "Avoidance",
    t6: "Transference",
    t7: "Status",
    t8: "TO DO",
    t9: "IN PROGRESS",
    t10: "DONE",
    t11: "Promoter",
    t12: "Associated tasks",
    t13: "Assignee",
    t14: "Asset",
    t15: "Preview",
    t16: "Hide",
    t17: "Delete Node",
    t18: "Are you sure to delete it? If there are child nodes in this node, they will be deleted together. ",
    t19: "Cybersecurity property",
    t20: "Confidentiality",
    t21: "Integrity",
    t22: "Availability",
    t23: "Impact categories",
    t24: "Damage Scenario",
    t25: "Please enter the damage scenario",
    t26: "Safety",
    t27: "Please select",
    t28: "Financial",
    t29: "Operational",
    t30: "Privacy",
    t31: "Impact level",
    t32: "Threat scenario",
    t33: "Please enter the threat scenario",
    t34: "Select preset text to append to input content",
    t35: "Attack feasibility rating",
    t36: "Attack path",
    t37: "Please enter the attack path",
    t38: "Elapsed time",
    t39: "Specialist expertise",
    t40: "Knowledge of the item or component",
    t41: "Window of opportunity",
    t42: "Equipment",
    t43: "Attack feasibility level",
    t44: "Risk value",
    t45: "Risk treatment measure",
    t46: "Please enter the risk treatment measure",
    t47: "Cybersecurity goals",
    t48: "Cybersecurity requirements",
    t49: "Cybersecurity statements",
    t50: "Assignee",
    t51: "Associated tasks",
    t52: "Please enter the cybersecurity statements",
    t53: "Please enter the cybersecurity requirements ",
    t54: "Please enter the cybersecurity goals",
    t55: "Cybersecurity property",
    t56: "Damage Scenario",
    t57: "Threat scenario",
    t58: "Attack path",
    t59: "Risk value",
    t60: "Whether to close assets",
    t61: "Tip",
    t62: "Confirm",
    t63: "Cancel",
    t64: "Index",
  },
  fta: {
    title: "FTA",
    calculate: "FTA Calculation",
    mincut: "Minimum cut set",
    minpath: "Minimum path set",
    structural: "Structural importance",
    topevent: "Probability of top event occurrence",
    warning: "There are at least two nodes behind each gate. Please exit the mind map mode to modify",
    node: "Node",
    ms1: 'Purchase tips',
    ms2: 'FTA is a paid feature, please contact customer service to activate.',
    text1: "Remaining",
    text2: " gate(s) not filled in",
    text3: "Importance",
    text4: "Event name",
    text5: "Probability of occurrence",
    text6: "Enter a probability from 0 to 1",
    text7: "Reset",
    text8: " not filled in",
    text9: "Calculate",
    text10: "Calculation result:",
    text11: "Probability cannot be empty",
    text12: "Please enter a numerical value from 0 to 1",
    type: "english",
    or_door: "OR",
    and_door: "AND",
    null_door: "NULL",

  },
  share: {
    share_tip: 'Tip',
    know_1: 'Get to know us',
    share_word: 'Download Word',
    share_down: 'Download mind maps',
    share_open: "The download mind map operation needs to be done in the browser. Please click on the top right corner -> Open in the browser.",
    sharetip: 'This function is used to generate an external link that others can click on to view your mind map, but not modify it. You can also paste the link to the Feishu document and preview the MappingSpace file in real-time in Feishu.',
    shareword: "Using web pages for better opening effects",
    btn: 'Sharing page',
    send: 'Successfully',
    word: 'Switch to word view',
    map: 'Switch to mind map',
    word1: 'Word view',
    map1: 'Mind map',
    mulu: 'Contents',
    save: 'Save to MappingSpace',
    s1: "This sharing has been revoked. Please contact the sharer.",
    s2: "Email notification sharer",
    des: "Description",
    nodata: "No data",
    pic: "Architecture diagram",
    pro: 'Please select a project',
    notice: 'You have received a request for external sharing: ',
    file: "Please select a file type",
    d3: 'This mind map has expired for 3 days. If you need to view the complete content or create a new one, please save it to your own Mapping',
    ti: 'Tip',
    tip: 'If you need to view the complete content or create a second creation, please save it to your own MappingSpace.',
    date: 'Sharing date',
    fname: 'File Name',
    people: 'Sharers',
    nosha: 'Cancel sharing',
    summer: 'Mind map sharing data summary',
    phone_tip: 'To enjoy a better viewing experience, please visit the official website of Yunti Technology or MappingSpace and log in on your computer for a closer look. www.ms.ytdevops.com',
    know: 'OK'
  },
  home: { // My workbench
    title: 'My workbench',
    myChangeRequest: 'Change requests',
    myCollection: 'My favorites',
    myResponsibility: 'Assignee me',
    myAt: '@Me',
    myFile: 'My file',
    myNode: 'My node',
    personalDataSummary: 'Personal data summary',
    projectDataSummary: 'Project data summary',
    projectName: 'Project name',
    numberOfPeople: 'Number of people',
    numberOfFiles: 'Number of files',
    numberOfNodes: 'Number of nodes',
    nodeResponsiblePerson: 'Node-responsible person',
    workHoursStatistics: 'Work hours statistics',
    plannedWorkHours: 'Planned work hours',
    actualRegisteredWorkHours: 'Actual registered work hours',
    nodeCompletionStatus: 'Completion status of the responsible person\'s nodes',
    sharingDataSummary: 'Summary of mind map sharing data',
    sharer: 'Sharer',
    sharingTime: 'Sharing time',
    sharingPermission: 'Sharing permission',
    status: {
      notStarted: 'Not started',
      inProgress: 'In progress',
      completed: 'Completed',
    },
    total: "Total",
    update_1: 'Upgrade to a new version',
    update_2: 'More version records',
    update_3: 'The updated content of the new version is as follows:'
  },

  file: { // File
    top: { // Top button
      search: 'Search',
      type: 'Type',
      creator: 'Creator',
      status: 'Status',
      clearBtn: 'Reset',
      createBtn: 'Save filter',
      anoSaveBtn: 'Save as',
      select: 'Filter fields',
      viewAll: 'View all',
      saveBtn: 'Save filter',
      confirmForm: {
        name: 'Name',
        placeholder: 'Please input name'
      }
    },
    dialog: {
      condition: 'Filter',
      name: 'Name',
      creator: 'Creator',
      option: 'Option',
      invite: 'Invite your friends to MappingSpace！',
      tip1: 'Unspecified fields cannot be imported. Are you sure you want to import?',
      tip2: 'Attention',
    },
    table: { // File table
      type: 'File type',
      name: 'File name',
      status: 'Status',
      creator: 'Creator',
      lastUpdated: 'Updated time',
      options: {
        collaboration: 'Collaborate',
        delete: 'Delete',
        deleteForm: {
          title: 'Tips',
          desc: 'Are you sure you want to delete the file? A deleted file can only be recovered with the administrator\'s permission',
          message: 'The file has been moved to the recycle bin'
        },
        paste: 'Copy',
        collectList: 'Favorite list',
        message: 'Paste is successful.It is refreshing now',
        collect: 'Favourite',
        nonCollect: 'Favourite',
        download: 'Download',
        move: 'Move',
        moveForm: {
          title: 'Move file',
          target: 'Target project',
          fieldSetting: 'Field mapping',
          origin: 'Original project',
          file: 'Original file',
          save: 'Reserve',
          tip1: 'No fields of the same corresponding type in the target project',
          tip2: 'Incomplete values in the target item, data may be lost after the move',
          // 改动
          tip3: 'The target project field is not fully specified',
          tip4: 'If the file contains nodes in a baseline that has been started, deletion will not be possible.  It is required for the baseline to be completed, then the file would need to be deleted manually after'
        },
        export: 'Export',
        dialog: {
          title: 'Excel export options',
          title2: 'Batch modify fields',
          title3: 'Word export options',
          box1: 'Select all',
          box2: 'Node position',
          box3: 'Node key',
          box4: 'Node name',
          box5: 'Cancel',
          box6: 'Export',
          box7: 'Predecessors',
          box8: 'Scheduled start time',
          box9: 'Scheduled end time',
          box10: 'Assignee',
          box11: 'Status',
          box12: 'Index',
          box13: 'Planned time',
          box14: 'Cumulative time',
          box15: 'Progress',
        },
        message1: 'Operation column can‘t be moved',
        message2: 'Exporting',
        addToBaseLine: 'Add to baseline',
        targetBaseLine: 'Target baseline'
      },
    }
  },

  expire: { // 软件过期
    expire_notice1: "Only",
    expire_notice2: "days left until software expiration. Please contact customer service for renewal.",
    buy: "Please scan the customer service WeChat QR code.",
    tip1: 'Please scan the customer service WeChat QR code to make a purchase.',
    tip2: 'Or email:',
    tip3: 'Information not available',
    tip4: 'Contact us via Wechat '
  },

  canvas: { // Canvas page
    tip: 'Press ENTER to add sibling nodes, press TAB to add child nodes, press DELETE to delete nodes, press Ctrl+C to copy nodes, press Ctrl+X to cut nodes, press Ctrl+V to paste nodes, press Ctrl+F to search nodes, right click to perform batch operations on multiple selected nodes',
    tip1: 'Zoom in',
    tip2: 'Zoom out',
    tip3: 'Node move up',
    tip4: 'Node move down',
    tip5: 'Alignment',
    tip6: 'Full screen',
    tip7: "Add child node",
    tip8: "View User Guide",
    tip9: "Select nodes by clicking the selection box on the left side of the node, Enter to add a sibling node, Tab to add a child node, Delete to delete a node, Ctrl+F to search nodes, Ctrl+A to select all nodes or all child nodes of the selected node, right click to perform batch operations on multiple selected nodes",
    tip10: "Locked nodes can not be moved",
    tip11: "Root node can not be moved",
    tip12: "Please select one node",
    tip13: "Default Word",
    tip14: "Default mind map",
    tip15: "The left side is the directory tree, and the right side is the node detail page. Clicking on the left directory tree will automatically switch the node detail page on the right side",
    tip16: 'Select the graphic element on the right and right-click to associate nodes. Hold down the ALT key while dragging a line to turn it into a polyline and change its direction',
    mode: {
      edit: 'Edit mode',
      read: 'View mode',
      review: 'Review mode'
    },
    viewResult: {
      pass: 'Pass',
      fail: 'Fail',
      reason: 'Reasons for not passing',
      message2: 'Failed to save',
      reviewing: 'Reviewing',
      nopass: 'Not passed',
      noreviewing: 'Not reviewed',
      res: "Detail",
      people: "people"
    },
    btn: { // Upper button
      file: 'File',
      view: 'View',
      floating: 'Floating window',
      executeTestPlan: 'Execute test plan',
      reviewRequest: 'Request review',
      reviewResult: 'Review result',
      clear: "Clear Selected"

    },
    fileDropDown: { // File button dropdown menu
      paste: 'Copy',
      delete: 'Delete',
      export: 'Export',
      creatTemplate: 'Save as new template',
      creatTemplatePlace: 'Please enter a template name',
      creatTemplatePlace1: 'Please enter template information',
      templatename: 'Template name',
      templatedescript: 'Template description',
      descriptplace: 'Please enter a template description',
      deleteForm: {
        title: 'Tips',
        desc: 'Are you sure you want to delete the file? A deleted file can only be recovered with the administrator\'s permission',
      }
    },
    viewDropDown: { // View button dropdown menu
      showStatus: 'Status',
      detectRequired: 'Detect required fields',
      Reusesituation: 'Reuse situation',
      coverage: 'Coverage',
      baseline: 'Baseline',
      responsibility: 'Assignee',
      wordView: 'Word view',
      mindView: 'Mind view',
      showStatus_shortcutKey: 'Ctrl+Alt+S',
      detectRequired_shortcutKey: 'Alt+M',
      Sprint_shortcutKey: 'Alt+P',
      responsibility_shortcutKey: 'Alt+R',
      wordView_shortcutKey: 'Alt+W',
      DFMEAView: 'DFMEA',
      modelView: 'Model view',
      treeView: 'Tree view',
    },
    excel: {
      setting: 'Setting up customisation',
      title: 'Please select the form you wish to import',
      mindmapName: 'Mind map name',
      note: 'Remarks'
    },
    reviewRequestForm: { // Request review form
      title: 'Request review',
      placeholder: 'Please select the reviewer',
      note: 'Permission: review',
      title2: 'Review member',
      num: 'Reviewer',
      savingState: 'All changes have been saved',
      savingState2: 'Saving...',
      tip1: 'Please enter the reason for not passing',
      tip2: 'Please select the inviter',
      tip3: 'Invitation successful.',
      tip4: 'nodes are either under review or already passed and cannot be review.',
      tip5: 'Invitation successful',
      tip6: 'No review rules specified for this file type. Please add rules ',
      tip7: "Please choose pass/fail",
      tip8: 'Review item pass rate',
      no: 'Fail',
      yes: 'Pass',
      all_yes: 'All selected to pass, are you sure to continue?',
      all_no: 'All selections failed, are you sure to continue?',
    },
    floatWindow: { // Floating window
      creator: 'Creator',
      workflowNone: 'No data',
      workflowView: 'View workflow',
      testcase: 'Test case',
      form: { // Form
        responsibility: 'Assignee',
        deadline: 'Deadline',
        priority: 'Priority',
        placeholder: 'Please select',
        placeholder2: 'Select date'
      }
    },
    rightClick: { // Right-click menu
      statusChange: 'Status',
      statusChangeForm: {
        title: 'Status change',
        num: 'Layer',
        layer: '',
        original: 'Original',
        new: 'Switch to status'
      },
      responsibility: 'Assignee',
      responsibilityForm: {
        title: 'Edit field',
        edit1: 'Modify',
        edit: 'Modify the assignee'
      },
      baseline: 'Baseline',
      targetVersion: 'Target version',
      node: 'Related node',
      addRelatedNode: 'Add related nodes',
      others: 'Edit other fields',
      message: 'The node is locked, you cannot modify other fields',
      othersChild: {
        priority: 'Priority',
        deadline: 'Deadline',
      },
      moveNode: 'Move node',
      moveNodeForm: {
        title: 'Move node',
        edit: 'Target node',
        placeholder: 'Please input the key of the node to be moved'
      },
      testPlan: 'Test plan',
      dfmeaTable: 'DFMEA',
    },
    dfmea: {
      step1: "Planning and preparation (Step 1)",
      companyName: "Company name",
      project: "Subject",
      location: "Engineering location",
      openDate: "DFMEA start date",
      id: "DFMEA ID",
      customerName: "Customer name",
      updateDate: "DFMEA revision date",
      responsibility: "Design responsibility",
      yearProject: "Model year / platform",
      group: "Cross-functional team",
      secretLevel: "Confidentiality level",
      step2: "Structural analysis (Step 2)",
      lastLevel: "Next higher level",
      focusElement: "Focus element",
      nextLevel: "Next lower level or characteristic type",
      details: "Details",
      step3: "Function analysis (Step 3)",
      lastLevel3: "Next higher level function and requirement",
      focusElement3: "Focus element function and requirement",
      nextLevel3: "Next lower level function and requirement or characteristic",
      step4: "Failure analysis (Step 4)",
      fe: "Failure effects (FE) to the next higher level element and/or vehicle end user",
      severity: "Severity (S) of FE",
      fm: "Failure mode (FM) of the focus element",
      fc: "Failure cause (FC) of the next lower level element or characteristic",
      step5: "DFMEA risk analysis (Step 5) and optimization (Step 6)",
      pc5: "Prevention controls (PC) of FC",
      frequency5: "Occurrence (O) of FC",
      dc5: "Detection controls (DC) of FC or FM",
      detection5: "Detection (D) of FC/FM",
      priority: "DFMEA AP",
      code: "Filter code (optional)",
      step6: "DFMEA optimization (Step 6)",
      pc6: "Prevention controls (PC) of FC",
      frequency6: "Occurrence (O) of FC",
      dc6: "Detection controls (DC) of FC or FM",
      detection6: "Detection (D) of FC/FM",
      priority6: "DFMEA AP",
      assignee: "Assignee",
      targetDate: "Target completion date",
      status: "Status",
      method: "Action taken with pointer to evidence",
      associatedTasks: "Associated tasks",
      completeDate: "Completion date",
      note: "Remarks",
      step7: "Export result (Step 7)",
      validError: "There are required fields in Step 6 that are not filled in. Leaving directly will not save this optimization measure. Are you sure you want to leave?",
      validError2: "There are required fields in Step 6 that are not filled in. Step 6 cannot be exported. Are you sure you want to proceed with the export?",
      apMessage: "Please fill in the severity, occurrence, and detection first.",
      emptyMessage: "The current node does not contain this field, please contact the project administrator.",
      editMessage: "Modifying the current value may affect other DFMEA forms within the file. Are you sure you want to modify it?",
      structureTree: ' structure tree',
      index: 'Index',
      pleaseEnterNumber: 'please enter a number 1-10',
      severitySettings: 'Severity settings',
      severityIs: 'Severity (S) is',
      cannotBeEmpty: 'Cannot be empty',
      extremelyHigh: 'Extremely high',
      extremelyLow: 'Extremely low',
      veryHigh: 'very high',
      veryLow: 'Very low',
      sameMessage1: 'In the current table, line ',
      sameMessage2: ' and line ',
      sameMessage3: ' data in the 1-2-3-4-5-6-7-9-10 column is identical, which may be repeated. Do you want to keep it?',
      reserve: 'Reserve',
      deleted: 'Deleted',
      cancelDelete: 'Cancel',
      cannotBeRestored: 'It cannot be restored. Are you sure to delete it?',
      associate1: ' greater than expected',
      associate2: ' less than expected value',
      associate3: ' accompanying other unnecessary events',
      associate4: ' opposite direction',
      associate5: ' partial not working',
      associate6: ' other abnormalities occurred',
      associate7: ' not working',
      removeNodeMessage: 'There are associated failures under the current function. After deleting this function, the failures will be synchronously deleted. Are you sure to delete this function?',
      functionDescription: 'Function description',
      associationMessage1: ' the current structure has ',
      associationMessage2: ' functions have not yet been associated with superior and subordinate functions, has ',
      associationMessage3: ' failures has not yet been associated with a superior or subordinate failures',
      associationMessage4: 'Corrected ',
      associationMessage5: ' severity values,',
    },
    wordView: { // Word view
      unfoldBtn: 'Unfold',
      foldBtn: 'Fold',
      node: {
        desc: 'Description',
        placeholder: 'Please add description'
      },
      need_has: "Covered",
      can_cover: "Coverable",
      need_no: "Not covered",
      no_need: "No need to cover",
      null: "NULL",
      status: "Status",
      assignee: "Assignee",
      sprintId: "Sprint",
      coverage: "Coverage",
    },
    testPlan: { // Test plan related
      selectBtn: 'Please select a test plan',
      warning: 'The following test plans are not executable because no Assignee is specified',
      info: 'The highlighted node is in this test plan. Please execute the test plan',
      lock: 'The node is locked',
      status: {
        notStarted: 'Not started',
        inProgress: 'In progress',
        completed: 'Completed',
        uncompleted: 'Not completed',
        started: 'Started',
        ended: 'Ended',
        notPublished: 'Not released',
        published: 'Released'
      },
      executeStatus: {
        UN_EXECUTED: 'Not executed',
        APPROVED: 'Passed',
        BLOCKED: 'Blocked',
        FAILED: 'Failed',
        NA: 'NA',
      },
      floatWindow: { // Options in the floating window
        result: 'Test result',
        status: 'Execution status',
        editError: 'The node is not editable because it is not in the current test plan',
        executeError: 'The node is not executable because it is not in the current test plan'
      },
      testStepForm: {
        template: 'Test template',
        title: 'List of test steps',
        editBtn: 'Edit',
        forward: 'Preconditions',
        testStep: 'Test step',
        antiResult: 'Expected result'
      },
      message: {
        selectWarn: 'Please select a test plan',
        warn: "This node was introduced by Sprint and cannot be removed, please go to Sprint to remove it",
        executionFailed: 'Execution failed',
        click: 'Click',
        automaticallyCreat: ' automatically create and associate bug',
      },
      deleteRelationBugMess: 'Are you sure to delete the bug?',
      selectRelationBugMess: 'Please select the associated bug',
      newBug: 'Create Bug',
      notWork: ' not working'
    },
    import: 'It can be imported',
    messages: { // Prompt message
      noForward: 'You cannot go forward further',
      noBack: 'You have returned to the end',
      deleteSuccess: 'The file has been moved to the recycle bin',
      delSuccess: 'File recycled',
      delSuccess1: '<p><strong>Some files failed to be deleted, please check the following reasons</strong></p></br><p>&nbsp2.If any nodes in the file are in the started baseline</p></br><p>&nbsp2.No permission to delete the file type</p>',
      delError: 'Recycle failed',
      delCancel: 'Recycle cancelled',
      deleteInfo: 'Deletion canceled',
      renameSuccess: 'Successfully modified.',
      importSuccess: 'Import is successful',
      importWarn1: 'Not a mind view file',
      importWarn2: 'Empty field exists',
      importWarn3: 'Illegal child node exists',
      importWarn4: 'Undefined content exists',
      importWarn5: 'Repeated node key exists',
      exportSuccess: 'Template export is successful',
      requiredWarn1: 'Required field(s) in the node has not been filled. Please fill in it timely',
      requiredWarn2: 'All required fields in the node have been filled in',
      coverageWarn1: 'The highlighted node is not related to ',
      coverageWarn11: ' Please creat relationship in timely',
      coverageWarn2: 'All nodes have been related',
      ano: 'copy',
      emailError: 'Email address cannot be null',
      emailError2: 'Please enter a correct email address',
      authorizationInfo: 'Please select an invitation permission',
      editSuccess: 'Modified',
      editWarning: 'Modified, some nodes are locked in the baseline and cannot be modified',
      error1: 'Only nodes at the same level can be moved at present',
      success1: 'Moved',
      error2: 'The type of the source file is inconsistent with that of the target file, so the movement cannot be performed',
      info1: 'Are you sure to move',
      info2: 'and its child nodes to the current file? The original file will no longer contain',
      info3: 'and its child nodes',
      error3: 'The movement cannot be performed in the multi-selection state. Please select a single node',
      success2: 'Sprint has been changed',
      success3: 'Sprint has been deleted',
      success4: 'The baseline has been added',
      success5: 'The version has been added',
      success6: 'The test plan has been added',
      success7: 'Execution is successful',
      error4: 'Execution failed. None of the selected nodes are in the currently selected test plan.',
      info4: 'Yes',
      info5: 'xxx nodes are executed,',
      info6: 'xxx nodes are not in the selected test plan.',
      warn1: 'The selected node contains locked nodes. Please cancel the selection before operating',
      moveWarn: 'Allow batch moving when only selecting the same file type',
      moveSuccess: 'Batch movement successful',
      moveInfo: 'Batch movement failed',
      Copied: 'Copied',
      CutNotAvailable: 'Cut function is not available in multi-selected state',
      RootNodeNotSupportCutting: 'The root node does not support cutting',
      Cut: 'Cut'
    },
    clone: {
      tip1: 'Copy is not available in multi-select mode',
      tip2: 'Paste is not available in multi-select mode',
      tip3: 'Delete is not available in multi-select mode',
      tip4: 'Add node is not available in multi-select mode',
    },
    AI: {
      tip1: 'AI supplement in progress...',
      tip2: 'AI supplement',
      tip3: 'AI supplement failed',
      tip4: 'AI supplement is not available in multi-select mode',
      tip5: 'Please select at least one node',
    },
    status: {
      st1: 'Not started',
      st2: 'In progress',
      st3: 'Completed',
      st4: 'Started',
      st5: 'Released',
      st6: 'Not released',
      st7: 'Unassigned'
    },
    word: 'Try the Word view for clearer descriptions!',
    p1: 'Total',
    p2: 'entries',
  },

  node: { // Node
    top: { // Top button
      search: 'Search',
      type: 'Type',
      creator: 'Creator',
      status: 'Status',
      responsibility: 'Assignee',
      moreBtn: 'More+',
      clearBtn: 'Reset',
      select: 'Filter item',
      saveBtn: 'Save filter conditions',
      confirmForm: {
        title: 'Please enter a condition name',
        name: 'Name',
        notificationFrequency: 'Notification frequency',
        notificationObject: 'Notification object',
        every: 'every',
        week: 'week',
        weeks: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
      },
      senior: ' Advanced search',
      simple: ' Easy Search'
    },
    batchField: {
      assignee: 'Assignee',
      baseline: 'Baseline',
      version: 'Version',
      testplan: 'Test plan',
      sprint: 'Sprint',
      storypoint: 'Story point',
      msg: 'Please select fields and field values',
      alert: 'Batch modifying the current field will not overwrite the original value; instead, it will add new option values.',
    },
    table: { // Node table
      type: 'File type',
      name: 'Node name',
      file_name: 'File name',
      status: 'Status',
      creator: 'Creator',
      responsibility: 'Assignee',
      createdTime: 'Created time',
      updatedTime: 'Updated time',
      fileName: 'File name',
    },
    excel: {
      tip1: 'Maximum of 1000 nodes can be exported at once',
      tip2: 'Please select nodes to export',
      tip3: 'Export in progress',
      tip4: 'Please select the node you want to modify',
      tip5: 'You can modify up to 100 nodes simultaneously at most.',
    },
    message: {
      message1: 'Field not included',
      message2: 'Not covered',
      message3: 'Covered',
      message4: 'Switch to edit mode',
      message5: 'Switch to review mode',
      message6: 'Other fields',
      message7: 'Suspect',
      message8: 'Consistent',
      message9: 'Non-reused node',
      message10: 'Cannot contain non-reusable nodes',
      needCoverage: 'Need to cover',
      noNeedCoverage: 'No need to cover',
      operationSuccess: 'Operation successful',
      operationSuccess1: 'Top up successful',
      operationSuccess2: 'Bottom up successful',
      operationSuccess3: 'Move up successful',
      operationSuccess4: 'Move down successful',
      operationSuccess5: 'Already at the limit',
      nodeCovered: 'Node has been covered',
      nodeNotCovered: 'Node no longer needs to be covered',
    },
    date: {
      Mon: 'Mon',
      Tue: 'Tue',
      Wed: 'Wed',
      Thur: 'Thur',
      Fri: 'Fri',
      Sat: 'Sat',
      Sun: 'Sun',
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December',
    },
    ai: {
      title: 'AI drawing',
      placeholder1: 'Please enter the content',
      placeholder2: 'Please select the type',
      aiDiagram1: 'Sequence diagram',
      aiDiagram2: 'Flow diagram',
      aiDiagram3: 'Class diagram',
      aiDiagram4: 'State diagram',
      aiDiagram5: 'Entity-Relationship diagram',
      aiDiagram6: 'Gantt diagram',
      aiDiagram7: 'Git diagram',
      aiDiagram8: 'Mindmap diagram',
      alertTitle: 'Note',
      alertMessage: 'Copy successful, click on the tab above [Insert] ->[Advanced] ->[Mermaid] Paste Code Insert',
    },
    sourceNodeSync: {
      title: 'Synchronize original node',
      target: 'Original node',
      origin: 'Current node',
      tip1: 'No fields of the same corresponding type in the original node',
      tip2: 'There is a difference value between the current node and the original node, data may be lost after the move',
      tip3: 'The original node field is not fully specified',
      sureToSynchronize: 'Sure to synchronize? The field data of the current node will be overwritten by the original node,',
      sureToSynchronize2: 'this operation is irreversible',
      success: 'Sync successful'
    },
    sendiorSearch: {
      errormessage: 'Contains illegal characters, please avoid manually entering characters',
      addFilterConditions: 'Add filter conditions'
    }
  },

  AI: {
    tip1: 'AI creation',
    tip2: 'Copy all',
    tip3: 'Create file',
    tip4: 'Please enter',
    tip5: 'AI enhancement',
    tip6: 'Purchase tips',
    tip7: 'Note: AI Robot is a paid feature, please contact customer service to activate.',
    tip8: 'Create requirement',
    tip9: 'Create test case',
    tip10: 'Generate code',
    tip11: 'Enhance requirement',
    tip12: 'Enhance test case',
    tip13: 'Enhance code',
    tip14: 'Copy successful',
    tip15: 'Please enter file name',
    tip16: 'Please select file type',
    tip17: 'File creation in progress, please refresh the file page later',
    tip18: 'Creation failed',
    tip19: 'Sorry, your browser does not support server-sent events. Please switch to a different browser.',
    tip20: 'Please enter the code language type',
    tip21: 'Input box cannot be empty',
    tip22: 'What topic do you want to write a requirement about?',
    tip23: 'What aspects do you need to enhance in this requirement? For example, specific features to add, usage scenarios to consider, performance indicators to meet, etc.',
    tip24: 'What test case do you want to write about?',
    tip25: 'What aspects do you need to enhance in this test case? For example, specific features to test, usage scenarios to consider, performance indicators to test, etc.',
    tip26: 'What requirement do you need to enhance?',
    tip27: 'What test case do you need to enhance?',
    tip28: 'What code do you need to enhance?',
    tip29: 'What aspects do you need to enhance in this code? For example, specific features to add, usage scenarios to consider, performance indicators to meet, etc.',
    tip30: 'What are your code requirements?',
    tip31: 'Please further enhance this requirement.',
    tip32: "I'm satisfied with this requirement. Please help me create a file in MappingSpace.",
    tip33: 'Please further enhance this test case.',
    tip34: "I'm satisfied with this test case. Please help me create a file in MappingSpace.",
    tip35: 'Please further enhance this code.',
  },

  nodeDetail: { // Node detail page
    third: {
      title: 'Universal web connection',
      add: 'Add associated link',
      input: 'Please enter the associated link',
      warn: "Tip",
      addsucc: "Successfully added",
      delsucc: "Delete successful",
      edsucc: "Modified successfully",
      input_tab: "Please enter the name",
      open: "Open link on new tab",
      del: "This operation will cause all pages under this section to be removed. Are you sure you want to remove it?",
      del_btn: 'Remove',
      or: 'or'
    },
    basicInfo: {
      title: 'Basic information',
      responsibility: 'Assignee',
      deadline: 'Deadline',
      priority: 'Priority',
      storyPoint: 'Story point',
      structureDiagram: 'Structure diagram',
      api: 'API document',
      warn: 'At most 50 API documents are related',
      add: 'Please input apiDocUrl',
      jira: 'Linked jira issue',
      ones: "Linked ones work item",
      warnjira: 'Jira issue can be linked with up 50',
      warnones: "ones work item can be linked with up 50",
      addIssue: 'Please enter the jira issue link',
      addOnesIssue: "Please enter the ones work item link",
      errOnesIssue: "The ones work item link is illegal",
      api_status_map: {
        published: "Released",
        hold: "Maintenance",
        bin: "Discarded",
        unsure: "To be determined",
        develop: "Develop",
        test: "Test",
        cor: "Correspond",
        ana: "Anomaly",
        design: "Designing",
        complete: "Completed",
      },
      ms1: 'Deleted and not recoverable, please confirm whether to delete?',
      controllability: "Controllability",
      exposure: "Exposure",
      severity: "Severity",
      safetyLevel: "ASIL",
      FunctionalRequirementsAndCharacteristics: "Functional requirements and characteristics",
      loseEfficacy: "Failure",
    },
    publish: {
      title: "Release",
      baseline: 'Baseline',
      testPlan: 'Test plan',
      version: 'Version',
      info1: 'For a test plan not started yet, test cases can be added or deleted',
      info2: 'For a test plan in progress, only test cases can be added'
    },
    testStep: {
      title: 'Test step',
      no: 'Number',
      forward: 'Precondition',
      testStep: 'Test step',
      antiResult: 'Expected result',
      ViewTestStep: 'View test step'
    },
    description: {
      title: 'Description',
      placeholder: 'Please add description...',
      info1: 'Drag the file here, or ',
      info2: 'click to upload'
    },
    relationChart: {
      title: 'Relationship',
      centerView: 'Relationship diagram',
      vmodelView: 'V-Model diagram',
      wmodelView: 'W-Model diagram',
      tip: 'Double-click the node to view the node details, and right-click the node to cancel the relationship',
      tip2: 'Click the node to unfold its related nodes. Double-click the node to access the node details',
      addRelatedNode: 'Add related nodes',
      placeholder: 'Input node Key or keywords',
      text: 'Executing...',
      message: 'Added. It is redrawing now',
      deleteRelation: {
        info: 'Are you sure you want to cancel this relation?',
        tip: 'Do not remind again'
      }
    },
    message: {
      error: 'This node has been locked. If you need to change it, please submit a change request!',
      error2: 'The version where this node lies was introduced by Sprint and cannot be deleted. Please go to Sprint to remove it',
      error3: 'The file is too large. Please try again after modifying it.',
      info: 'Picture name',
      suspect: 'Suspect',
      suspectTip: 'It is inconsistent with the content of the source node. Manually click the synchronization button on the right to keep it consistent with the source node.'
    },
    activity: {
      title: 'Activity',
      info: 'Display:',
      tab: {
        comment: 'Comments',
        history: 'History',
        code: 'Gitlab',
        code1: 'Gitee',
        code2: 'Github',
        code3: 'Jenkins',
        code4: 'Gerrit',
        review: 'Review records',
        testRecords: 'Test records',
        reuse: 'Reuse records'
      },
      comment: {
        placeholder: 'Press Ctrl+Enter to send comments',
        info1: 'Please enter your comment',
        info2: 'Comment succeeded',
        info3: 'Comment failed',
        place: 'No result'
      },
      history: {
        option: 'Operation',
        originalData: 'Original data',
        UpdatedData: 'Change to',
        time: 'Operation time',
        null: 'None',
        teststep: 'Test details',
        typeMap: {
          Create: "Create node",
          Change: "Change field",
          RelationAdd: "Add relation",
          testPlan: "Test plan",
          RelationDel: "Delete relation"
        },
        tp1: 'Test step details',
        ms1: 'left empty',
        recoverMsg: 'Are you sure you want to revert to this image?',
        DeleteGanttItem: "Remove gantt chart",
        UpdateGanttItem: "Change gantt chart field",
        MoveGanttItem: "Change gantt chart",
        CreateGanttItem: "Add node to gantt chart",
        ganttName: "Gantt chart name",
        day: "Day",
        man_hour: "Actual",
        plan_man_hour: "Planned",
        progress: "Progress",
        StartTime: "Start time",
        DueTime: "End Time",
        remove: "(deleted)",
        NodeSync: 'Node synchronization',
        ChangeRequest: 'Change request approved',
      },
      jenkins: {
        time: 'Build time',
        job: 'Build job',
        result: 'Build result',
        during: 'Build duration',
        code: 'Code changeset',
        codeBtn: 'View changeset',
        dialog: {
          email: 'Change person',
          summary: 'Change description',
          time: 'Time of change'
        }
      },
      gerrit: {
        changeName: 'Name of the change',
        operator: 'Initiator',
        createTime: 'Created time',
        repo: 'Associated repositories',
        reviewList: 'List of reviewers',
        status1: 'Status',
        status: {
          st1: 'In Progress',
          st2: 'Merged',
          st3: 'Deprecated'
        }
      },
      svn: {
        repoName: 'Repository',
        commitMessage: 'Commit message',
        author: 'Operator',
        revision: 'Version',
        createdAt: 'Operation time'
      },
      code: {
        commit: {
          title: 'Commit activity',
          repoName: 'Repository',
          repoTitle: 'Commit message',
          pipelineRes: 'Pipeline result',
          securityScan: 'Security scanning',
          operator: 'Operator',
          opeTime: 'Operation time',
          info: ' bug(s)',
          info2: 'No data',
          info3: 'Reviewing',
          info4: 'Approved'
        },
        merge: {
          title: 'Merge request activity',
          repoName: 'Repository name',
          repoTitle: 'Merge request title',
          originToTarget: 'Original branch>target branch',
          pipelineRes: 'Pipeline result',
          securityScan: 'Security scanning',
          operator: 'Operator',
          opeTime: 'Operation time',
          reviewResult: 'Code review result',
          reviewMember: 'Review member(approved/total)'
        }
      },
      reuse: {
        CreateNode: 'Create node',
        sync: 'Sync with ',
        copyF: 'Copy from ',
        copyT: 'Copy to ',
      }
    }
  },

  baseline: { // Baseline
    addBaselineForm: { // Add new Baseline Form
      title: 'New baseline',
      name: 'Name',
      desc: 'Description'
    },
    topBar: { // Top operation
      beginBaseline: {
        title: 'Tips',
        content: 'After the baseline starts, the node is locked by default, namely it is no longer allowed to change the node directly. If you need to change the node, please pull out the branch from the node to make the change.',
      },
      endBaseline: {
        title: 'Tips',
        content: 'After the baseline ends, the node is automatically unlocked.'
      },
      pasteBaselineForm: {
        title: 'New copy',
        name: 'Baseline name',
        desc: 'Baseline description',
        note: 'Note:The copied baseline will inherit all nodes from the original baseline',
        info: 'Please enter a copy name'
      },
      autoSave: 'Autosave',
      deleteBaselineForm: {
        title: 'Tips',
        info: 'Are you sure you want to delete this baseline?'
      }
    },
    basicInfo: { // Basic information
      startTime: 'Start time',
      startPeople: 'Started by',
      baselineDesc: 'Description'
    },
    basicInfo2: {
      startTime: 'Baseline creation time',
      startPeople: 'Baseline creator',
    },
    basicInfo3: {
      startTime: 'Baseline end time',
      startPeople: 'Ended by',
    },
    nodeTable: {
      name: 'Node name',
      type: 'File type',
      status: 'Status',
      operation: 'Operation',
      addTime: 'Add baseline time',
      operationList: {
        new: 'New',
        change: 'Modified',
        all: 'All'
      },
      operator: 'Operator',
      operationTime: 'Operation time',
      block1: 'Baseline content',
      block2: 'Deleted nodes',
    },
    addNode: { // Add node
      title: 'Add nodes',
      placeholder: 'Input node Key or keywords',
    },
    unlockNode: { // Unlock node
      title: 'Tips',
      content: 'After unlocking the node, it can be changed directly, which may affect the range of the baseline. Are you sure you want to unlock it? '
    },
    lockNode: {
      title: 'Tips',
      content: 'Are you sure you want to lock the node? After the node is locked, it is no longer allowed to change the node directly. If you need to change the node, please pull out the branch from the node to make the change.'
    },
    history: {
      date: 'Time',
      user: 'User',
      activity: 'Activity'
    },
    info: {
      addNode: 'Add a new node',
      delNode: 'Delete a node',
      modNode: 'Modify node content',
      recNode: 'Recover the node'
    },
    message: {
      beginSuccess: 'The baseline has started',
      endSuccess: 'The baseline has ended',
      pasteError: 'Anomaly occurs in creating the baseline copy. Please try again!',
      deleteSuccess: 'Deleted',
      addSuccess: 'Added',
      revertSuccess: 'Recovered',
      unlockSuccess: 'The node is unlocked',
      lockSuccess: 'The node is locked',
      NameCannotBeEmpty: 'Name cannot be empty',
      archiveSuccess: "Successful archiving",
      archiveFail: "Cannot save empty baseline",
      versionArchiveFail: "Cannot save empty version",
      archiving: "In the archives. Please check the archive records later.",
    },
    archive: {
      title1: "Archive",
      title2: "Baseline archive",
      title3: "Version archive",
      remark: "Remarks",
      submit: "Confirm",
      cancel: "Cancel",
      time: "Time",
      user: "Archive user",
      version: "Name",
      Error1: "Mandatory version number",
    }
  },

  version: { // Version
    addVersionForm: { // New version form
      title: 'New version',
      name: 'Name',
      baseline: 'Related baseline',
      testPlan: 'Related test plan',
      goal: 'Release goal'
    },
    topBar: { // Top operation
      deleteVersionForm: {
        title: 'Tips',
        info: 'Are you sure you want to delete this version?'
      }
    },
    basicInfo: { // Basic information
      publishTime: 'Planned release time',
      actualTime: 'Actual release time',
      responsibility: 'Assignee',
      baseline: 'Related baseline',
      testPlan: 'Related test plan',
      sprint: 'Related sprint',
      notice: "Release notification",
      goal: 'Release purpose',
      note: 'Comments',
      placeholder: 'Please select date and time'
    },
    status: 'Completed',
    nodeTable: {
      title: 'Node name',
      status: 'Status',
      responsibility: 'Assignee',
      operator: 'Operator',
      operationTime: 'Operatied time'
    },
    addNode: {
      title: 'Add nodes',
      placeholder: 'Input node Key or keywords'
    },
    publishBtn: "Release",
    publishForm: {
      info: 'The related test plan is not completed. Are you sure you want to release it?'
    },
    message: {
      addSuccess: 'New version created',
      publishSuccess: 'Released',
      publishWarn: 'Please specify the assignee first',
      addError: 'The version name already exists',
      addError1: 'Failed to create a new version',
    }
  },

  review: {
    tip1: 'New rule',
    tip2: 'Applicable file types',
    tip3: 'Fixed reviewers',
    tip4: 'Not specified, please specify yourself',
    tip5: 'Approval rule',
    tip6: 'At the end of each review, automatically approve nodes with a pass rate >=',
    tip7: 'Review items',
    tip8: 'Path',
    tip9: 'Review rule name',
    tip10: 'Please enter a name',
    tip11: 'Tip',
    tip12: 'Are you sure you want to delete it?',
    tip13: 'Cancel',
    tip14: 'Confirm',
    tip15: 'Review rule name cannot be empty',
    tip16: 'File type cannot be empty',
    tip17: 'Approval pass rate cannot be empty and must be a number between 0 and 100',
    tip18: '%Node that automatically passes the review',
    tip19: 'Delete',
    tip20: 'Review entry cannot be empty',
    result: {
      info1: 'Review end time',
      info2: 'Review results',
      info3: 'Detailed review results',
      info4: 'View',
      info5: 'Pass',
      info6: 'people',
      info7: 'Not passed',
    }
  },

  test: { // Test
    testCaseID: 'test case ID',
    attachments: 'Attachments',
    addTestForm: { // New test form
      title: 'New test plan',
      name: 'Name',
      baseline: 'Related baseline',
      version: 'Related version'
    },
    topBar: { // Top operation
      pasteTestForm: {
        title: 'Paste test plan',
        name: 'New name',
        note: 'Note: after being pasted, the new test plan will include all the test cases in the original test plan'
      }
    },
    basicInfo: { // Basic information
      version: 'Versions to be tested',
      info: 'Related test cases in the synchronous version',
      responsibility: 'Assignee',
      baseline: 'Related baseline',
      goal: 'Test goal',
      note: 'Comments',
      planStartTime: 'Planned start time',
      plannedEndTime: 'Planned end time',
      actualStartTime: 'Actual start time',
      actualEndTime: 'Actual end time',
    },
    export: {
      title: 'Export setting',
      name: 'Report name',
      project: 'Select exporting project',
      all: 'Select all'
    },
    checked_options: {
      bug: "Bug overview",
      tesecase: "Test case overview",
      coverage: "Coverage report",
      result: "Detailed test result",
      detailBug: "Bug details",
      api: "API test report",
      node: 'Node to be tested',
      testcase: 'Test case',
    },
    status: 'Completed',
    notExecuted: 'Not executed',
    nodeTable: {
      title: 'Node name',
      result: 'Test result',
      status: 'Execution status',
      bug: 'Related bug',
      executor: 'Test executor',
      executor2: 'Test executor',
      executionTime: 'Execution time',
      status1: 'Status',
      assign: 'Assignee'
    },
    addNode: {
      title: 'Add nodes',
      place: 'Input node Key or keywords'
    },
    newFile: {
      title: 'New file',
      place: 'Please enter the file name',
      place2: 'Please select a file type',
      place3: 'Please select a test plan',
      place4: 'Please select a test case'
    },
    setStatus: {
      title: 'Custom execution status',
      alert: 'Please note that this setting is effective for all projects',
      name: 'Name',
      type: 'Type',
      option: 'Operation',
      info: 'Are you sure you want to delete it?',
      fileName: 'File name',
    },
    editStatus: {
      title: 'Edit status',
      name: 'Name',
      color: 'Color',
    },
    addStatus: {
      title: 'Add status',
      name: 'Name',
      type: 'Type',
      color: 'Color',
    },
    report: {
      api: 'Number of APIs',
      test: 'Number of API test cases',
      cov: 'Coverage of API test cases',
      auto: 'Coverage of automated process testing',
      pie: 'API status distribtuion pie chart',
      detail: 'API details',
      num: 'Serial No.',
      name: 'API name',
      status: 'Status',
      info1: 'Number of APIs with written test cases',
      info2: 'Number of APIs without written test cases',
      info3: 'Number of APIs with bound process use cases',
      info4: 'Number of APIs without bound process use cases',
      info5: 'Passed the test',
      info6: 'Failed in the test',
      info7: 'Not tested',
    },
    message: {
      info1: 'Please enter the report name',
      info2: 'Please select at least one item',
      warn1: 'Please enter the status name',
      warn2: 'Please enter the status color',
      warn3: 'Please enter the status type',
      deleteSuccess: 'Test plan deleted',
      addSuccess: 'New test plan created',
      executeWarn: 'Please specify the assignee first',
      pasteSuccess: 'Pasted',
      pasteError: 'Copy failed',
    }
  },

  board: { // Common content for Agile Kanban
    fileFilter: 'By file',
    responsibilityFilter: 'By assignee',
    status: {
      notStarted: 'Not started',
      completed: 'Completed',
      inProgress: 'In progress',
      unAssigned: 'Unassigned'
    },
    notice: 'Number of tasks, total story points',
    settingPage: {
      title: 'Settings',
      name: 'Name',
      bar: 'Column',
      maxCardNum: 'Maximum number of cards',
      addBarBtn: 'Add column',
      addBarForm: {
        title: 'Enter name',
        name: 'Name'
      }
    },
    chart: {
      burn: 'Burn down chart',
      story: 'Remained story points',
      burn2: 'Burn up chart',
      story2: 'Completed story points',
      burn3: 'Completed story chart',
      story3: 'Story point',
      assignee: 'Number of completed nodes-Assignee',
      num: 'Number of nodes',
      task: 'Completed task',
      type: 'Type',
      name: 'Node name',
      ass: 'Assignee',
      status: 'Status',
    },
    message: {
      error: 'You have no permission to view node details',
      error1: 'You are not allowed to move it to a different user or file',
      error2: 'You are not allowed to move it to this status',
      error3: 'Upload failed',
      info: 'Date'
    }
  },

  scrum: {
    goal: 'Sprint goal',
    addNewNode: 'Add nodes',
    place: 'Input node Key or keywords',
    stickyNode: 'Stick the node',
    sticky: 'Stick',
    editScrumPage: {
      head1: 'Settings',
      head2: 'Goal',
      name: 'Name',
      date: 'Time',
      goal: 'Sprint goal',
    },
    newSprint: {
      title: 'New sprint',
      name: 'Name',
      goal: 'Sprint goal',
      set: 'Sprint settings',
      name2: 'Sprint name',
      time: 'Time',
      start: 'Start date',
      end: 'End date',
      com: 'Completed sprint',
      move: 'Move the remaining nodes to',
      info1: 'The node is in the ',
      info2: ' Locked in',
    },
    clock: {
      part1: 'Time remaining:',
      part2: 'Days'
    }
  },

  kanban: {
    stick: 'Stick the node',
    stickBtn: 'Stick'
  },

  chart: { // Data chart
    addChartForm: { // New data chart form
      title: 'New data chart',
      name: 'Name',
      desc: 'Description'
    },
    topBar: { // Top operation
      deleteVersionForm: {
        title: 'Tips',
        info: 'Are you sure to delete it?'
      }
    },
    basicInfo: { // Basic information
      creator: 'Creator',
      createTime: 'Created time',
      readAuthority: 'View permission',
      writeAuthority: 'Edit permission',
      desc: 'Description',
      authorityList: {
        all: 'Public',
        private: 'Private',
        part: 'Partial'
      }
    },
    addDiagramBtn: 'Add diagram',
    addDiagramForm: {
      title: 'Diagram settings',
      name: 'Name',
      type: 'Type',
      typeList: {
        bar: 'Histogram',
        pie: 'Pie chart',
        line: 'Line chart',
        radar: 'Radar chart',
        twoDTable: 'Two-dimensional dashboard',
        twoDCoverage: 'Two-dimensional coverage dashboard',
        duration: 'Average status duration',
        times: 'Average status transition times',
        condition: 'Filtered results list',
        vmodel: 'Overview of V-model',
        wmodel: 'Overview of W-model',
        l: 'Level',
        c: ''
      },
      dataSource: 'Data source',
      fieldSelection: 'Field',
      node: 'NODE',
      file: 'FILE',
      datatype: 'Data type',

    },
    total: 'Total: ',
    allData: 'All data',
    num: 'Quantity',
    NodesQueried: 'Nodes to be queried',
    message: {
      deleteSuccess1: 'Deleted',
      deleteSuccess: 'Deleted',
      saveSuccess: 'Saved',
      deleteError: 'Deletion failed',
      info1: 'This filter condition has been used by',
      info2: ' chart in the dashboard and it cannot be deleted',
      warn: 'Please enter a correct number',
      warn2: 'At least one person is selected for editing permission!',
      error: 'There are required items left unfilled!',
      ms1: 'Average number of times a file or node passes through a specific state',
      ms2: 'Average duration of a file or node passing through a specific state',
      ms3: 'If there are 50 nodes of type A, and 20 of them have a traceability relationship with nodes of type B, the coverage of the A row and B column is 40%.',
      ms4: 'Create time range',
      ms5: 'Start time',
      ms6: 'Please select',
      ms7: 'End time',
      ms8: 'Data',
      ms9: 'Data metrics',
      ms10: 'V-Model traceability panorama plugin has expired. Please renew to continue using!',
      ms11: 'Click to renew',
      ms12: 'If in the selected project there are a total of 50 user requirements, and 10 of them are associated with system requirements, the first row and second column will display 20% (10/50). 其中有10条用户需求关联了系统需求，则第一行第二列显示20% (10/50)',
      ms13: 'X-axis field selection',
      ms14: 'Y-axis field selection',
      ms15: 'Purchase tips',
      ms16: 'Note: V-Model panorama is a paid feature, please contact customer service to activate.',
      ms17: 'Day',
      ms18: 'Hour',
      ms19: 'Minute',
      ms20: 'Second',
      ms21: 'Two-dimensional dashboard',
      ms22: '',
      ms23: '',
      ms24: '',
      ms25: 'Status',
      ms26: 'Average duration',
      ms27: 'Jump count',
      ms28: 'Total',
      ms29: "No need to cover nodes",
      ms30: 'File type',
      ms31: 'Node name',
      ms32: 'Note: W-Model panorama is a paid feature, please contact customer service to activate.',
      ms33: 'W-Model traceability panorama plugin has expired. Please renew to continue using!',
    }
  },

  bin: { // Recycle bin
    table: {
      type: 'File type',
      name: 'File name',
      deletePeople: 'Deleted by',
      deleteDate: 'Deleted time',
      forceDeleteForm: {
        title: 'Tips',
        desc: 'Are you sure you want to permanently delete the file? A deleted file cannot be recovered'
      }
    },
    message: {
      revertSuccess: 'The file has been recovered. You can find it in file',
      deleteSuccess: 'Deleted',
      info: 'Deletion canceled'
    }
  },

  projectDetails: { // Project details
    name: 'Project name',
    avatar: 'Project avatar',
    id: 'Project ID',
    key: 'Project KEY',
    agileType: 'Agile type',
    gitlab: 'Relate repository',
    eolink: 'Eolink configuration',
    jenkins: 'Jenkins configuration',
    editAvatarBtn: 'Modify',
    addGitlabBtn: 'Add Gitlab',
    copyed: 'Replication completed',
    addGitlabForm: {
      title1: 'Select Gitlab type',
      note1: '1. You have at least the "Maintainer" permission for your private GitLab; otherwise, after the Gitlab is related, the system cannot automatically configure the Webhook of the Gitlab, and it needs to be manually configured. </br> 2. Private GitLab supports version 11.11 at least, and version 12.4 is recommended. ',
      title2: 'Private GitLab authentication (OAuth)',
      icon: 'Private GitLab certification',
      note3: 'Certification succeeded. Please select GitLab',
      note2: 'No authenticated private GitLab is available yet, and you can create a new authentication',
      note4: '1.Enter the hooks directory of the SVN code repository. For example, if your SVN repository is located in/path/to/repo, then you should access the/path/to/repo/books directory</br> 2. Edit the post - commit script file (post - commit. tmpl) and add the following code to the script. After editing, rename the post - commit. tmpl file to post - commit',
      note5: '3.Run the "chmod+x post commit" command to ensure that the script has executable permissions',
      note6: '# The repository path and the submitted version number are passed as parameters to the script',
      note7: '# Using svnlook to obtain commit information',
      note8: '# Make JSON data',
      note9: '# The URL of a remote POST request',
      note10: '# Send POST request',
      addAutoBtn: 'New authentication',
      addAutoForm: {
        warnMessage: "To authenticate a private GitLab, you need to create a new Application in GitLab first, and then fill in the information in the current pop-up window and confirm the authentication.",
        step1: {
          title: 'Create a new Application in GitLab',
          step1: 'After opening the private GitLab URL, click "Preferences"  - > "Applications" in the account at the top right corner of the page;',
          step2: 'Fill in the specified fields in GitLab according to the instructions below, and then click "Save application".',
          url: 'Redirect URI should be filled in:',
          scopes: 'Scopes should be ticked:'
        },
        step2: {
          title: 'Fill in the authentication information in the current pop-up window',
          info: 'After the new Application is created, the Application ID and Secret can be found in the application details. Please fill in the following fields based on the above information. ',
          field1: 'Please copy Application ID bing in the GitLab and paste it here',
          field2: 'Please copy Secret in the GitLab and paste it here',
          field3: 'Private GitLab URL',
          placeholder: 'Such as\' http://47.112.x.x \''
        }
      },
      title3: 'Select GitLab'
    },
    editEolinkSettingsBtn: 'Edit configuration',
    editEolinkSettingsForm: {
      tip: 'Account setting=>Open API=>Open API Secret Key',
      filed: 'Applicable file type'
    },
    editJenkinsSettingsForm: {
      tip1: 'Fill in Jenkins login username',
      tip2: 'From the top right corner of Jenkins, go to the user page>Settings>API Token>Add New Token',
      tip3: 'Link to JenkinsJob, such as: https://jenkins Domain name/job/{Job name}/. Multiple branch pipelines or folders need to enter specific job pages to copy links, such as https://jenkins Domain name/job/folder/job/mp/job/master/',
      tip4: 'Jenkins homepage address, such as https://jenkins.ytdevops.com'
    },
    message: {
      info1: 'Please select an active area',
      jenkins1: 'Please enter Jenkins address',
      jenkins2: 'Please enter the authentication username',
      jenkins3: 'Please enter apiToken',
      lab: 'JiHu GitLab',
      lab2: 'Private GitLab',
      lab3: 'Public Gitee',
      lab4: 'Gitee',
      lab5: 'Public Github',
      lab6: 'Github',
      lab7: 'SVN',
      error: 'The uploaded avatar picture size cannot exceed 1MB!',
      success: 'Uploaded',
      ing: 'Uploading',
      error2: 'Upload failed',
      info2: 'Please enter the project name',
      dele: 'Are you sure you want to delete the related GitLab?',
      title: 'Tips',
      info3: 'Deletion canceled',
      warn: 'Please select at least one Gitlab',
      warn2: 'Please fill in the required fields',
    },
    GroupNotification: 'Group notification',
    AddConfiguration: 'Add configuration',
    GiteeAuthentication: 'Gitee authentication',
    AuthenticationSuccessful: 'Authentication successful',
    NoAuthenticatedGitee: 'No authenticated Gitee accounts found',
    GithubAuthentication: 'Github authentication',
    NoAuthenticatedGithub: 'No authenticated Github accounts found',
    JenkinsAddress: 'Jenkins address',
    AuthenticationUsername: 'Authentication username',
    ConnectionFailed: 'Connection failed!',
    EnterBuildJobLink: 'Please enter the Build Job link to bind',
    SetIPWhitelistForDingTalk: 'You need to set the IP whitelist for the DingTalk robot',
    MessageChannel: 'Message channel',
    SelectMessageChannel: 'Please select a message channel',
    EnterWebhookAddress: 'Please enter the webhook address for the group robot',
    Feishu: 'Feishu',
    DingTalk: 'DingTalk',
    WeChatWork: 'WeChat work',
    EnterToken: 'Enter token',
    WorkspaceDomainPrefix: 'Workspace domain prefix',
    WorkspaceDomainDes: 'Space Management>Space Settings>Workspace Domain, copy the domain name prefix and fill in the input box. For example, if the workspace domain name is test123.w.eolink.com, then the workspace domain name prefix is test123.',
  },

  user: { // User
    addUserBtn: 'Add user',
    ChangePassword: 'Change password',
    PleaseEnterYourPassword: 'Please enter your password',
    ConfirmPassword: 'Confirm password',
    PleaseEnterYourPasswordAgain: 'Please enter your password again',
    passwordDoesNotMatch: 'two times to enter the password does not match!',
    ResetPasswordSuccessfully: 'Reset password successfully',
    ErrorInInput: 'Error in input',
    addUserForm: {
      title: 'Add user',
      name: 'User',
      info1: 'Please select a username',
      info2: 'Please select a position',
      warn: 'Please select the user to be added',
      position: 'Title'
    },
    manager: 'Project administrator',
    ordinary: 'General user',
    userTable: {
      name: 'Name',
      role: 'Role',
      placeholder: 'Select a role',
      position: 'Title',
      placeholder2: 'Unassigned',
      positionType: {
        pm: 'Project manager',
        requirement: 'Requirement engineer',
        developer: 'Development engineer',
        test: 'Test engineer',
        operation: 'Operation & maintenance engineer'
      },
      options: {
        remove: 'Remove'
      },
      message: {
        addSuccess: 'Added'
      }
    }
  },

  permission: { // Permission
    node: {
      title: 'Node',
      view: 'View',
      edit: 'Edit',
      create: 'Create',
      move: 'Move mode across files',
      deleteLeafNode: 'Delete the leaf node',
      deleteNonLeafNode: 'Delete the non-leaf node'
    },
    file: {
      title: 'File',
      view: 'View',
      export: 'Export',
      delete: 'Delete'
    },
    baseline: {
      title: 'Baseline',
      create: 'Create/Test/Edit',
      delete: 'Delete',
      unlock: 'Cancel locked node'
    },
    version: {
      title: 'Version',
      create: 'Create/edit',
      delete: 'Delete',
      publish: 'Release'
    },
    testPlan: {
      title: 'Test plan',
      create: 'Create/edit',
      delete: 'Delete',
      export: 'Export'
    },
    deleteRoleBtn: 'Delete',
    deleteRoleForm: {
      title: 'Tips',
      desc: 'Deleting this position will result in related employee\'s position being unassigned. Are you sure you want to delete it?'
    },
    addNewRoleBtn: 'Add a new position',
    placeholder: 'Please input position name',
    message: {
      addRoleError: 'Position name already exists',
      error: 'There is sub-permission dependency, so it cannot be unchecked'
    }
  },

  fileType: { // File type
    vmodelBtn: 'Configure traceability view',
    vmodelBtn1: 'Configure V-Model',
    vmodelBtn2: 'Configure W-Model',
    addFileTypeBtn: 'New file type',
    addFileTypeForm: {
      title: 'New file type',
      name: 'Name',
      bug: 'Is it a bug',
      pop: 'When the file type is bug, its canvas page and node details page contain the fields of test plan and test case.',
      pop2: 'When the file type is test case, its node details page contains fields of preconditions, test steps and expected results. A test result field and an entry for creating bug appear in the the canvas floating window, and there is an entry for creating questions.',
      yes: 'Yes',
      no: 'No',
      testcase: 'Is it a test case'
    },
    fileTypeTable: {
      type: 'File type',
      icon: 'Icon',
      layer: 'Layer',
      options: {
        edit: 'Edit',
        paste: 'Copy',
        delete: 'Delete',
        deleteForm: {
          info: 'Are you sure you want to delete this file type?'
        }
      }
    },
    message: {
      deleteInfo: 'Deletion canceled',
      deleteSuccess: 'Deleted!'
    },
    permission: {
      table: {
        label1: 'Title',
        label2: 'No permission',
        label3: 'Read-only',
      }
    },
    upgradeMechanism: 'Upgrade mechanism',
    ruleDescription: 'Rule description',
    enableOrNot: 'Enable',
    deleteInfo: 'Are you sure to delete it?',
    p1: "When a node's status is",
    p2: 'and the content of the node remains unchanged for more than',
    p3: 'days',
    p4: 'send',
    p5: 'a message.',
    p6: 'When the upgrade mechanism is initiated, it only applies to nodes created within the last month before it is enabled, and to nodes created thereafter.'
  },

  editFileType: { // File type edit page
    msg: "Specified assignee",
    tip: 'Inherit all the settings of the last layer',
    title: 'System requirement',
    createFieldBtn: 'No suitable field? Click to add a new field',
    field: {
      title: 'Field',
      table: {
        name: 'Field name',
        type: 'Field type',
        required: 'Required',
        floatWindow: 'Floating window display'
      },
      newFieldBtn: 'New field'
    },
    coverage: {
      title: 'Coverage detection'
    },
    hara: {
      title: 'Functional safety',
      message: 'Severity, Exposure, Controllability, ASIL',
    },
    dfmea: {
      message: 'Functional requirements and characteristics, lose efficacy',
      tooltips: 'All levels inherit the first level settings'
    },
    workflow: {
      title: 'Workflow',
      title2: 'Workflows',
      placeholder: 'Change template',
      addStatusBtn: 'Add status',
      newBtn: 'New status',
      name: 'Name',
      type: 'Type',
      place: 'Please select a status type',
      place2: 'Please enter the status name',
      saveAsNewTemplateBtn: 'Save as a new template',
      info: 'Allow all statuses to transition to this one',
      info2: 'The initial status node cannot be deleted. Please change the initial status node first!',
      warn: 'The name must not be empty',
      success: 'Template added',
      saveAsNewTemplateForm: {
        title: 'Save as a new template',
        name: 'Name'
      },
      saveBtn: 'Save',
      saveForm: {
        title: 'Confirm status',
        original: 'Original',
        matched: 'Match status',
        place: 'It cannot be null'
      },
      message: {
        saveSuccess: 'Saved',
        ms1: 'The following validation conditions failed, unable to proceed with state transition',
        ms2: 'Please check the automation workflow settings:',
      },
      template: {
        tip1: 'Deleting a level will also delete all settings associated with that level. Are you sure you want to delete?',
        tip2: 'Assignee',
        tip3: 'Story points',
        tip4: 'Baseline',
        tip5: 'Version',
        tip6: 'Linked nodes',
        tip7: 'Add status',
        tip8: 'Edit',
        tip9: 'Exit edit',
        tip10: 'Workflow automation settings:',
        tip11: 'Auto trigger',
        tip12: 'Auto trigger functionality may be affected by required fields. If required fields are not fully filled, the status may not change, resulting in the auto trigger functionality becoming ineffective.',
        tip13: 'When all child nodes of a node are pushed to the ',
        tip14: 'Specify status',
        tip15: 'Please specify a status',
        tip16: 'Please specify conditions',
        tip17: 'Field',
        tip18: 'Please specify a field',
        tip19: 'Please specify a user',
        tip20: 'Add condition',
        tip21: 'Validate condition',
        tip22: 'Only allow pushing to the next status when',
        tip23: '',
        tip24: 'Only allow pushing to the next status for specified',
        tip25: ' users',
        tip26: 'Only allow pushing to the next status when the values of ',
        tip27: 'and',
        tip28: 'fields are the same',
        tip29: ' status, automatically advance to the next status.',
        tip30: 'Only allow pushing to the next status when the ',
        tip31: 'Only allow pushing to the next status for users with specified ',
        tip32: 'Value',
        tip33: 'Please enter a value',
        tip34: 'Please enter text',
        tip35: 'Multiline text',
        tip36: 'Single line text',
        tip37: 'Please enter a link',
        tip38: 'Link',
        tip39: 'Date',
        tip40: 'Please select a date',
        tip41: 'Please specify a label',
        tip42: 'Label',
        tip43: 'Please specify a single choice',
        tip44: 'Single choice',
        tip45: 'Please specify multiple choices',
        tip46: 'Multiple choices',
        tip47: 'Specify user',
        tip48: 'Field 1',
        tip49: 'Please specify Field 1',
        tip50: 'Field 2',
        tip51: 'Please specify Field 2',
        tip52: 'Specify field',
        tip53: 'Specify position',
        tip54: ' positions',
        tip55: ' field is not empty',
        tip56: "Please specify a fileType",
        tip57: 'When all specified types of nodes associated with a node are pushed to a certain state, it automatically advances to the next state'

      },
      dialog: {
        tip1: 'Please specify user position',
        tip2: 'Post-processing',
        tip3: 'After advancing to the next status, automatically set the child nodes of the current node to ',
        tip33: 'After advancing to the next status, the assignee would be assigned as',
        tip4: 'After advancing to the next status, automatically change the value of the ',
        tip5: ' field to ',
        tip6: 'Current changes are not saved, do you want to save?',
        tip7: 'Exit without saving',
        tip8: 'Save',
        tip9: 'Purchase tips',
        tip10: 'Note: Workflow automation is a paid feature, please contact customer service to activate.',
        tip11: 'This feature is not developed. Please use other features.',
        tip12: 'Meet all conditions',
        tip13: 'Meet any condition',
        tip14: 'Workflow template',
        tip15: 'Template name',
        tip16: 'Operation',
        tip17: 'Modification success!',
        tip18: 'Modification failed!',
        tip19: 'Delete success!',
        tip20: 'Failed to delete!',
      }
    }
  },

  fieldLab: { // Field library
    default_tip: "Click below to select default values",
    addNewFieldBtn: 'Add',
    addNewFieldForm: {
      title: 'Add a new field',
      placeholder1: 'Please enter field name',
      placeholder2: 'Please select field type',
      placeholder3: 'Please enter a description',
      placeholder4: 'Please enter the unit',
      info: 'Please make sure all fields are not null',
      fieldTypeList: {
        text: 'Single-line text',
        textarea: 'Multi-line text',
        number: 'Number',
        select: 'Single choice',
        multiSelect: 'Multiple choices',
        user: 'Single user',
        multiUser: 'Multiple users',
        date: 'Date',
        label: 'Label',
        version: 'Version',
        link: 'Link'
      }
    },
    info: 'You are not allowed to add duplicate option',
    fieldTable: {
      name: 'Field name',
      type: 'Field type',
      action: 'Operation',
      unit: 'Unit',
      options: {
        delete: 'Delete',
        edit: 'Modify',
        editForm: {
          title: 'Edit',
          placeholder: 'Please input the option content',
          high: 'High',
          medium: 'Medium',
          low: 'Low',
          addBtn: 'Add',
          editBtn: 'Modify',
        }
      }
    },
    message: {
      deleteError: 'This field has been used. It cannot be deleted',
      addError: 'The content of the option cannot be empty',
      ms1: 'Send notifications to stakeholders when a field changes',
      ms2: 'This field is a system field and cannot be modified.',
      ms3: 'Modification successful',
      ms4: 'Modify notification settings.',
      ms5: 'Only numeric types can edit units'
    }
  },

  changeRequest: { // Change request
    saveAndExitBtn: 'Save and exit',
    field1: 'Change control board',
    placeholder1: 'Not specified. Please specify it by yourself',
    field2: 'Pass rule',
    placeholder2: 'When the passing rate by reviewers > =',
    placeholder3: '%, the change request will be approved automatically',
    placeholder4: '%, and',
    placeholder5: 'or the node creator manually confirms, the change request will be approved',
    byHand: 'Merge manually',
    auto: 'Merge automatically',
    info: 'Select at least one member after opening the committee',
    needReview: 'To be reviewed',
    needConfirm: 'To be confirmed',
    myIssue: 'Submitted by myself',
    allChange: 'All change requests',
    title: 'Change request',
    approve: 'Ready to merge',
    reviewing: 'In review',
    merge: 'Merged',
    refuse: 'Refused',
    initiator: 'Initiator',
    option1: 'Top ',
    option2: ' nodes of the most change times',
    option3: '% nodes of the most change times',
    times: 'Change times',
    id: 'Node key',
    pieHeader: "'s "
  },

  gantt: {
    info1: 'Gantt Chart',
    info2: 'Name',
    info3: 'Cumulative time',
    info4: ' days',
    info5: ' weeks',
    infot: ' d',
    info6: ' hours',
    info7: ' minutes',
    info8: 'Recorded Time',
    info9: 'Confirmation',
    info10: 'Node moved successfully',
    info11: 'Node added successfully',
    info12: 'Modified time successfully',
    info13: 'Node removed from Gantt chart successfully',
    info14: 'Progress calculation successful',
    info15: 'There is currently no data available, please add nodes to the Gantt chart first',
    total: '',
    PleaseAdd: 'Please add nodes to the Gantt chart',
    AddNode: 'Add a node to the Gantt chart',
    PleaseEnterNodeID: 'Please enter node Key or keyword',
    confirmTheDeletion: 'Delete the current Gantt chart, all data resources in the Gantt chart will be cleared, but the node itself will not be deleted. Do you want to confirm the deletion?',
    CreateGantt: 'New gantt chart',
    selectAll: 'Select all',
    Name: 'Name',
    Desc: 'Description',
    nodeName: 'Node name',
    status: 'Status',
    workHours: " Planned",
    startTime: 'Scheduled start time',
    endTime: 'Scheduled end time',
    selectDate: 'Select date',
    GanttSettings: 'Gantt chart settings',
    NotificationRules: 'Notification rules',
    UnlockGantt: 'Purchase tips',
    Tip: ' Tip: Gantt chart is a paid feature, and only one item can be configured in the trial version. For the full version, please contact customer service to open it.',
    AutomaticScheduling: 'Automatic scheduling',
    ScheduleFromTop: 'Schedule from top',
    ScheduleFromBottom: 'Schedule from bottom',
    ScheduleFromTopTip: 'When the events in a Gantt chart are hierarchical, the parent event sets a time range within which the sub-events can only be completed. Therefore, the system will automatically adjust the time range of the sub-events',
    ScheduleFromTopBottomTip: 'When the events in a Gantt chart are hierarchical, the time range of the sub-event is set, the time range of the parent event can not be less. Therefore, the system will automatically adjust the time range of the parent events',
    irreversible: 'This operation is irreversible',
    ScheduleFromTip2: 'Are you sure want to',
    ScheduleFromTip3: '?',
    BeforeScheduleFromTop: 'Before scheduling',
    AfterScheduleFromTop: 'After scheduling',
    BeforeScheduleFromBottom: 'Before scheduling',
    AfterScheduleFromBottom: 'After scheduling',
    byMonth: 'Display by month',
    byGroup: 'Display by group',
    byResponsible: 'Display by assignee',
    byQuarte: 'Display by quarter',
    byYear: 'Display by year',
    NoResponsible: 'No responsible person',
    deletedSuccessfully: 'Gantt chart deleted successfully',
    hasChanged: 'The current Gantt chart structure has changed, some functions are not available before refreshing, please click',
    missingId: 'The node is missing id',
    structureModified: 'The current Gantt chart structure has been modified, you cannot add nodes before refresh',
    InsertFailed: 'Insert failed',
    TryAddingNodes: 'Try adding a node to the Gantt chart, or you can also go to the mind map page and click right-click to add.',
    NoCurrentlySelected: 'No gantt chart is currently selected',
    forbiddenBottomNodeUp: 'Illegal connection, forbidden to connect from the bottom node up',
    forbiddenNodesAtTheEnd: 'Illegal connection, forbidden to connect nodes at the end',
    forbiddenheadToTail: 'Illegal connection, forbidden to connect nodes from head to tail',
    forbiddenSameConnectionStake: 'Illegal connection, forbidden to initiate connection to the same connection stake',
    addedSuccessfully: 'Gantt chart added successfully',
    Quarter: 'Quarter',
    ScaledUpToUpperLimit: 'Enlarged to upper limit',
    ScaledDownToLowerLimit: 'Reduced to lower limit',
    NewGanttChartFailed: 'New failed',
    SetUpSuccessful: 'Successfully set',
    SetupFailed: 'Setting failed',
    ProgressCalculationSuccess: 'Progress calculation successful',
    ErrorPassingReference: 'Parameter error',
    NoProfileAvailable: 'No introduction',
    PermanentlyClosed: 'Permanent closed',
    tipworkhours: ' Please fill in the planned working hours first',
    SetAsMilestone: 'Set as milestone',
    UnsetMilestone: 'Cancel setting milestones',
    Remove: 'Remove',
    RemovingParentNode: 'Removing the parent node will result in the removal of all child nodes. Are you sure to proceed with the removal operation?',
    importGantt: 'Gantt chart import',
    nochoseGrantTip: "Please select a Gantt chart first",
    chart: {
      title: {
        title1: ' Status',
        title2: ' Assignee',
        title3: ' Working-Assignee',
        title4: ' Status-Assignee',
        title5: ' Actual',
        title6: ' Working(d)',
        title7: ' Time variance',
        title8: ' Total',
      }
    },
    table: {
      GanttChartDataSummary: 'Gantt chart data summary'
    }
  },

  personal: { // Personal center
    menu: { // Left menu
      user: 'User management',
      project: 'Project management',
      thirdParty: 'Apps integration',
      OtherSettings: 'Other settings',
      userGroup: 'Group management',
    },
    edit: {
      password: 'Change password',
      info: 'You have linked',
      place: 'Please enter verification code',
      new: 'Please enter a new password',
      confirm: 'Please confirm your new password',
      again: 'Please enter your new password again',
      no: 'The passwords you entered are inconsistent!',
      cap: 'Verification code',
      input: 'Please input the verification code',
      re: 'It will be resent after xxx seconds',
      success: 'The verification code has been sent',
      success2: 'Password has been reset',
      ava: 'The uploaded avatar picture size cannot exceed 5MB!',
      ing: 'Uploading',
      error: 'Upload failed',
      avatar: 'Avatar',
      click: 'Modify',
      info2: 'The name cannot be null',
      info3: 'It cannot be changed',
      info4: 'You cannot delete yourself!',
    },
    userManagement: { // User management
      table: { //User table
        name: 'Name',
        email: 'Email',
        role: 'Role',
        userType: 'User type',
        projects: 'Available projects',
        LastActiveTime: 'Last active time',
        options: {
          editUserInfoForm: { // Edit user information form
            title: 'Edit',
            name: 'Name',
            email: 'Email',
            role: 'Role'
          }
        },
      },
      newUserBtn: 'Invite new user', // Button for adding new user
      newUserForm: { // Add new user form
        tab1: 'Invite a user',
        tab2: 'Invite users in batch',
        info1: ' After adding a user, the user will automatically receive a login email',
        info2: 'Please download the import template:',
        info8: 'Import template.xlsx',
        name: 'Name',
        email: 'Email',
        role: 'Role',
        userType: 'User type',
        legal: 'It is legal',
        illegal: 'Reasons for being illegal',
        userType: 'user type',
        info3: 'The email address cannot be null',
        info4: 'Please enter the correct email address',
        info5: 'Please enter your username',
        info6: 'Please select a role',
        info7: 'Invited',
        info9: 'Please select a user type'
      },
      add: {
        p1: 'Common',
        p2: 'user are is and others are illegal. Please check the specific reasons',
        p3: 'One user is legal',
        p4: 'All users are illegal users. Please check the reasons carefully',
        p5: 'A user that does not exist in the table is recognized',
        p6: 'Are you sure you want to import it?'
      }
    },
    userGroupManagement: {// User Group management
      searchPlaceholder: 'Search',
      newGroup: 'New group ',
      editGroup: 'Edit Group',
      authorityTip: 'The current user currently does not have permission to operate',
      delete: {
        tip: 'prompt ',
        tipText: 'After deleting a user group, the permission settings based on that user group will be deleted as well. Are you sure to delete?',
        deletetext: 'Successfully deleted!',
        confirmCanael: 'cancel',
        confirmDelete: 'delete'
      },
      addGroup: {
        groupName: 'Group name',
        groupMember: 'Group members',
        groupDescription: 'Group description',
        placeholder: {
          info1: 'Please enter a group name',
          info3: 'Add description',
        },
      },
      table: {
        groupName: 'Group name ',
        member: 'Members',
        description: 'Description',
        operate: 'Operation',
      }
    },
    projectManagement: { // Project management
      tab1: 'Current projects',
      tab2: 'Deleted projects',
      table: { // Project table
        avatar: 'Project avatar',
        type: 'Project type',
        name: 'Project name',
        lastUpdated: 'Updated time',
        deleteAt: 'Deleted time',
        memberNum: 'Count of members',
        creator: 'Creator',
        leader: 'Project administrator',
        options: {
          editBtn: 'Edit',
          revertBtn: 'Recover',
          editMemberForm: { //Manage the member form
            name: 'Name',
            role: 'Role',
            position: 'Position',
            options: 'Remove the assignee',
            addNewUserBtn: 'Add user',
            addNewUserForm: {
              title: 'Add user',
              name: 'Name',
              position: 'Position'
            }
          },
          deleteForm: { // Delete the project and permanently delete the form
            title: 'Delete project',
            title2: 'Permanently delete the project',
            info1: 'Are you sure you want to',
            info2: ' permanently',
            info3: ' delete this project?',
            desc: {
              name: 'Project name',
              lastUpdated: 'Last modification',
              memberNum: 'Member number',
            }
          }
        }
      },
      message: {
        p1: 'Project team has been deleted',
        p2: 'Project team is recovered',
        p3: 'Please enter the correct project name',
        p4: 'The process has been initiated'
      }
    },
    thirdPartyManagement: { // Management of third party's application
      tab1: 'My Apps',
      tab2: 'Favorites',
      title: 'Marketplace',
      placeholder: 'Search',
      info: ' apps',
      detail: { // Detail page
        tab: 'About',
        intro: 'Profile',
        preview: 'Screenshot'
      },
      settings: { // Setting page
        setBtn: 'Settings',
        removeBtn: 'Remove',
        confirmForm: {
          title: 'Confirm operation',
          desc: 'Are you sure you want to remove this application?'
        }
      },
      free: 'Free',
      get: 'Get',
      config: 'config',
      add: 'Authorize and install',
      breadcrumb: { // Breadcrumb content
        app: 'Application',
        settings: 'Setting'
      }
    },
    openApi: {
      basicInfo: { // The first block
        title: 'Basic information',
        id: 'Tenant ID',
        note: 'If you think your secret key has been leaked, please modify it as soon as possible. After modifying the field, the old key will be invalid immediately. Please replace the old key with a new one in time',
        resetBtn: 'Reset API Secret',
        add: 'Create secret'
      },
      use: { // The second block
        title: 'Use open API',
        btn1: 'View access guide',
        btn2: 'View open API file'
      },
      add: {
        p1: 'Get the tenant ID and API secret',
        p2: 'Click the upper right corner of the interface to enter the project management interface, and click the <Open API> option at the bottom of the sidebar to obtain the tenant ID and API Secret',
        p3: 'Please carry the tenant ID and API secret in the request',
        p4: 'Add the following header to the Request header:',
        p5: 'Get the project ID',
        p6: 'After selecting the project, click < Settings >-< About Project > to copy the project ID',
        p7: 'The project ID is required for business operations under the project',
        p8: 'If users need to specify a project in some business operations , the project ID should be carried in the request. Please refer to ',
        p9: 'API documentation',
        p10: 'Example ',
        p11: '(In the following request, the project ID appears in the request as a path parameter):',
        p12: 'Project ID',
        p13: 'Open API access process'
      },
      message: 'Resetting succeeded!',
      message2: 'Created!',
      message3: 'Copied!'
    },
    otherSettings: {
      watermarkFunction: 'Watermark',
      share: 'Mind map sharing',
      PleaseEnter: 'Please enter the watermark content(optional)',
      tips: "When not filled out, display the user's email prefix by default. After filling out, display added content and user's email prefix",
      dangerzone: 'Danger zone',
      Deregistertenant: 'Deregister tenant',
      DeregistertenantDes: 'Before you proceed, please enter the email verification code you received to confirm that you are the authorized user performing this operation.',
      getCode: 'Verification code',
      please: 'Please enter verification code',
      warning: 'Warning!',
      warningMsg1: 'This operation will delete all data under this tenant. Please confirm if you want to proceed with the deletion.',
      warningMsg2: 'Warning! This operation is irreversible. Please confirm if you want to deregister.',
      warningMsg3: "Deregistering is considered a risky operation. After deregistering, all user data will be permanently lost and cannot be recovered. Please delete all users under the tenant first before proceeding with the above operation to ensure the security of your company's data.",
      deregister: 'Deregister',
      Custom: 'Custom property environment',
    },
    ones: {
      title: 'To authenticate Ones, you need to first create a "Custom OAuth Application" in Ones, then fill in the information in the current pop-up window and confirm the authentication',
      subtitle1: 'Create a "Custom OAuth Application"in Ones',
      step1before: 'Please refer to  (',
      step1middle: 'here',
      step1after: ') Create OAuth application',
      step2: 'Fill in the specified fields in Ones according to the instructions below, and then click confirm',
      authenticationType: 'Authentication Type',
      organization: 'Organization',
      Scope: 'Scope needs to be checked',
      projectManagement: 'Project Management',
      subtitle2: 'Fill in authentication information in the current pop-up window',
      step3: 'After creating the new Application, you can see the Application ID and Secret in the Application details. Please fill in the following fields based on the information above',
      pl1: 'Please copy and paste the Client ID here after creating the application',
      pl2: 'Please copy the Client Secret and paste it here after creating the application',
      pl3: 'Customize OAuth Application ->Your Domain Name',
    }
  },

  notice: { // Notice center
    title: 'Notification',
    menu: { // Left menu
      myAt: '@Me',
      myResponsibility: 'Assignee to me'
    },
    placeholder: 'No notice available',
    btn: {
      read: 'Mark all as read',
      delete: 'Delete read'
    },
    card: { // Card content
      time: 'Time',
      nodeName: 'Node name',
      del: 'Node has been deleted',
      com: 'Comment'
    },
    weeklyNotification: 'Weekly notification',
    message: 'This node has been deleted. You cannot jump to it.',
    FieldAssignmentReminder: 'Field assignment reminder',
    assign: 'Task assignment',
    batchAssign: 'Task batch assignment',
    knowledgeQuickShare: 'Wiki QuickShare',
    atInDocument: 'Mentions within the Wiki page',
    commentAt: 'Comment mentions',
    review: 'Invite review',
    endReview: 'End of review',
    version: "Version release notice",
    inviteDeleteProject: 'Project delete invitation',
    endDeleteProject: 'Project deletion complete',
    ganttStartNotifyAssignee: 'Gantt chart task started (responsible person)',
    ganttStartNotifyCollector: 'Gantt chart task start (Collector)',
    ganttEndNotifyReliedAssignee: 'Gantt chart parent dependency task end (responsible person)',
    ganttEndNotifyReliedCollector: 'Gantt chart parent dependency assignment ended (collector)',
    customSingleUserFieldChange: 'customSingleUserTypeFieldChange: "Custom single user type field notification',
    customMultiUserFieldChange: 'customMultiUserTypeFieldNotification',
    applyAccessOnDoc: 'Apply permission to read Wiki articles',
    Images: 'Images',
    ThisReviewDoesNotExist: 'This review does not exist',
    ThisInvitationDoesNotExist: 'This invitation does not exist',
    TheGanttChartDoesNotExist: 'The gantt chart does not exist',
    TheArticleDoesNotExist: 'The article does not exist',
    wordComments: 'Concerned Wiki pages have new fetch word comments',
    newPageComments: 'There are new page comments for the following Wiki pages',
    reviewEndInFourHours: "Review task ends within four hours",
    docWordCommentAt: "Word-level comment mentioned in the Wiki",
    docCommentAt: "Comment mentioned in the Wiki page",
    followDocChanged: "Content of followed Wiki page has been updated",
    followDocCommented: "Followed Wiki page has been commented on",
    testPlanEndInOneDay: "The test plan will end within 24 hours. Please handle it promptly",
    docInternetPermission: 'Document is set as internet ',
    dfmeaOptimization: 'DFMEA optimization task assignment',
    dfmeaOptimizationEnd: 'DFMEA optimization measures have reached the deadline',
    changeRequest: 'Invite to review change request',
    changeRequestRelated: 'Related nodes initiate change requests',
    statusTimeOut: 'Status stay timeout',
    over: 'over',
    days: 'days',
    editable: 'editable',
    readable: 'readable',
  },

  addNodeDetail: {
    attachments: "Attachments",
    title: 'Create a copy',
    lab1: 'Name',
    lab2: 'Reviewer',
    lab3: 'View details',
    info: 'The function of changing committee is not opened. Please contact the administrator',
    place: 'Please select a reviewer',
    lab4: 'Detailed description',
    field: 'Field',
    now: 'Current value',
    desc: 'Description',
    styPoint: 'Story point',
    change: 'Changed value',
    info2: 'The reviewing pass rate has exceeded ',
    info3: 'Please confirm whether you agree to this change',
    lab5: 'Select fields',
    next: 'Next',
    edit: 'Edit copy',
    res: 'Review result',
    people: 'Reviewer',
    comment: 'Comment',
    pass: 'Approve',
    rej: 'Reject',
    reason: 'Comments',
    upload: 'Submit',
    my: 'Submitted by myself',
    wait: 'To be reviewed',
    wait2: 'To be  confirmed',
    message1: 'Please enter change description',
    message2: 'Please enter the rejection reason',
    message3: 'Please enter a copy name',
    message4: 'Please select change field',
    message5: 'Change request',
    message6: 'Node name',
    message7: 'Modify',
    message8: 'Immediately merge',
    message9: 'Approved',
    message10: 'Combined',
    message11: 'Rejected',
    message12: 'Reviewing',
    message13: 'Agree',
    message14: 'Reject',
    message15: 'Submit a change request',
    message16: 'Change details',
    message17: 'Immediately  reject',
  },

  addMyFilePermission: {
    view: 'View',
    edit: 'Edit',
    download: 'Download',
    info1: 'Search to added members',
    role1: 'Development engineer',
    role2: 'Operation & maintenance engineer'
  },

  addMyFileHead: {
    desc: 'Are you sure to delete the conditions?',
    title: 'Tips',
    info: 'Deleted',
    info2: 'Deletion failed',
    info3: 'Saved',
    info4: 'Please enter a condition name'
  },

  addNodeTestPlan: {
    addnull: "Add empty step",
    copy: "Copy",
    del: "Delete",
    title: 'Test steps',
    lab1: 'Serial No. ',
    lab2: 'Precondition',
    place1: 'Please enter pre-conditions',
    lab3: 'Test step',
    place2: 'Please enter test steps',
    lab4: 'Expected result',
    place3: 'Please enter the anticipated result',
    btn1: 'Save',
    btn2: 'Edit',
    message: 'Saved',
  },

  addMindMap: {
    p1: 'You cannot delete the root node！',
    p2: 'Deleting this node will cause the subsequent leaf nodes to be deleted. Are you sure you want to delete it?',
    p3: 'After deleting the node, the node cannot be recovered. Are you sure you want to delete it?',
    p4: 'Shortcut key list',
    p5: 'Feedback',
    lab1: 'Test step',
    lab2: 'List of test steps',
    btn1: 'Save',
    btn2: 'Edit',
    lab3: 'Precondition',
    lab4: 'Test step',
    lab5: 'Expected result',
    info1: 'New sibling node',
    info2: 'New child node',
    info3: 'Edit text',
    info4: 'Fold/unfold',
    message1: 'All changes have been saved',
    message2: 'Nodes cannot be added when selected in batch',
    message3: 'Saving...',
    message4: 'You have no permission to delte the leaf node',
    message5: 'You have no permission to delte the non-leaf node',
    message6: 'The following nodes exist in an already started baseline and cannot be deleted: ',
    message7: 'The test plan of this use case has been completed, and deleting it will affect the test report; deleting this node will cause the subsequent leaf nodes to be deleted. Are you sure you want to delete it?',
    message8: 'The test plan of this use case has been completed, and deleting it will affect the test report. Are you sure you want to delete it?',
    message9: 'The test plan of this use case is in progress, and deleting this node will cause the subsequent leaf nodes to be deleted. Are you sure you want to delete it?',
    message10: 'The test plan of the use case is in progress. Are you sure you want to delete it?',
    message11: 'The locked node cannot be edited',
    message12: 'The locked node cannot be moved!',
    message13: 'This is the end',
    message14: 'You cannot go forward further',
    message15: 'Export succeeded',
    place1: 'Please enter the template name',
    title1: 'Setting name',
    title2: 'Tips',
    message16: 'The current page has been changed, please click',
    btn3: 'Refresh',
    message17: 'The node has been deleted!',
    message18: 'Setting succeeded',
    message19: 'Setting failed',
    message20: 'The parameter uploaded is not an object',
    message21: 'Root node cannot add sibling nodes',
    message22: 'Please select a node first',
    message23: 'The following nodes are locked in the baseline and cannot be moved: ',
    tip1: 'Completion:',
    tip2: 'Review',
    tip3: 'Invite review',
    tip4: 'Review task title',
    tip5: 'Enter the review task title',
    tip6: 'Review deadline',
    tip7: 'Select date and time',
    tip8: 'Fix review participants',
    tip9: 'Execute review',
    tip10: 'Select invitee',
    tip11: 'Provide the reason for rejection',
    tip12: 'Purchase tips',
    tip13: 'Note: AI supplement is a paid feature. The trial version allows 100 uses per day. For full feature, contact customer service.',
    tip14: 'All selected nodes are non-reviewable nodes',
    tip15: 'Nodes that have already passed cannot be review ',
    tip16: 'Nodes that are  under review cannot be review ',
    tip17: 'Please select a node',
    tip18: 'Provide the reason for approved',

    saveMindmapMoveDialogTitle: 'This requires you to log in to MappingSpace',
    hasAccount: 'Already have an account',
    noAccount: 'Register',
  },

  addFileList: {
    title1: 'Page preview',
    title2: 'PDF preview'
  },

  addMindWord: {
    message: 'The node is locked, and the node name cannot be edited'
  },

  addAddUser: {
    message: 'Please fill in at least a piece of user information'
  },

  addAt: {
    default: 'Full comment',
    depart: '(resigned)'
  },

  addLogin: {
    otherlogin: 'Other login methods',
    binding: 'Third-party binding',
    complete: 'Complete',
    introduce: 'One-stop requirement, development, and testing management \n platform for the automotive industry',
    solution: 'Domestic alternative preferred solution',
    forgetPsd: {
      title: 'Reset password',
      place1: 'Email',
      place2: 'New password',
      place3: '',
      title2: 'Already have an account?',
      title3: 'Go to login',
      title4: 'No account?',
      getCode: 'Get verification code',
      message: 'Email address cannot be null',
      message2: 'Please enter a correct email address',
      message3: 'Password has been reset',
      message4: 'The verification code has been sent',
      message5: 'It will be resent after', // e.g. resend in 60 seconds
      message6: 'Resend in 180 seconds',
      message7: 'Verification code sent',
      message8: 'Resend in ',
      message9: '  seconds',
      PleaseSetPassword: 'Please set password',
      JoinNow: 'Join Now',
      PleaseEnterTheCorrectEmailDddress: 'Please enter the correct email address'
    },
    loginEmail: {
      email: 'Log in by verifying with your email address',
      email2: 'Log in with verification code',
      psd: 'Log in with password',
      login: 'Login',
    },
    loginPsd: {
      forget: 'Forgot password?',
      place: 'Password',
      place2: 'Please enter your password'
    },
    myInfo: {
      email: 'My email',
      edit: 'Update email information',
      reset: 'Reset password'
    },
    register: {
      title: 'Register',
      place: 'Confirm password',
      register: 'Register now',
      message: 'Please enter your new password again',
      message2: 'The passwords you entered are inconsistent!',
      VerificationCodeSent: 'Verification code has been sent to cell phone',
      PleaseEnterTheCorrectCellPhoneNumber: 'Please enter the correct cell phone number',
      OperateFrequentPrompts: 'User actions are too frequent',
      VerificationCodeSentToCellPhone: '',
      PleaseEnterYourCellPhoneNumber: 'Please enter your cell phone number',
      agreement: 'I agree Suzhou Yunti Technology Co., Ltd.\'s',
      privacyPolicy: 'Privacy policy',
      termsOfUse: 'Terms of use',
      and: 'and',
      message3: 'In order to provide better service, you need to verify your mobile phone number',
      verificationCode: 'Verification code',
      mobilePhoneNumber: 'phone number',
    },
    resetEmail: {
      h2: 'Update email information',
      my: 'My email address',
      new: 'New email address',
      new2: 'New email address',
      psd: 'Password',
      edit: 'Change',
      return: 'Return',
      reset: 'Change password',
      message: {
        p1: 'Changed',
        p2: 'Change failed. The email address has already been used, please try again',
        p3: 'Change failed. The password is incorrect. please try again',
        p4: 'Change failed. Please try again later',
        p5: 'Change canceled'
      },
      form: {
        desc: 'Are you sure you want to update the email address as:',
        title: 'Tips'
      }
    },
    resetPsd: {
      title: 'reset password',
      old: 'Old password',
      new: 'New password',
      confirm: 'Confirm new password',
      place: 'Please enter your old password',
      btn: 'Reset now',
      message: {
        p1: 'Resetting succeeded!',
        p2: 'Resetting failed. The password is incorrect. Please try again',
        p3: 'Resetting failed. Please try again later',
        p4: 'Change canceled'
      },
      form: {
        desc: 'Are you sure you want to change the password?',
        title: 'Tips'
      }
    },
    background: 'Background picture source',
    loginr: {
      name: 'Name',
      phone: 'Contact number',
      company: 'Your company name',
      email: 'Email address',
      code: '',
      try: 'Login',
      place1: 'Please enter your company’s email address',
      place2: 'Registration is only available for company’s email address',
      place3: 'Please check the agreement.'
    },
    WelcomeToJoin: 'Welcome to join',
    WelcomeToYuntiTechnology: 'Welcome to Yunti Technology',
  },

  addMyColla: {
    mySend: 'Invitations sent',
    myReceive: 'Invitations received',
    allReviewRequest: 'All review requests',
    fileType: 'File type',
    need: 'Requirement',
    archi: 'Architecture',
    detailDesign: 'Detailed design',
    testcase: 'Test case',
    fileName: 'File name',
    complete: 'Review completed',
    creator: 'Creator',
    time: 'Invitation time',
    option: 'Operation',
    edit: 'Edit',
    review: 'Review',
    view: 'View',
    table: {
      title1: 'Review task title',
      title2: 'Review status',
      title3: 'Initiator',
      title4: 'Deadline',
      title5: 'Completed reviewers',
      title6: 'Incomplete: ',
      title7: 'More',
      title8: 'Send reminder again',
      title9: 'Close review in advance',
      title10: 'Delete',
      title11: 'Filter',
      title12: 'Select all',
      title13: 'Initiation time',
      title14: 'Incomplete: ',
    },
    chart: {
      title1: 'Node name',
      title2: 'Assignee',
      title3: 'Review result',
      title4: 'Node average review duration',
      title5: 'File type',
      title6: 'Duration (Days)',
    },
    message: {
      title1: 'After deleting the review task, the review records will be deleted together. Are you sure you want to delete it?',
      title2: 'Cancel',
      title3: 'Deletion canceled',
      title4: 'Reminder sent successfully',
      title5: 'Review ended successfully',
    },
    status: {
      st1: 'Completed',
      st2: 'Pending review',
      st3: 'In review',
      st4: 'Ended',
      st5: 'Passed on 1st review',
      st6: 'Passed on 2nd review',
      st7: 'Passed on 3rd review or more',
      st8: 'Failed',
      st9: 'Not reviewed'
    }
  },

  addTopBar: {
    excel: 'excel template'
  },

  addAuthentication: {
    index: 'Authentication failed'
  },

  addEditCopy: {
    name: 'Name',
    storyPoint: 'Story point',
    num: 'Please enter a number',
    name1: 'Please enter a copy name',
    node: 'Please enter the node name',
    nodeName: 'Node name',
    desc: 'Description',
    limit: 'Please enter a positive integer in the story point field',
  },

  addTemplate: {
    h1: 'Manage templates',
    name: 'Template name',
    time: 'Creation time',
    option: 'Operation',
    no: 'Use no template',
    message: 'Deleted',
    error: 'Deletion failed',
  },

  addModules: {
    alert: 'The system has detected that you have switched a user. Please refresh the page before operating.',
    btn: 'Refresh'
  },

  addNum: '(s)',
  addNum2: '',
  or: 'or',
  year: 'year',
  yearMonth: 'January',
  today: 'Today',
  me: 'me',
  addJs: {
    close: 'Permanently closed',
  },
  addAlert: {
    collapse: 'collapse sidebar'
  },
  certificate: {
    Prompt: 'For each recommended friend, both parties will receive an additional 10 days of free trial time. For every additional user who enters the tenant, an additional 3 days of free trial time will be added.',
    Copy: 'Copy the following link, share to register',
    Please: 'Please go to ',
    CertificateManagement: 'license management ',
    InstallComponents: 'install components',
    Recommend: 'Referral',
    ClickInstall: 'Install',
    Installed: 'Installed',
    Expired: 'Expired',
    PurchaseCertificate: 'Purchase certificate',
    BasicSoftware: 'Basic software',
    CertificateType: 'Certificate type',
    MaximumNumberOfUsers: 'Maximum fixed users',
    Used: 'Occupied',
    FloatMaximumNumberOfUsers: 'Maximum floating users',
    ENTERPRISE_EDITION: 'Fixed',
    FLOAT_EDITION: 'Float',
    MIXED_EDITION: 'Hybrid',
    EnterpriseEdition: 'Fixed license',
    FreeEdition: 'Free edition',
    FloatEdition: 'Floating license',
    ExpirationDate: 'Expiration date',
    NoExpirationDate: 'No expiration date',
    ClickToBuy: 'Click to buy',
    FeeBasedComponents: 'Marketplace',
    UpgradePrice: 'Upgrade price',
    PublicCloudVersion: 'Public cloud version',
    Purchased: 'Purchased',
    Unpurchased: 'Unpurchased',
    Introduction: 'Introduction',
    UnpaidUsers: 'Click to install. After installation, you may use the trial for one month.',
    Preview: 'Preview',
    GanttDes: "Gantt chart, as an effective chart visualization tool for project management, can carry out efficient project process control, clarify the team's project phase goals, and orchestrate multi-node projects. For agile R&D project scenarios, Gantt Chart provides project planning management tools with a smooth working experience for teams to efficiently manage project nodes and control project progress.",
    Price: process.env.NODE_ENV === 'intl' ? '$/person/month' : '￥/person/month',
    PricePerSet: process.env.NODE_ENV === 'intl' ? '$/set' : '￥/set',
    AccordingToTheModel: 'According to the model',
    AutomatedWorkflow: 'Automated workflow',
    AutomatedWorkflowDes: 'Automated workflow designs and executes a series of repetitive business tasks based on business rules to reduce human interaction and improve the efficiency of project operations. Each task is standardized, thus reducing decision errors in manual processes. Unnecessary administration is also eliminated, and workflow automation ensures efficient completion of task transfer and evaluation within teams in multi-node projects.',
    VModelPanorama: 'V-Model panorama',
    WModelPanorama: 'W-Model panorama',
    VModelPanoramaDes: 'V-Model panorama is used to show the coverage between different node types. It can show the percentage of coverage of requirements, architecture, test cases, etc. in a project on a single graph. By clicking on the percentage, data penetration can be performed to see which nodes are not covered, helping users to establish a more complete traceability of the correlation, in addition, it supports displaying in a two-dimensional coverage report in the data report, which makes it easier to view the correlation between nodes.',
    WodelPanoramaDes: 'W-Model panorama is used to show the coverage between different node types. It can show the percentage of coverage of requirements, architecture, test cases, etc. in a project on a single graph. By clicking on the percentage, data penetration can be performed to see which nodes are not covered, helping users to establish a more complete traceability of the correlation.',
    AIRobot: 'AI robot',
    AIRobotDes: "The AI robot is used to help users draw structure diagram, create requirements and test cases, and also optimize the already generated requirements and test cases in specified directions, which greatly improves users' work efficiency and broadens their thinking. It can also automatically supplement nodes on the mind map page to help users create relevant sub-cases.",
    AIRobotTip: " Currently, the optional large language model include ChatGPT and Mobvoi. Users can also provide their own large language model interfaces.",
    AutomatedTesting: 'Automated testing',
    AutomatedTestingDes: 'After the automation test script is executed, test cases are automatically generated in MappingSpace, and test plans are automatically created, test results are filled in, and test reports are generated. For a more detailed manual, please check',
    KnowledgeBase: 'Wiki',
    KnowledgeBaseDes: 'The Wiki is a knowledge management tool component that enables structured organization of online collaborative documents and facilitates knowledge reuse. It can also integrate the knowledge network of a team project and access the corresponding Wiki directly within the software project, as well as call the Wiki documents during the use of the software.',
    BasicSoftwarePurchaseTips: 'Basic software purchase tips',
    GanttPurchaseTips: 'Purchase tips for gantt chart components',
    AutomatedWorkflowPurchaseTips: 'Automated workflow component purchase tips',
    KnowledgeBasePurchaseTips: 'Wiki component purchase tips',
    VModelPanoramaPurchaseTips: 'V-Model panorama component purchase tips',
    AIRoboticsPurchaseTips: 'AI robotics component purchase tips',
    AutomationTestingPurchaseTips: 'Automation testing component purchase tips',
    UnlocktheTtheVmodel: 'Unlock the panoramic view function of the V model',
    UnlocktheTtheVmodelTips: 'Reminder: The panoramic view of the V model is a paid feature, and the trial version can only be configured with one project. Full version functionality, please contact customer service to activate.',
    HARA: 'HARA',
    HARADes: 'HARA analysis is an important analytical method in the field of functional safety, used to identify and evaluate potential hazards and risks. HARA (Hazard Analysis and Risk Assessment) refers to the analysis and assessment of hazards in a system, product, or service. Through HARA analysis, potential hazards and risks can be identified and identified in advance, and corresponding measures can be taken to ensure the functional safety of the system. This is very important for many key industries, such as automobiles, aerospace, and medical equipmen.',
    HARAPurchaseTips: 'HARA component purchase tips',
    DFMEA: 'DFMEA',
    DFMEADes: 'DFMEA (Design Failure Mode and Effects Analysis) is a method in functional safety analysis used to evaluate and reduce potential failure modes and their impact on system performance during the design process. Through DFMEA, the team is able to identify and evaluate potential failure modes during the design process, and take corresponding measures to reduce the occurrence and impact of faults, thereby improving the functional safety of the system.',
    DFMEAPurchaseTips: 'DFMEA component purchase tips',
    FTA: 'FTA',
    FTADesc: "Fault Tree Analysis (FTA) is a top-down deductive failure analysis method. Fault tree analysis is mainly used in the fields of safety engineering and reliability engineering to understand the causes of system failures and find the best way to reduce risks, or to confirm the occurrence rate of a specific safety accident or system failure. It is one of the commonly used tools in ISO 26262 functional safety. By utilizing the mind map structure of MappingSpace files, fault trees can be quickly configured and related calculations can be performed.",
    FTAPurchaseTips: "FTA component purchase tips",
    FTAUnpaidUsers: "Unpaid users can only use this feature in the sample files in the car template project",
    TARADesc: "TARA (Threat Analysis and Risk Assessment). In the ISO/SAE 21434 standard, it is considered the core means of network security analysis. The use of TARA for analysis can identify potential threats and corresponding risk levels that vehicles may face, which plays a crucial role in determining network security objectives and also provides strong guidance for formulating network security requirements. This will directly affect subsequent development activities. By utilizing the mind map structure of the MappingSpace file, TARA can be quickly configured for analysis.",
    TARAPurchaseTips: "TARA component purchase tips",
    ASPICETitle: "ASPICE Project template",
    MappingGPT: 'MappingGPT',
    MappingGPTPurchaseTips: 'ASPICEcomponent purchase tips',
    MGPTDesc: 'Our product is an innovative AI-powered tool that generates software code and documentation, offering developers a brand new way to program. Whether you are a beginner or an experienced developer, our AI assistant is designed to quickly produce high-quality code and detailed documentation for your projects. By utilizing our product, you can save a significant amount of time and effort, enhancing your development efficiency. Simply input your requirements and specifications, and our AI assistant will automatically generate the corresponding code and documentation, ensuring the smooth progress of your project.',
    ASPICEDesc: "ASPICE, the acronym for Automotive Software Process Improvement and Capability Determination, is a standard framework aimed at guiding and appraising the capability of embedded system development and management processes within the automotive industry. Built on the ISO/IEC 15504 standard, it has been customized to meet the specific needs of the automotive sector. Through the maturity assessment of data from engineering and project management processes, ASPICE helps to enhance process quality and performance among manufacturers and suppliers across the supply chain. The framework includes necessary project configurations and various document templates required for ASPICE projects.",
    ASPICEPurchaseTips: "ASPICE component purchase tips",
    SecCTDesc: 'SecCT is an information security compliance testing tool independently developed by uni-sentry. This tool complies with international regulations such as UN/WP.29 R155 and GDPR, as well as national technical standards and requirements for complete vehicles, components, and data security. By transforming the technical experience of professional hackers into standardized and streamlined automated testing specifications, test cases, and testing processes, SecCT helps OEMs and component manufacturers build information security testing capabilities and improve professional testing technology levels. The test cases in SecCT can be bi-directionally traced with the requirements in MappingSpace, and more security compliance test results will be displayed in MappingSpace.',
    SecCTPurchaseTips: 'Reminder: SecCT is a paid feature, please contact customer service to activate.',
    unSupportTrial: "This plug-in does not support trial.",
    shareCopyText: "Here is a cool ALM software, come and check it out!",
    recommendedBenefit: 'Referral reward policy ',
    third: 'Universal web connection key',
    thirdDes: `The Universal Web Connection Key aims to seamlessly integrate third-party website links to specific nodes, achieving instant access and convenient viewing of information. This tool allows you to directly bind external web page links or HTML documents to nodes, and then seamlessly view or redirect to these resources in the node details page, greatly improving the smoothness and efficiency of operations.
We are widely compatible with various HTML content sources, including but not limited to Figma and real-time design sharing pages, as well as HTML files generated by professional tools such as MATLAB, ensuring that your creative works or research materials can seamlessly integrate into your project system and workflow no matter where they come from, bringing unprecedented efficiency and flexibility to your work.`,
    thirdPurchaseTips: 'Universal Web Connection Key Component Purchase Tips'
  },
  personalAside: {
    SoftwareCertificateManagement: 'License management',
    checkPsw: '8 or more upper and lower case letters, numbers, special characters'
  },
  deletProject: {
    deletProjectBefore: 'the ',
    deletProjectAfter: ' project will be deleted, the operation will clear all data resources under the project, the operation is irreversible!',
    inputProjectName: 'please manually enter the current project name to be deleted',
    deletProjectStep2: "The project deletion operation needs at least one more system administrator's consent, do you want to initiate the deletion process?",
    DoYouAgree: 'Do you agree to delete the',
    project: 'project',
    irreversible: 'This operation will clear all data resources under the project and the operation is irreversible!',
    Agree: 'Agree',
    Deny: 'Deny',
    ProjectDeletionProcessRejected: 'The project deletion process has been rejected and no further review is required',
    ProjectHasBeenDeleted: 'The project has been deleted and no further review is required',
  },
  Jira: {
    desBefore: 'You are not yet bound to any Jira Cloud account, to use the application, please click ',
    desMiddle: 'this link',
    desAfter: ' to complete the authentication process',
    redesBefore: ' You have already completed Jira Cloud account binding, to re-authenticate, click ',
    redesMiddle: 'this link',
    redesAfter: ' to do so',
    compDes: 'You have finished authenticating with Jira Cloud, now go use the application!'
  },
  Ones: {
    desBefore: 'You have completed the Ones configuration. If you need to reconfigure, please click',
    desMiddle: "here",
  },
  thirdPartyDetail: {
    des: 'Please go to "Settings > About Project" to configure your specific project after you enter it!',
    click: 'Click ',
    this: 'here ',
    config: 'to see how to configure',
    noAccess: 'If you do not have access, please contact your system administrator!',
  },
  moveFile: {
    sureToMove: 'sure to move?'
  },
  globleMixin: {
    ExportCompleted: 'Export completed',
    ExportFailed: 'Export failed',
  },
  myMind: {
    CurrentFileIsDeleted: 'Current file is deleted'
  },
  workSpace: {
    UpcomingExpirationAlert: 'Upcoming expiration alert',
    enterOfficalWeb: 'Homepage',
    WeChatContent: 'Welcome to follow the WeChat official account of "Yunti Technology" to get firsthand information about our products!',
    welComeTitle1: 'Dear ',
    welComeTitle2: ', welcome to MappingSpace!',
    welComeContent: 'In the following, we will introduce you to a capable assistant.',
    fun1Title: 'Compliant with ASPICE standard traceability',
    fun1Content: 'Based on ASPICE 3.1 V model or ASPICE 4.0 W model, traceability of R&D tasks can be accessed at any time.',
    fun1Gif: `${config.oss_url}/Frontend/gif/1_en.gif`,
    fun2Title: 'Create standards-compliant R&D process documentation with one click',
    fun2Content: 'Built in massive templates, enterprise level templates can also be customized, improving document writing efficiency by 50%.',
    fun2Gif: `${config.oss_url}/Frontend/gif/2_en.gif`,
    fun3Title: 'Clearly structured test case management',
    fun3Content: 'Create test cases based on mind maps, clearly view test steps, batch associate requirements to be tested, execute test plans, and automatically generate test reports.',
    fun3Gif: `${config.oss_url}/Frontend/gif/3_en.gif`,
    fun4Title: 'Online requirement reviews',
    fun4Content: 'Invite reviews in batches, customize review entries, perform reviews online, view review results, and automatically track review problem points until closed.',
    fun4Gif: `${config.oss_url}/Frontend/gif/4_en.gif`,
    fun5Title: 'Baseline and change reviews',
    fun5Content: 'Create baselines, add nodes to baselines, start baselines to freeze requirements, submit change review requests, and seamlessly integrate baseline and change processes.',
    fun5Gif: `${config.oss_url}/Frontend/gif/5_en.gif`,
    fun6Title: 'Functional safety and cyber security processes',
    fun6Content: 'Enter the "Automotive Project Template" and view the safety section to experience unique modules of functional safety, including HARA, DFMEA, FTA, TARA, ensuring safety is well managed.',
    fun6Gif: `${config.oss_url}/Frontend/gif/6_en.gif`,
    fun7Title: 'AI R&D accelerator',
    fun7Content: 'Click on the bottom right corner to summon the AI robot and experience the latest AI generation technology. AI features are also available in the knowledge base.',
    fun7Gif: `${config.oss_url}/Frontend/gif/7_en.gif`,
    fun8Title: 'Quick import of offline documents',
    fun8Content: 'Click "Create" and "Batch Import" to quickly import offline Word and Excel documents. Friendly reminder: When importing Excel, if you can\'t find the required fields, you can freely configure them within the project.',
    fun8Gif: `${config.oss_url}/Frontend/gif/8_en.gif`,
    fun9Title: 'Powerful plugins',
    fun9Content: 'Click on the avatar in the top right corner, go to "License Management" select the paid components you prefer, and click "Install" to experience the unique features of different plugins.',
    fun9Gif: `${config.oss_url}/Frontend/gif/9_en.gif`,
    fun10Title: 'Team collaboration',
    fun10Content: 'Click on the avatar in the top right corner, go to "User Management" and invite your colleagues to collaborate efficiently in MappingSpace.',
    fun10Gif: `${config.oss_url}/Frontend/gif/10_en.gif`,
    fun11Title: 'Brand ambassador',
    fun11Content: 'Good things are meant to be shared! Please share this software with those in need, and we have also prepared exclusive promotional gifts for you as our brand ambassador.',
    fun11Gif: `${config.oss_url}/Frontend/gif/11_en.gif`,
    update: {
      pic1: `${config.oss_url}/Frontend/update/1-en.png`,
      pic2: `${config.oss_url}/Frontend/update/2-en.gif`,
      pic3: `${config.oss_url}/Frontend/update/3-en.gif`,
      pic4: `${config.oss_url}/Frontend/update/4-en.gif`,
      pic5: `${config.oss_url}/Frontend/update/5-en.gif`,
      pic6: `${config.oss_url}/Frontend/update/6-en.gif`,
      pic7: `${config.oss_url}/Frontend/update/7-en.png`,
      title1: 'Two-dimensional coverage dashboard',
      des1: 'Based on the traceability relationship between requirements architecture detailed design test cases, real-time display of two-dimensional traceability reports can meet ASPICE review requirements',
      title2: 'Universal web connection',
      des2: 'Added integration of various software such as Matlab/Simulink, PLM, Figma, and real-time design, allowing real-time viewing of dynamics in other software on the node details page',
      title3: 'Model View',
      des3: 'Half tree structure, half model view, and the ability to directly associate SysML model elements with architecture, establishing fine-grained traceability between architecture tasks and SysML',
      title4: 'Tree View',
      des4: 'View the node details page in a tree structure to further expand the boundaries of the Word view',
      title5: 'Advanced Search',
      des5: 'For node queries, support combination queries of elements such as and, or, non, contain, etc., greatly improving query efficiency.',
      title6: 'Reuse Node Synchronization',
      des6: 'Add node synchronization functionality for scenarios where requirements, architecture, and other nodes are reused in other projects. No matter how they are modified after reuse, the reused requirements can be kept consistent with the original requirements at any time',
      title7: 'Reuse Graph',
      des7: 'Added the feature of requirement reuse graph, which allows real-time viewing of the number of times requirements have been reused and the modification status after reuse through a visual graphical page',
      newVersion: 'new version released',
      newVersionDes: 'More efficient and intuitive system functions, comprehensively improving your work efficiency and operational experience',
      seeIntroduction: 'View the introduction of the new version',
      share: 'share'
    }
  },
  dataChart: {
    PleaseEnterReportName: 'Please enter report name',
    XAxisFields: 'X-axis fields',
    YAxisFields: 'Y-axis fields',
    Quantity: 'Quantity'
  },
  switch: {
    PutAway: 'Put away',
    Expand: 'Expand',
  },
  chartTop: {
    sureDelete: 'Are you sure you want to delete it? Deletion is not recoverable',
    noPermission: 'You do not have permission to view this report'
  },
  baseChart: {
    selectCorrectTimeRange: 'Please select the correct time range'
  },
  vmodelChart: {
    node: 'node',
    is: '',
    notAssociated: 'not associated'
  },
  knowledge: {
    knowledge_ai: {
      title1: 'AI intelligent writing',
      title2: 'Advanced AI intelligent writing',
      title3: 'Please refine your prompt',
      title4: "AI's previous response",
      placeholder1: "Please enter content",
      placeholder2: "Please select",
      placeholder3: "You can modify the prompt to generate the image again",
      placeholder4: "Optional",
      placeholder5: "Required",
      code_lang: "Code lang",
      btn1: "Generate again",
      btn2: "Not satisfied",
      btn3: "Satisfied",
      bumanyi_form1: "As a/an",
      bumanyi_form2: "I wish to improve the above content",
      bumanyi_form3: "To Achieve",
      bumanyi_form4: "Purpose",
      bumanyi_form5: "In addition, I have another requirement:",
      message1: "Please select an operation",
      message2: "Please enter code language",
      message3: "Please fill in the required fields",
      op1: "Expand",
      op2: "Polish",
      op3: "Draw",
      op4: "Summarize",
      op5: "Write test cases",
      op6: "Generate code",
      op7: "Generate structure diagram",

    },
    delete_at: 'The message that mentioned you has been deleted',
    user: 'User',
    userGroup: 'User group',
    NodeunlockedexpiredTip: "The node has been unlocked and the change request has expired",
    ArticleOutline: 'Chapter',
    NoPagesSelected: 'No pages selected',
    SorryNoPermission: 'Sorry, you do not have permission to view the content of this article',
    RequestPermission: 'Request permission to view',
    ViewAccessRequested: 'View access has been requested, please try again later',
    PleaseEnterContent: 'Please enter content',
    noPermission: 'No permission',
    KnowledgeBaseRecycleBin: 'Wiki recycle bin',
    UnlockKnowledgeBaseFeatures: 'Purchase tips',
    KnowledgeBasePaidFeature: 'Note: Wiki is a paid feature, please contact customer service to activate.',
    TheCurrentKnowledgeBaseChanged: 'The current Wiki directory has changed. Please click',
    SomeFeaturesDisabledUntilThen: 'to refresh. Some features will be disabled until then.',
    NewArticle: 'New article',
    Loading: 'Loading',
    EnteringKnowledgeBase: 'Entering Wiki',
    SourceHanSans: 'Source Han Sans',
    MicrosoftYaHei: 'Microsoft YaHei',
    SimSun: 'SimSun',
    SimHei: 'SimHei',
    KaiTi: 'KaiTi',
    FangSong: 'FangSong',
    lineSpacing: 'x line spacing',
    body: 'Body',
    Heading1: 'Heading 1',
    Heading2: 'Heading 2',
    Heading3: 'Heading 3',
    Heading4: 'Heading 4',
    Heading5: 'Heading 5',
    Heading6: 'Heading 6',
    Undo: 'Undo',
    Redo: 'Redo',
    Format: 'Format painter',
    Bold: 'Bold',
    Italic: 'Italic',
    Underline: 'Underline',
    ParagraphFormat: 'Paragraph format',
    Strikethrough: 'Strikethrough',
    Blockquote: 'Blockquote',
    InsertCode: 'Insert code',
    InsertCodeBlock: 'Insert code block',
    Font: 'Font',
    FontSize: 'Font size',
    NumberedList: 'Numbered list',
    BulletedList: 'Bulleted list',
    TextDirectio: 'Text direction',
    Alignment: 'Alignment',
    FontColor: 'Font color',
    BackgroundColor: 'Background color',
    Image: 'Image',
    Video: 'Video',
    AddLink: 'Add link',
    InsertFormula: 'Insert formula',
    ClearFontFormatting: 'Clear font formatting',
    InsertTable: 'Insert table',
    Subscript: 'Subscript',
    Superscript: 'Superscript',
    DecreaseIndent: 'Decrease indent',
    IncreaseIndent: 'Increase indent',
    HeadingSize: 'Heading size',
    AlignLeft: 'Align left',
    AlignRight: 'Align right',
    AlignCenter: 'Align center',
    Justify: 'Justify',
    HighlightBlock: 'Highlight block',
    PleaseConfirmTheLocation: 'Please confirm the location to insert the table.',
    Creator: 'Creator',
    LatestVersion: 'Latest version',
    Exit: 'Exit',
    articleTitle: 'Please enter the article title.',
    sureExit: 'There are images on the current page that have not finished uploading. Are you sure you want to exit, which may result in losing the images?',
    GenerateSharingLink: 'Generate sharing link',
    tooLarge: 'The file is too large to upload',
    InsertOperation: 'Insert operation',
    InsertRight: 'Insert column on the right',
    InsertLeft: 'Insert column on the left',
    InsertAbove: 'Insert row above',
    InsertBelow: 'Insert row below',
    MergeCells: 'Merge cells',
    SplitCells: 'Split cells',
    DeleteColumn: 'Delete column',
    DeleteRow: 'Delete row',
    DeleteTable: 'Delete table',
    SelectText: 'Please select text first.',
    LastEditedTime: ' last edited time',
    CommentSection: 'Comment',
    NoComment: 'No comment',
    CommentNotEmpty: 'Comment cannot be empty',
    WordExtractComment: 'Comment',
    Comment: 'Comment',
    WordExtractCommentSection: 'Inline comments',
    Abandoned: 'Abandoned',
    Reply: 'Reply',
    RightClickInfo: 'Right-click on a paragraph or text to perform operations such as link sharing, word extraction, and commenting',
    QuestionOverview: 'Overview of questions',
    OneClickConversion: 'One-click conversion to mind map',
    ViewMindMap: 'View mind map',
    Deleted: 'Deleted',
    CreateMindMap: 'Create a new mind map',
    Unfavorite: 'Unfavorite',
    Favorite: 'Favorite',
    QuickShare: 'Quick share',
    CopyExternalLink: 'Copy external link',
    TransferOwnership: 'Transfer ownership',
    OverwriteMindMap: 'Overwrite',
    DeleteExceptRoot: 'All nodes except the root node of the original mind map will be deleted.',
    LinkArticleHeadings: 'Headings of the article will be linked to the newly generated nodes.',
    PreserveOriginalMap: 'Preserve',
    PreserveRemoveLink: 'The original mind map will be preserved, but all connections to the article will be removed.',
    LinkNewMindMap: 'The article will be linked to the newly created mind map.',
    ShareWithinTenant: 'Share the article with other users within the tenant to view',
    ShareWithUsers: 'Share with users',
    QuickShareNote: 'Note: Quick share is valid for 24 hours',
    ConfirmSharing: 'Confirm sharing',
    Permissions: 'Permissions',
    PageOwner: 'Page owner',
    Private: 'Private',
    InternallyPublic: 'Internal access',
    InternalPermissionSettings: 'Internal permission settings',
    Add: 'Add',
    SelectAtLeastOneUser: 'Please select at least one user',
    InternetPermissionSettings: 'Internet permission settings',
    InternetUsersCanView: 'External users can view',
    InternetUsersCanEdit: 'External users can edit',
    SyncSubpagePermissions: 'One-click synchronization of subpage permissions',
    ExternalLink: 'External link',
    CopyText: '【MappingSpace】I created a page for you, check it out! ',
    Copy: 'Copy',
    SelectUsers: 'Select users',
    ConfirmTransfer: 'Confirm transfer',
    OverviewOfQuestions: 'Overview of questions',
    SelectFileType: 'Please select the file type to convert to',
    PermissionInconsistencyWarning: 'The current page permission is inconsistent with the parent permission. Are you sure you want to modify it?',
    SelectTargetUser: 'Please select the target user',
    TransferSuccessful: 'Transfer successful',
    ConfirmOverwrite: 'Confirm overwrite?',
    DeleteNodesWarning: 'All nodes except the root node of the original mind map will be deleted',
    Overwrite: 'Overwrite',
    CancelOverwrite: 'Cancel overwrite',
    RefreshSuccessful: 'Refresh successful',
    RefreshFailed: 'Refresh failed',
    ConversionSuccessful: 'Conversion successful',
    GoToMapPage: 'Click to view the map and go to the map page',
    ReturnToKnowledgeBase: 'Return to the Wiki',
    TransformToMindMap: 'Your page content will be transformed into a mind map based on the headings in the directory. After the transformation is successful, you can view it in MappingSpace',
    TransformingIntoMindMap: 'Transforming into a mind map...',
    BookmarkSuccessful: 'Bookmark successful',
    ExportSuccessful: 'Export successful',
    ConfirmPageDeletion1: 'Are you sure you want to delete this page? ',
    ConfirmPageDeletion2: 'Subpages will be deleted together. ',
    ConfirmPageDeletion3: 'After deletion, you can restore it in the Wiki recycle bin',
    Restore: 'Restore',
    PermanentlyDelete: 'Permanently delete',
    Title: 'Title',
    Operator: 'Operator',
    DeletionTime: 'Deletion time',
    SelectAtLeastOnePage: 'Please select at least one page',
    ConfirmPermanentDeletion: 'Are you sure you want to permanently delete the selected page? After deletion, the page will disappear permanently',
    internalSharingLinks: 'Internal sharing links',
    externalSharingLinks: 'External sharing links',
    OneClickToMindMap: 'One-click to mind map',
    PermissionSetting: 'Permission setting',
    Unfollow: 'Unfollow',
    Follow: 'Follow',
    ViewHistoricalVersions: 'View change history',
    RegenerateMindMap: 'Regenerate mind map',
    regenerateNewOne: 'The original mind map has been deleted, regenerate a new one.',
    MoveAcrossProjectPages: 'Move across project pages',
    TargetProject: 'Target project',
    TargetParentPage: 'Target parent page',
    includeChildren: 'Include children',
    includeChildren2: 'Include children',
    RootDirectory: 'Root directory',
    KeepOriginalPage: 'Keep original page',
    SameProjectPageCopy: 'Same project copy',
    PleaseSelect: 'Please select',
    TargetItemCannotBeEmpty: 'Target item cannot be empty',
    SharingSucceeded: 'Sharing succeeded',
    FileTypeCannotBeEmpty: 'File type cannot be empty',
    Exporting: 'Exporting',
    DeletionCanceled: 'Deletion canceled',
    DeletingPageSucceeded: 'Deleting page succeeded',
    MovingAPage: 'Moving a page only moves the title and page content, confirm that the original page is not preserved?',
    wanning: 'wanning',
    PageCopySucceeded: 'Page copy succeeded',
    PageMovedSuccessfully: 'Page moved successfully',
    HistoricalVersions: 'Historical versions',
    VersionComparison: 'Version comparison',
    CurrentVersion: 'Current version',
    LastEditedBy: 'Last edited by',
    AddedContent: 'Added content',
    DeletedContent: 'Deleted content',
    RestoreThisPage: 'Restore this page',
    JumpMindMap: 'Jump mind map',
    JumpToKnowledgeBase: 'Jump to Wiki',
    InternetReadable: 'Viewable to public',
    InternetEditable: 'Editable by public',
    ArticleAuth: 'Article access permissions',
    copied: 'Copied',
    copiedLink: 'Copied the sharing link',
    pleaseFocus: 'Please focus on the editor to determine the insertion position',
    template: 'Template',
    saveAsTemplate: 'Save as template',
    userNum: 'x used',
    evaluate: 'Evaluate',
    myCreations: 'My Templates',
    myCollections: 'Favorites',
    myPurchase: 'Purchased',
    teamTemplate: 'Team Templates',
    PROJECT_MANAGEMENT: 'Project Management',
    R_D_DESIGN: 'R&D Management',
    PRODUCT_TEST: 'Product Evaluation',
    BUSINESS_ANALYSE: 'Business Analysis',
    FINANCIAL_MANAGEMENT: 'Financial Management',
    HUMAN_RESOURCES: 'Human Resources',
    OTHERS: 'Others',
    more: 'More',
    p1: 'There are no reviews yet, please add ',
    p2: 'The user did not write a review and has just left a rating.',
    p3: 'Please add a detailed review.',
    p4: 'The rating cannot be empty.',
    degree1: 'Very Poor',
    degree2: 'Poor',
    degree3: 'Fair',
    degree4: 'Good',
    degree5: 'Excellent',
    vrate: 'Rating',
    deleteTemplateInfo: 'Are you sure you want to delete this template?'
  },
  mindmap: {
    search: 'Please enter keywords',
    ChangeImpactAnalysis: 'Change impact analysis',
    ChangeImpactScope: 'Change impact scope',
    EditAgain: 'Edit again',
    PleaseEnterChangeImpactAnalysis: 'Please enter change impact analysis',
    PleaseSelectReviewers: 'Please select reviewers',
    CurrentNodeDeleted: 'The current node has been deleted',
    SpecifySprint: 'Sprint',
    SpecifyGanttChart: 'Gantt chart',
    NodeRemovedFromGantt: 'Node successfully removed from the gantt chart',
    MoveNodeToGanttSuccess: 'Successfully move node to gantt chart',
    AddNodeToGanttSuccess: 'Successfully add node to gantt chart',
    NodeExistInGantt: 'already exists in the gantt chart and cannot be added again',
    ConfirmMoveNode1: 'Are you sure you want to move this node to ',
    ConfirmMoveNode2: '? ',
    ConfirmMoveNode3: 'After moving, the current file will no longer contain this node and its subnodes. If the status of this node does not exist in the target file, it will automatically be converted to the initial state of the corresponding level.',
    JoinKanban: 'Join Kanban',
    RemovefromKanban: 'Shift out Kanban',
  },
  pubKnowledge: {
    RaiseQuestion: 'Raise a question',
    SubmitQuestion: 'Submit question',
    QuestionDescription: 'Question description',
    PhoneNumber: 'Phone number',
    Email: 'Email',
    RequireResponseContact: 'If you require a response, please provide contact information',
    Submit: 'Submit',
    FillQuestionDescription: 'Please fill in the question description',
    QuestionSubmittedSuccessfully: 'Question submitted successfully',
    registerTip: 'Register with MappingSpace to unlock more functions.',
    Unsubscribe: 'Cancel notification',
    subscribe: 'Change Notification',
    subscribeSuccessful: 'Subscribe Successful',
    UnsubscribeSuccessful: 'Unsubscribe Successful',
    checkEmail: 'Please enter the correct email address.',
    inputEamil: 'Please enter your email address.'
  },
  quick: {
    FilterWeekends: 'Filter weekends',
    CancelFilter: 'Cancel filter',
    Total: 'Total',
    AddedSuccessfully: 'Added successfully',
    BurnDownChart: 'Burn-down chart',
    IdealBurnDownChart: 'Ideal burn-down chart',
    IncompleteNodesCurrentVersion: 'Save as incomplete nodes in the current version. Are you sure you want to publish?',
    ConfirmOperation: 'Confirm operation',
    SprintDataSummary: 'Sprint data summary',
    nodeStatus: 'Node status',
    Joinsuccessfully: 'Join successfully',
    Removalsuccessful: 'Removal successful'
  },
  release: {
    IncompleteNodesCurrentVersion: 'There are unfinished tasks in the current version.  Are you sure you want to release it?',
    ConfirmOperation: 'Confirm operation',
  },
  corax: {
    StaticCodeScanPieChart: 'Static code scan pie chart',
    LinesOfCode: 'Lines of code',
    NumberOfFiles: 'Number of files',
    ScanningCriteria: 'Scanning criteria',
    ScanningTime: 'Scanning time',
    PlatformLinks: 'Platform links',
    DefectDetails: 'Defect details',
    GlobalNumber: 'Global number',
    BugNumber: 'Bug number',
    DefectName: 'Defect name',
    DefectDescription: 'Defect description',
    DefectLocation: 'Defect location',
    Severity: 'Severity',
    StaticCodeScanReport: 'Static code scan report (Corax)',
    coraxConfiguration: 'Corax configuration',
    ViewCoraxReport: 'View corax report',
    CoraxReports: 'Corax reports',
    ScanningTasks: 'Scanning tasks',
    DefectDistribution: 'Defect distribution',
    High: 'High',
    Medium: 'Medium',
    Low: 'Low',
    Note: 'Note',
    LastUpdated: 'Last updated',
    ReportingChart: 'Reporting chart',
    confirmDeletion: 'Deletion of a test plan is not recoverable but does not affect bugs found. confirm deletion?',
    introducedBySprint: 'This node is in a version introduced by sprint and cannot be deleted, please go to sprint to remove the node.'
  },
  // 富文本编辑器
  editor: {
    paragraph: "en_US"
  },
  DFMEA: {
    ReceivedTasks: 'Received tasks',
    MyAssignedTasks: 'Created tasks',
    OptimizationSteps: 'Optimization steps',
    ImprovedPrevention: 'Improved prevention',
    CompletionDate: 'Completion date',
    Priority: 'Priority',
    Status: 'Status',
    Assignee: 'Assignee',
    Initiator: 'Initiator',
    More: 'More',
    Reminder: 'Send reminder again',
    Completed: 'Completed',
    NotYetDecided: 'Not yet decided',
    NotYetExecuted: 'Not yet executed',
    NotYetDetermined: 'Not yet determined',
    NoExecute: 'No Execute',
    AssociatedTasks: 'Associated tasks',
    ms1: 'Purchase tips',
    ms2: 'DFMEA is a paid feature, please contact customer service to activate.',
    ms3: 'Send reminder again successfully',
    ms4: "Prohibit moving nodes in DFMEA view"
  },
  // 模板库
  templateLibrary: {
    SuccessfullySaved: 'Successfully saved as template',
    tips: 'Tips',
    warning1: 'The current template cannot be restored after deletion. Are you sure you want to delete it?',
    templateLibrary: 'Template library',
    searchTemplate: 'Search template',
    creator: 'Creator',
    used: ' people have used',
    preview: 'Preview',
    use: 'Use',
    myCreated: 'My created',
    myfavorites: 'My favorites',
    customTemplate: 'Team template',
    projectManagement: 'Project mgt',
    designing: 'R&D',
    resource: 'Resource Mgt',
    supply: 'Supply Chain Mgt',
    communication: 'Communication',
    sales: 'Sales Mgt',
    other: 'Others',
    previous: 'Previous',
    next: 'Next',
    useThisTemplate: 'Use this template',
    details: 'Details',
    comments: 'Comments',
    nocomments: 'There is no comment for now, please add',
    description: 'Description',
    addcomment: 'Please add a detailed comments',
    score: 'Please enter a score',
    comments1: ' comments',
    vrate: 'Rating',
    grade: 'grade',
    p1: 'No description for the time being',
    allTags: 'All tags',
    tagName: 'Tag name',
    addTags: 'Add tag',
    tag: 'Tag',
    Visibility: 'Visibility',
    PleaseEnterTemplateDescription: 'Please enter template description',
    PleaseEnterTheTagName: 'Please enter the tag name',
    tagTip1: 'Please go to Template Library=>Team Template, manage Tags'
  },
  wordTemplate: 'https://automind-oss.ytdevops.com/static/word-import-template.docx',
  excelTemplate: 'https://automind-oss.ytdevops.com/static/excel-import-template.xlsx',
  ganttExcelTemplate: 'https://automind-oss.ytdevops.com/static/import-gantt-template.xlsx',
  importUserTemplate: 'https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/import-user-template%20%281%29.xlsx',
  UserGuide: 'https://ms.ytdevops.com/pubKnowledge/c53738e5-f7bb-4b39-a194-843f86659a96',
  openApiGuide: 'https://ms.ytdevops.com/pubKnowledge/07c9feb6-1d86-4d4d-a1d3-dbace221ac1e',
  countryPhoneList: [
    {
      label: "+86   China",
      value: "China"
    },
    {
      label: "+886   Taiwan",
      value: "Taiwan"
    },
    {
      label: "+670   Democratic Republic of Timorleste",
      value: "Democratic Republic of Timorleste"
    },
    {
      label: "+236   Central African Republic",
      value: "Central African Republic"
    },
    {
      label: "+45   Denmark",
      value: "Denmark"
    },
    {
      label: "+380   Ukraine",
      value: "Ukraine"
    },
    {
      label: "+998   Uzbekistan",
      value: "Uzbekistan"
    },
    {
      label: "+256   Uganda",
      value: "Uganda"
    },
    {
      label: "+598   Uruguay",
      value: "Uruguay"
    },
    {
      label: "+235   Chad",
      value: "Chad"
    },
    {
      label: "+967   Yemen",
      value: "Yemen"
    },
    {
      label: "+374   Armenia",
      value: "Armenia"
    },
    {
      label: "+972   Israel",
      value: "Israel"
    },
    {
      label: "+964   Iraq",
      value: "Iraq"
    },
    {
      label: "+98   Iran",
      value: "Iran"
    },
    {
      label: "+501   Belize",
      value: "Belize"
    },
    {
      label: "+238   Cape Verde",
      value: "Cape Verde"
    },
    {
      label: "+7   Russia",
      value: "Russia"
    },
    {
      label: "+359   Bulgaria",
      value: "Bulgaria"
    },
    {
      label: "+385   Croatia",
      value: "Croatia"
    },
    {
      label: "+1671   Guam",
      value: "Guam"
    },
    {
      label: "+220   The Gambia",
      value: "The Gambia"
    },
    {
      label: "+354   Iceland",
      value: "Iceland"
    },
    {
      label: "+224   Guinea",
      value: "Guinea"
    },
    {
      label: "+245   Guinea-Bissau",
      value: "Guinea-Bissau"
    },
    {
      label: "+423   Liechtenstein",
      value: "Liechtenstein"
    },
    {
      label: "+242   The Republic of Congo",
      value: "The Republic of Congo"
    },
    {
      label: "+243   Democratic Republic of the Congo",
      value: "Democratic Republic of the Congo"
    },
    {
      label: "+218   Libya",
      value: "Libya"
    },
    {
      label: "+231   Liberia",
      value: "Liberia"
    },
    {
      label: "+1   Canada",
      value: "Canada"
    },
    {
      label: "+233   Ghana",
      value: "Ghana"
    },
    {
      label: "+241   Gabon",
      value: "Gabon"
    },
    {
      label: "+36   Hungary",
      value: "Hungary"
    },
    {
      label: "+27   South Africa",
      value: "South Africa"
    },
    {
      label: "+267   Botswana",
      value: "Botswana"
    },
    {
      label: "+974   Qatar",
      value: "Qatar"
    },
    {
      label: "+250   Rwanda",
      value: "Rwanda"
    },
    {
      label: "+352   Luxembourg",
      value: "Luxembourg"
    },
    {
      label: "+62   Indonesia",
      value: "Indonesia"
    },
    {
      label: "+91   India",
      value: "India"
    },
    {
      label: "+502   Guatemala",
      value: "Guatemala"
    },
    {
      label: "+593   Ecuador",
      value: "Ecuador"
    },
    {
      label: "+291   Eritrea",
      value: "Eritrea"
    },
    {
      label: "+963   Syria",
      value: "Syria"
    },
    {
      label: "+53   Cuba",
      value: "Cuba"
    },
    {
      label: "+996   Kyrgyzstan",
      value: "Kyrgyzstan"
    },
    {
      label: "+253   Djibouti",
      value: "Djibouti"
    },
    {
      label: "+57   Colombia",
      value: "Colombia"
    },
    {
      label: "+506   Costa Rica",
      value: "Costa Rica"
    },
    {
      label: "+237   Cameroon",
      value: "Cameroon"
    },
    {
      label: "+688   Tuvalu",
      value: "Tuvalu"
    },
    {
      label: "+993   Turkmenistan",
      value: "Turkmenistan"
    },
    {
      label: "+90   Turkey",
      value: "Turkey"
    },
    {
      label: "+1758   Saint Lucia",
      value: "Saint Lucia"
    },
    {
      label: "+1869   Saint Kitts and Nevis",
      value: "Saint Kitts and Nevis"
    },
    {
      label: "+239   Sao Tome and Principe",
      value: "Sao Tome and Principe"
    },
    {
      label: "+1784   Saint Vincent and the Grenadines",
      value: "Saint Vincent and the Grenadines"
    },
    {
      label: "+508   Saint Pierre and Miquelon",
      value: "Saint Pierre and Miquelon"
    },
    {
      label: "+290   Saint Helena",
      value: "Saint Helena"
    },
    {
      label: "+378   San Marino",
      value: "San Marino"
    },
    {
      label: "+592   Guyana",
      value: "Guyana"
    },
    {
      label: "+255   Tanzania",
      value: "Tanzania"
    },
    {
      label: "+20   Egypt",
      value: "Egypt"
    },
    {
      label: "+251   Ethiopia",
      value: "Ethiopia"
    },
    {
      label: "+686   Kiribati",
      value: "Kiribati"
    },
    {
      label: "+992   Tajikistan",
      value: "Tajikistan"
    },
    {
      label: "+221   Senegal",
      value: "Senegal"
    },
    {
      label: "+381   Serbia and Montenegro",
      value: "Serbia and Montenegro"
    },
    {
      label: "+232   Sierra Leone",
      value: "Sierra Leone"
    },
    {
      label: "+357   Cyprus",
      value: "Cyprus"
    },
    {
      label: "+248   Seychelles",
      value: "Seychelles"
    },
    {
      label: "+52   Mexico",
      value: "Mexico"
    },
    {
      label: "+228   Togo",
      value: "Togo"
    },
    {
      label: "+1767   Dominica",
      value: "Dominica"
    },
    {
      label: "+43   Austria",
      value: "Austria"
    },
    {
      label: "+58   Venezuela",
      value: "Venezuela"
    },
    {
      label: "+880   Bangladesh",
      value: "Bangladesh"
    },
    {
      label: "+244   Angola",
      value: "Angola"
    },
    {
      label: "+1264   Anguilla",
      value: "Anguilla"
    },
    {
      label: "+376   Andorra",
      value: "Andorra"
    },
    {
      label: "+691   Federated States of Micronesia",
      value: "Federated States of Micronesia"
    },
    {
      label: "+505   Nicaragua",
      value: "Nicaragua"
    },
    {
      label: "+234   Nigeria",
      value: "Nigeria"
    },
    {
      label: "+227   Niger",
      value: "Niger"
    },
    {
      label: "+977   Nepal",
      value: "Nepal"
    },
    {
      label: "+970   Palestine",
      value: "Palestine"
    },
    {
      label: "+1242   The Bahamas",
      value: "The Bahamas"
    }, {
      label: '+92   Pakistan',
      value: 'Pakistan'
    },
    {
      label: '+1246   Barbados',
      value: 'Barbados'
    },
    {
      label: '+675   Papua New Guinea',
      value: 'Papua New Guinea'
    },
    {
      label: '+595   Paraguay',
      value: 'Paraguay'
    },
    {
      label: '+507   Panama',
      value: 'Panama'
    },
    {
      label: '+973   Bahrain',
      value: 'Bahrain'
    },
    {
      label: '+55   Brazil',
      value: 'Brazil'
    },
    {
      label: '+226   Burkina Faso',
      value: 'Burkina Faso'
    },
    {
      label: '+257   Burundi',
      value: 'Burundi'
    },
    {
      label: '+30   Greece',
      value: 'Greece'
    },
    {
      label: '+680   Palau',
      value: 'Palau'
    },
    {
      label: '+682   Cook Islands',
      value: 'Cook Islands'
    },
    {
      label: '+1345   Cayman Islands',
      value: 'Cayman Islands'
    },
    {
      label: '+49   Germany',
      value: 'Germany'
    },
    {
      label: '+39   Italy',
      value: 'Italy'
    },
    {
      label: '+677   Solomon Islands',
      value: 'Solomon Islands'
    },
    {
      label: '+690   Tokelau',
      value: 'Tokelau'
    },
    {
      label: '+371   Latvia',
      value: 'Latvia'
    },
    {
      label: '+47   Norway',
      value: 'Norway'
    },
    {
      label: '+420   Czech Republic',
      value: 'Czech Republic'
    },
    {
      label: '+373   Moldova',
      value: 'Moldova'
    },
    {
      label: '+212   Morocco',
      value: 'Morocco'
    },
    {
      label: '+377   Monaco',
      value: 'Monaco'
    },
    {
      label: '+673   Brunei Darussalam',
      value: 'Brunei Darussalam'
    },
    {
      label: '+679   Fiji',
      value: 'Fiji'
    },
    {
      label: '+268   The Kingdom of Swaziland',
      value: 'The Kingdom of Swaziland'
    },
    {
      label: '+421   Slovakia',
      value: 'Slovakia'
    },
    {
      label: '+386   Slovenia',
      value: 'Slovenia'
    },
    {
      label: '+94   Sri Lanka',
      value: 'Sri Lanka'
    },
    {
      label: '+65   Singapore',
      value: 'Singapore'
    },
    {
      label: '+687   New Caledonia',
      value: 'New Caledonia'
    },
    {
      label: '+64   New Zealand',
      value: 'New Zealand'
    },
    {
      label: '+81   Japan',
      value: 'Japan'
    },
    {
      label: '+56   Chile',
      value: 'Chile'
    },
    {
      label: '+850   Korea, North',
      value: 'Korea, North'
    },
    {
      label: '+855   Cambodia',
      value: 'Cambodia'
    },
    {
      label: '+1473   Grenada',
      value: 'Grenada'
    },
    {
      label: '+299   Greenland',
      value: 'Greenland'
    },
    {
      label: '+995   Georgia',
      value: 'Georgia'
    },
    {
      label: '+32   Belgium',
      value: 'Belgium'
    },
    {
      label: '+222   Mauritania',
      value: 'Mauritania'
    },
    {
      label: '+230   Mauritius',
      value: 'Mauritius'
    },
    {
      label: '+676   Tonga',
      value: 'Tonga'
    },
    {
      label: '+966   Saudi Arabia',
      value: 'Saudi Arabia'
    },
    {
      label: '+33   France',
      value: 'France'
    },
    {
      label: '+594   French Guiana',
      value: 'French Guiana'
    },
    {
      label: '+689   French Polynesia',
      value: 'French Polynesia'
    },
    {
      label: '+596   french west indies',
      value: 'french west indies'
    },
    {
      label: '+298   Faroe Islands',
      value: 'Faroe Islands'
    },
    {
      label: '+48   Poland',
      value: 'Poland'
    },
    {
      label: '+1787   The Commonwealth of Puerto Rico',
      value: 'The Commonwealth of Puerto Rico'
    },
    {
      label: '+387   Bosnia and Herzegovina',
      value: 'Bosnia and Herzegovina'
    },
    {
      label: '+66   Thailand',
      value: 'Thailand'
    },
    {
      label: '+263   Zimbabwe',
      value: 'Zimbabwe'
    },
    {
      label: '+504   Honduras',
      value: 'Honduras'
    },
    {
      label: '+509   Haiti',
      value: 'Haiti'
    },
    {
      label: '+61   Australia',
      value: 'Australia'
    },
    {
      label: '+853   Macao',
      value: 'Macao'
    },
    {
      label: '+353   Ireland',
      value: 'Ireland'
    },
    {
      label: '+372   Estonia',
      value: 'Estonia'
    },
    {
      label: '+1876   Jamaica',
      value: 'Jamaica'
    },
    {
      label: '+1649   Turks and Caicos Islands',
      value: 'Turks and Caicos Islands'
    },
    {
      label: '+1868   Trinidad and Tobago',
      value: 'Trinidad and Tobago'
    },
    {
      label: '+591   Bolivia',
      value: 'Bolivia'
    },
    {
      label: '+674   Nauru',
      value: 'Nauru'
    },
    {
      label: '+46   Sweden',
      value: 'Sweden'
    },
    {
      label: '+41   Switzerland',
      value: 'Switzerland'
    },
    {
      label: '+590   Guadeloupe',
      value: 'Guadeloupe'
    },
    {
      label: '+681   Wallis et Futuna',
      value: 'Wallis et Futuna'
    },
    {
      label: '+678   Vanuatu',
      value: 'Vanuatu'
    },
    {
      label: '+262   Reunion',
      value: 'Reunion'
    },
    {
      label: '+375   Belarus',
      value: 'Belarus'
    },
    {
      label: '+1441   Bermuda',
      value: 'Bermuda'
    },
    {
      label: '+350   Gibraltar',
      value: 'Gibraltar'
    },
    {
      label: '+500   Falkland',
      value: 'Falkland'
    },
    {
      label: '+965   Kuwait',
      value: 'Kuwait'
    },
    {
      label: '+269   Comoros',
      value: 'Comoros'
    },
    {
      label: '+225   Cote d’Ivoire',
      value: 'Cote d’Ivoire'
    },
    {
      label: '+51   Peru',
      value: 'Peru'
    },
    {
      label: '+216   Tunisia',
      value: 'Tunisia'
    },
    {
      label: '+370   Lithuania',
      value: 'Lithuania'
    },
    {
      label: '+252   Somalia',
      value: 'Somalia'
    },
    {
      label: '+962   Jordan',
      value: 'Jordan'
    },
    {
      label: '+264   Namibia',
      value: 'Namibia'
    },
    {
      label: '+683   Island of Niue',
      value: 'Island of Niue'
    },
    {
      label: '+95   Burma',
      value: 'Burma'
    },
    {
      label: '+40   Romania',
      value: 'Romania'
    },
    {
      label: '+1   United States of America',
      value: 'United States of America'
    },
    {
      label: '+1340   Virgin Islands',
      value: 'Virgin Islands'
    },
    {
      label: '+1684   American Samoa',
      value: 'American Samoa'
    },
    {
      label: '+856   Laos',
      value: 'Laos'
    },
    {
      label: '+254   Kenya',
      value: 'Kenya'
    },
    {
      label: '+358   Finland',
      value: 'Finland'
    },
    {
      label: '+249   Sudan',
      value: 'Sudan'
    },
    {
      label: '+597   Suriname',
      value: 'Suriname'
    },
    {
      label: '+44   United Kingdom',
      value: 'United Kingdom'
    },
    {
      label: '+1284   British Virgin Islands',
      value: 'British Virgin Islands'
    },
    {
      label: '+31   Netherlands',
      value: 'Netherlands'
    },
    {
      label: '+599   Netherlands Antilles',
      value: 'Netherlands Antilles'
    },
    {
      label: '+258   Mozambique',
      value: 'Mozambique'
    },
    {
      label: '+266   Lesotho',
      value: 'Lesotho'
    },
    {
      label: '+63   Philippines',
      value: 'Philippines'
    },
    {
      label: '+503   El Salvador',
      value: 'El Salvador'
    },
    {
      label: '+685   Samoa',
      value: 'Samoa'
    },
    {
      label: '+351   Portugal',
      value: 'Portugal'
    },
    {
      label: '+976   Mongolia',
      value: 'Mongolia'
    },
    {
      label: '+34   Spain',
      value: 'Spain'
    },
    {
      label: '+229   Benin',
      value: 'Benin'
    },
    {
      label: '+260   Zambia',
      value: 'Zambia'
    },
    {
      label: '+240   Equatorial Guinea',
      value: 'Equatorial Guinea'
    },
    {
      label: '+84   Vietnam',
      value: 'Vietnam'
    },
    {
      label: '+994   Azerbaijan',
      value: 'Azerbaijan'
    },
    {
      label: '+93   Afghanistan',
      value: 'Afghanistan'
    },
    {
      label: '+213   Algeria',
      value: 'Algeria'
    },
    {
      label: '+355   Albania',
      value: 'Albania'
    },
    {
      label: '+971   United Arab Emirates',
      value: 'United Arab Emirates'
    },
    {
      label: '+968   Oman',
      value: 'Oman'
    },
    {
      label: '+54   Argentina',
      value: 'Argentina'
    },
    {
      label: '+297   Aruba',
      value: 'Aruba'
    },
    {
      label: '+82 Korea, South',
      value: 'Korea, South'
    },
    {
      label: '+852 Hong Kong (SAR)',
      value: 'Hong Kong (SAR)'
    },
    {
      label: '+389 Macedonia',
      value: 'Macedonia'
    },
    {
      label: '+960 Maldives',
      value: 'Maldives'
    },
    {
      label: '+265 Malawi',
      value: 'Malawi'
    },
    {
      label: '+60 Malaysia',
      value: 'Malaysia'
    },
    {
      label: '+692 Marshall Islands',
      value: 'Marshall Islands'
    },
    {
      label: '+356 Malta',
      value: 'Malta'
    },
    {
      label: '+261 Madagascar',
      value: 'Madagascar'
    },
    {
      label: '+223 Mali',
      value: 'Mali'
    },
    {
      label: '+961 Lebanon',
      value: 'Lebanon'
    },
    {
      label: '+382 The Republic of Montenegro',
      value: 'The Republic of Montenegro'
    }
  ],
  countryPhoneDefault: '+86'
}