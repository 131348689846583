import config from "@/../config/index";
export default {
  tip: "无权限，请联系管理员",
  loading: {
    loading1: "拼命加载中",
    loading2: "加载中...",
    loading3: "数据正在加载中...",
    loading7: "正在上传中...",
    loading4: "配置图表中请勿刷新...",
    loading5: "上传中",
    loading5: "耗时较长请耐心等待",
  },
  placeholder: {
    dropdown: "请选择",
    input: "请输入内容",
    date: "选择日期",
    time: "请选择时间",
    number: "请输入数字",
    name: "请输入名称",
    table1: "暂无数据",
    table2: "无数据",
    search: "搜索",
    notNull: "此项不能为空",
    notLength: "长度不要超过10个字符",
    onlyAlge: "只包含字母与下划线，且首尾只能为字母",
    onlyAlgeNum: "只包含字母、数字与下划线，且首尾只能为字母或数字",
    null: "无",
    open: "Open API",
    index: "您没有当前页面的查看权限或当前页面不存在",
  },
  btn: {
    cancelBtn: "取消",
    confirmBtn: "确定",
    addBtn: "添加",
    newBtn: "新建",
    movebtn: "移动",
    importBtn: "导入",
    exportBtn: "导出",
    pasteBtn: "复制",
    copyBtn: "复制",
    editBtn: "编辑",
    exitEditBtn: "退出编辑",
    sendBtn: "发送",
    saveBtn: "保存",
    uploadBtn: "上传",
    uploadFile: '上传文件',
    deleteBtn: "删除",
    ResetPassword: "重置密码",
    forceDeleteBtn: "永久删除",
    revertBtn: "恢复",
    optionBtn: "操作",
    batchBtn: "批量操作",
    exitBtn: "退出",
    modify: "修改",
    executeBtn: "执行",
    exitExecuteBtn: "退出执行模式",
    returnExeBtn: "返回执行",
    startBtn: "开始",
    endBtn: "结束",
    completeBtn: "完成",
    closeBtn: "关闭",
    jumpBtn: "跳过",
    replyBtn: "回复",
    next: "下一步",
    prev: "上一步",
    noLongerDisplayed: "不再显示",
    Customize: "自定义",
    view: "查看",
    insert: "插入",
    export_tem: '导出模板',
    tem: '定制导出模板'
  },
  model_view: {
    de3f: '默认模板',
    tip: '从系统中导出文件时，可选择此处预置的模板。您也可以上传自定义模板',
    tip2: "仅支持word和excel文件",
    tip3: '重名文件上传失败',
    tip4: '请选择文件类型和状态',
    font_size: "字体大小",
    font_decoration: "字体装饰",
    font_weight: "字体粗细",
    text_align: "文字对齐",
    start: "左",
    center: "中",
    end: "右",
    up: "上",
    down: "下",
    justify_content: "水平位置",
    align_items: "纵向位置",
    font_color: "字体颜色",
    font_style: "斜体",
    line_height: "行距",
    font_indent: "缩进",
    background_color: "背景颜色",
    border_color: "边框颜色",
    border_weight: "边框粗细",
    border_type: "边框样式",
    border_radius: "圆角",
    shadow_color: "阴影颜色",
    shadow_x: "阴影X偏移量",
    shadow_y: "阴影Y偏移量",
    shadow_blur: "阴影模糊",
    shadow_size: "阴影大小",
    to_top: "移至最前",
    to_bottom: "移至最后",
    up_Zindex: "上移一层",
    down_Zindex: "下移一层",
    stroke: "连线颜色",
    italicoo: '开启',
    italicno: '关闭',
    strokeWidth: "连线粗细",
    targetMarker: "箭头样式",
    strokeDasharray: "线条样式",
    connector: "连接器样式",
    add_link_visiable: "关联节点",
    remove_link_visiable: "取消关联",
    is_show_model_dialog: "添加模板",
    remove_link: "确定解除关联吗？",
    t1: "文字设置",
    t2: "背景设置",
    t3: "位置设置",
    t4: "连线设置",
    t5: "表格设置",
    base: "基础",
    base1: "圆角矩形",
    base2: "菱形",
    base3: "圆形",
    base4: "椭圆形",
    base5: "正方形",
    base6: "文字",
    base7: "箭头",
    base8: "梯形",
    base9: "三角形",
    base10: "",
    base11: "曲线",
    base12: "自由绘图",
    base13: "表格",
    base14: "人",
    cata1: '演示汇报',
    cata2: '协作分析',
    cata3: '研发制图',
    cata4: '其他',
    cata5: '',
    high1: "导出",
    high2: "流程图",
    high3: "时间点",
    high4: "时间线",
    high5: "泳道图",
    high6: "四象限",
    high7: "产品架构图",
    high8: "思维导图",
    high9: "web浏览器",
    high10: "iPhone",
    high11: "组织架构图",
    high12: "旅游线路图",
    high13: "",
    high14: "",
    high15: "",
    font_decoration1: {
      none: "无",
      overline: "上划线",
      line: "删除线",
      underline: "下划线",
    },
    border_type1: {
      none: "无",
      dotted: "点",
      dashed: "虚线",
      solid: "直线",
      double: "双直线",
      groove: "沟槽",
      ridge: "脊",
      inset: "内",
      outset: "外",
    },
    operate_dialog_name: {
      node: "节点设置",
      edge: "连线设置",
      table: "表格设置",
    },
    targetMarker1: {
      null: "无",
      block: "实心箭头",
      classic: "经典箭头",
      diamond: "菱形箭头",
      cross: "交叉箭头",
      circle: "圆形箭头",
      circlePlus: "圆形和加号箭头",
      ellipse: "椭圆",
    },
    strokeLinejoin: {
      normal: "默认路由",
      orth: "正交路由",
      oneSide: "三段路由",
      manhattan: "曼哈顿路由",
      metro: "地铁路由",
      er: "Z 字形",
    },
    strokeDasharray1: {
      n1: "实线",
      n2: "虚线",
    },
    connector1: {
      n1: '普通',
      n2: '圆角',
      n3: '跳跃',
    },
  },
  menu: {
    // 左菜单
    file: "文件",
    node: "节点",
    release: "发布",
    baseline: "基线",
    version: "版本",
    test: "测试",
    board: "敏捷看板",
    chart: "数据报表",
    bin: "回收站",
    setting: "设置",
    projectDetails: "关于项目",
    user: "用户",
    permission: "权限",
    settingFile: "文件",
    fileType: "文件类型",
    fieldLab: "字段库",
    changeRequest: "变更请求",
    unfold: "展开",
    reviewSettings: "评审设置",
    permissionSettings: "权限设置",
    workbench: "工作台",
    Notify: "消息通知",
    userManagement: "用户管理",
    projectManagement: "项目管理",
    thirdParty: "第三方应用管理",
    certificateManagement: "软件证书管理",
    wordview: "Word视图",
    FunctionalSafety: "安全",
    DFMEA: "DFMEA",
    asyncTask: "任务列表",
    question: "用户指南",
    shortcuts: "快捷方式",
    addShortcut: "添加快捷方式",
    link: "链接",
    name: "名称",
  },
  hara: {
    lang: "zh",
    tip: "故障数据库",
    tip1: "项目管理员可自定义更多故障数据，以更符合产品及行业要求。",
    label1: "功能",
    label2: "故障",
    label3: "风险",
    label4: "安全目标",
    export: "HARA分析",
    notice: "HARA：你收到一个HARA安全目标：",
    ms1: "购买提示",
    ms2: "提示：HARA为付费功能，请联系客服开通。",
    p1: "请输入故障",
    p2: "请输入风险",
    p3: "请输入安全目标",
    p4: "请输入数字",
    p5: "请输入安全状态",
    t1: "风险分析",
    t2: "严重度",
    t3: "暴露度",
    t4: "可控性",
    t5: "功能安全等级",
    t6: "安全状态",
    t7: "安全等级",
    ReceivedTasks: "我收到的安全目标",
    MyAssignedTasks: "我发出的安全目标",
    OptimizationSteps: "全部安全目标",
  },
  tara: {
    tara_lang: "ch",
    set: "设置",
    edit: "编辑",
    tip1: "项目管理员可自定义更多攻击路径，以更符合产品及行业要求。",
    tip2: "项目管理员可增加更多风险处置措施，以更符合产品及行业要求。",
    tip: "在预览模式下，点击下方表头，可快速定位到对应位置",
    notice: "TARA：你收到一个TARA风险处置指派提醒，TARA资产：",
    ms1: "购买提示",
    ms2: "提示：TARA为付费功能，请联系客服开通。",
    MyAssignedTasks: "我发出的任务",
    OptimizationSteps: "全部风险措施",
    export: "TARA分析",
    null: "空",
    t1: "安全资产",
    t2: "风险处置策略",
    t3: "接受风险",
    t4: "降低风险",
    t5: "规避风险",
    t6: "转移风险",
    t7: "状态",
    t8: "未开始",
    t9: "进行中",
    t10: "已完成",
    t11: "发起人",
    t12: "关联任务",
    t13: "责任人",
    t14: "资产",
    t15: "整体预览",
    t16: "隐藏",
    t17: "删除节点",
    t18: "确定删除吗？ 若该节点存在子节点将会被一并删除",
    t19: "信息安全属性",
    t20: "机密性",
    t21: "完整性",
    t22: "可用性",
    t23: "影响类别",
    t24: "危害场景",
    t25: "请输入危害场景",
    t26: "人身安全",
    t27: "请选择",
    t28: "财产",
    t29: "功能",
    t30: "隐私",
    t31: "影响等级",
    t32: "威胁场景",
    t33: "请输入威胁场景",
    t34: "选择预设文本追加到输入内容后面",
    t35: "攻击可行性评估",
    t36: "攻击路径",
    t37: "请输入攻击路径",
    t38: "耗时",
    t39: "专业知识",
    t40: "项目或组件知识",
    t41: "机会窗口",
    t42: "设备耗材",
    t43: "攻击可行性等级",
    t44: "风险数值",
    t45: "风险处置措施",
    t46: "请输入风险处置措施",
    t47: "网络安全目标",
    t48: "网络安全需求",
    t49: "网络安全声明",
    t50: "责任人",
    t51: "关联任务",
    t52: "请输入网络安全声明",
    t53: "请输入网络安全需求",
    t54: "请输入网络安全目标",
    t55: "信息安全资产",
    t56: "危害场景",
    t57: "威胁场景",
    t58: "攻击路径",
    t59: "风险数值",
    t60: "是否关闭资产",
    t61: "提示",
    t62: "确定",
    t63: "取消",
    t64: "节点编号",
  },
  fta: {
    title: "FTA",
    calculate: "FTA计算",
    mincut: "最小割集",
    minpath: "最小径集",
    structural: "结构重要度",
    topevent: "顶事件发生概率",
    warning: "每个门后至少有两个节点才能进行计算，请退出到思维导图模式修改",
    node: "节点",
    ms1: "购买提示",
    ms2: "提示：FTA为付费功能，请联系客服开通。",
    text1: "剩余",
    text2: " 个门未填写",
    text3: "重要度",
    text4: "事件名称",
    text5: "发生概率",
    text6: "请填写0-1之间的概率",
    text7: "重置",
    text8: " 个概率没有填写",
    text9: "计算",
    text10: "计算结果：顶事件发生概率是",
    text11: "概率不能为空",
    text12: "请输入0-1的数字值",
    type: "中文",
    or_door: "或门",
    and_door: "与门",
    null_door: "空",
  },
  share: {
    share_tip: "提示",
    share_down: "下载思维导图",
    share_word: "下载word",
    know_1: "了解我们",
    share_open:
      "下载思维导图操作需要在浏览器中进行，请点击右上角->在浏览器中打开。",
    sharetip:
      "该功能用于生成一个外部链接，其他人点击该链接，可以查看您的思维导图，但不能对其修改。您也可以将该链接贴到飞书文档中，在飞书中实时预览MappingSpace文件。",
    shareword: "网页端打开效果更好",
    btn: "一键分享",
    send: "发送成功",
    word: "切换到word视图",
    map: "切换到思维导图",
    word1: "word视图",
    map1: "思维导图",
    mulu: "目录",
    save: "保存至MappingSpace",
    s1: "此分享已被撤回，请直接联系分享人。",
    s2: "邮件通知分享人",
    des: "描述",
    nodata: "无数据",
    pic: "架构图",
    pro: "请选择项目",
    file: "请选择文件类型",
    d3: "此思维导图已过3天有效期，如需查看完整内容或进行二次创作，请将其保存到自有MappingSpace。",
    ti: "提示",
    tip: "如需查看完整内容或进行二次创作，请将其保存到自有MappingSpace。",
    notice: "您收到一个对外分享请求：",
    date: "分享日期",
    fname: "文件名",
    people: "分享人",
    nosha: "取消分享",
    summer: "思维导图分享数据汇总",
    phone_tip:
      "为了获得更好的查看体验，请访问云体科技或MappingSpace官网，登录电脑端查看。www.ms.ytdevops.com",
    know: "我知道了",
  },
  homeTopBar: {
    // 首页topBar
    newProjectForm: {
      // 新建项目表单
      title: "添加项目",
      name: "项目名称",
      key: "项目KEY",
      agileType: "敏捷类型",
      template: "项目模板",
      avatar: "项目头像",
      member: "项目成员",
      editBtn: "点击修改",
      agileDesc: "Scrum：敏捷开发的一种模式，包含Sprint(冲刺)的概念。",
      kanban: "Kanban：敏捷开发的一种模式，不包含Sprint(冲刺)的概念。",
      tempDesc: "选择模板后不能选择敏捷类型。",
      message: {
        nameError: "项目名已存在",
        idError: "项目ID已存在",
        keyError: "项目Key已存在",
        nonError: "当前项目不存在",
        avatarError: "上传头像图片大小不能超过 1MB!",
        uploadError: "上传失败",
        uploadError2: "上传出错，服务器开小差了呢",
        addSuccess: "添加成功",
        addError: "添加失败",
      },
    },
    newFileForm: {
      // 新建文件表单
      tab1: "新建文件",
      tab2: "批量导入",
      tab3: "新建关联文件",
      name: "请输入文件名称",
      type: "请选文件类型",
      plan: "请选测试计划",
      case: "请选测试用例",
      field1: "责任人",
      field2: "描述",
      newNode: "新建节点",
      excel: {
        title: "操作注意：",
        p1: "1.节点位置列和节点名称列为必填项,删除这两列或者值为空均会导入失败。",
        p2: "2.excel中的字段值，用户可自定义,在导入时，用户需指定与浮窗中字段的对应关系。",
        p3: "请下载导入模板:",
        p4: "导入模板.xlsx",
        p5: "excel导入注意：",
        p6: "3.导入excel文件大小不允许超过20M。",
        p7: "word导入注意：",
        p8: "1.导入文件格式必须为docx。",
        p9: "2.导入word文件大小不允许超过20M。",
        p10: "导入模板.docx",
        p11: "xmind导入注意:",
        p12: "1.导入文件格式必须为xmind。",
        p13: "freemind导入注意:",
        p14: "1.导入文件格式必须为mm。",
        excelField: "Excel字段",
        windowField: "浮窗字段",
        sysField: "系统字段",
        select: "请选择浮窗字段",
      },
      mgpt: {
        requirement: "需求描述",
        repositoryUrl: "仓库地址",
        repositoryType: "仓库类型",
        tip1: "若您指定一个代码仓库地址，我们将把生成的软件代码直接放入到代码仓库中, 请至分支feature/mgpt查看, 若您未指定代码仓库，则默认将代码放到项目知识库内",
        tip2: "个人access token， 一般在设置页面增加，具体可见各Git软件说明文档. 例：gitlab-点击头像 - Edit profile - Access Tokens - Add new token- api",
        mgptM1: "请输入需求描述",
        mgptM2: "请输入仓库类型",
        mgptM3: "请输入Token",
      },
      limit: "上传文件大小不能超过20M",
      message: "创建成功",
      templatePlace: "单击右侧按钮选择模板",
    },
    menu: {
      // 头像下拉菜单
      profile: "我的资料",
      blue: "主题蓝（默认）",
      dark: "深色",
      light: "浅色",
      logout: "退出登录",
    },
    asyncTask: {
      index: "序号",
      type: "任务类型",
      status: "任务状态",
      startTime: "发起时间",
      endTime: "结束时间",
      errorMessage: "错误信息",
      response: "返回信息",
      message: "任务进行中，请进入右上角任务列表查看进度。",
    },
    search: "请输入关键词",
    file: "文件",
    node: "节点",
    knowledge: "文章",
    language: "简体中文",
  },

  home: {
    // 我的工作台
    title: "我的工作台",
    myChangeRequest: "变更请求",
    myCollection: "我的收藏",
    myResponsibility: "指定我为责任人",
    myAt: "@我的",
    myFile: "我的文件",
    myNode: "我的节点",
    personalDataSummary: "个人数据汇总",
    projectDataSummary: "项目数据汇总",
    projectName: "项目名称",
    numberOfPeople: "人数",
    numberOfFiles: "文件数",
    numberOfNodes: "节点数",
    nodeResponsiblePerson: "节点-责任人",
    workHoursStatistics: "工时统计",
    plannedWorkHours: "计划工时",
    actualRegisteredWorkHours: "实际登记工时",
    nodeCompletionStatus: "责任人节点完成情况",
    sharingDataSummary: "思维导图分享数据汇总",
    sharer: "分享人",
    sharingTime: "分享时间",
    sharingPermission: "分享许可",
    status: {
      notStarted: "未开始",
      inProgress: "进行中",
      completed: "已完成",
    },
    total: "总计",
    update_1: "升级到新版本",
    update_2: "更多版本记录",
    update_3: "新版本更新内容如下：",
  },

  file: { // 文件
    top: { // 顶端按钮
      search: '搜索',
      type: '类型',
      creator: '创建人',
      status: '状态',
      clearBtn: '重置',
      createBtn: '创建筛选条件',
      anoSaveBtn: '另存为筛选条件',
      select: '筛选项',
      viewAll: '查看所有',
      saveBtn: '保存筛选条件',
      confirmForm: {
        name: "名称",
        placeholder: "请输入名称",
      },
    },
    dialog: {
      condition: "筛选条件",
      name: "条件名称",
      creator: "创建人",
      option: "操作",
      invite: "试试邀请你的小伙伴，一起来到MappingSpace！",
      tip1: "未指定的字段无法导入，确定要导入吗？",
      tip2: "注意",
    },
    table: {
      // 文件table
      type: "文件类型",
      name: "文件名",
      status: "状态",
      creator: "创建人",
      lastUpdated: "上次修改时间",
      options: {
        collaboration: "协作",
        delete: "删除",
        deleteForm: {
          title: "提示",
          desc: "确认要删除文件吗？删除后的文件，需管理员权限方可恢复",
          message: "文件已移至回收站",
        },
        paste: "复制",
        collectList: "收藏列表",
        message: "复制成功，正在刷新",
        collect: "收藏",
        nonCollect: "取消收藏",
        download: "下载",
        move: "移动",
        moveForm: {
          title: "文件移动",
          target: "目标项目",
          fieldSetting: "字段匹配",
          origin: "原项目",
          file: "原文件",
          save: "保留",
          tip1: "在目标项目中没有对应类型相同的字段",
          tip2: "在目标项目的值不全，移动后可能丢失数据",
          tip3: "目标项目字段未完全指定",
          tip4: "若文件内包含已开始基线内节点，则会导致删除失败。需基线结束后，再手动删除",
        },
        export: "导出",
        dialog: {
          title: "Excel导出选项",
          title2: "批量修改字段",
          title3: "Word导出选项",
          box1: "全选",
          box2: "节点位置",
          box3: "节点Key",
          box4: "节点名称",
          box5: "取 消",
          box6: "导 出",
          box7: "前置任务",
          box8: "计划开始时间",
          box9: "计划结束时间",
          box10: "责任人",
          box11: "状态",
          box12: "序号",
          box13: "计划工时",
          box14: "累计工时",
          box15: "进度",
        },
        message1: "操作列不可移动",
        message2: "正在导出中",
        addToBaseLine: "添加至基线",
        targetBaseLine: "目标基线",
      },
    },
  },

  expire: {
    // 软件过期
    expire_notice1: "距离软件到期仅剩",
    expire_notice2: "天时间，到期之后可能影响您的使用，请联系客服续订",
    buy: "请扫描客服微信二维码",
    tip1: "请扫描客服微信二维码进行购买",
    tip2: "更多详情请咨询：",
    tip3: "未获取",
    tip4: "微信联系客服，即刻购买",
  },

  canvas: {
    // 画布页
    tip: "Enter键添加同级节点，Tab键添加子节点，Delete键删除节点, Ctrl+C复制节点, Ctrl+X剪切节点, Ctrl+V粘贴节点, Ctrl+F快速搜索节点, 右键可以对多选的节点进行批量操作。",
    tip1: "放大",
    tip2: "缩小",
    tip3: "节点向上移动",
    tip4: "节点向下移动",
    tip5: "左对齐",
    tip6: "全屏",
    tip7: "添加子节点",
    tip8: "查看用户指南",
    tip9: "点击节点左侧选择框选择节点，Enter键添加同级节点，Tab键添加子节点，Delete键删除节点，Ctrl+F快速搜索节点，Ctrl+A选择所有节点或已选节点的所有子节点，右键可以对多选的节点进行批量操作。",
    tip10: "锁定的节点不能移动",
    tip11: "根节点不能移动",
    tip12: "请选中一个节点",
    tip13: "默认Word",
    tip14: "默认思维导图",
    tip15: "左侧是目录树，右侧是节点详情页，点击左侧目录树，右侧节点详情页自动切换",
    tip16: "选中右侧图形元素后右键，可关联节点。拖动线条时按住ALT键，可将直线变成折线，改变线条方向。",

    mode: {
      edit: "编辑模式",
      read: "查看模式",
      review: "评审模式",
    },
    viewResult: {
      pass: "通过",
      fail: "失败",
      reason: "未通过理由",
      message2: "保存失败",
      reviewing: "评审中",
      nopass: "未通过",
      noreviewing: "未评审",
      people: "人",
      res: "节点详细评审结果",
    },
    btn: {
      // 上方按钮
      file: "文件",
      view: "视图",
      floating: "浮窗",
      executeTestPlan: "执行测试计划",
      reviewRequest: "邀请评审",
      reviewResult: "查看评审结果",
      clear: "清空选中",
    },
    fileDropDown: {
      // 文件按钮下拉菜单
      paste: "复制",
      delete: "删除",
      export: "导出",
      creatTemplate: "另存为新模板",
      creatTemplatePlace: "请输入模板名称",
      creatTemplatePlace1: "请输入模板信息",
      templatename: "模板名称",
      templatedescript: "模板描述",
      descriptplace: "请输入模板描述",
      deleteForm: {
        title: "提示",
        desc: "确认要删除文件吗？删除后的文件，需管理员权限方可恢复",
      },
    },
    viewDropDown: {
      // 视图按钮下拉菜单
      showStatus: "状态",
      showStatus_shortcutKey: "Ctrl+Alt+S",
      detectRequired: "必填项检测",
      Reusesituation: "复用情况",
      detectRequired_shortcutKey: "Alt+M",
      coverage: "覆盖度",
      baseline: "基线",
      responsibility: "责任人",
      Sprint_shortcutKey: "Alt+P",
      responsibility_shortcutKey: "Alt+R",
      wordView: "Word视图",
      wordView_shortcutKey: "Alt+W",
      DFMEAView: "DFMEA",
      mindView: "思维导图",
      modelView: '模型视图',
      treeView: '树形视图',
    },
    excel: {
      setting: "设置自定义",
      title: "请选择您想导入的表格",
      mindmapName: "思维导图名称",
      note: "备注",
    },
    reviewRequestForm: {
      // 邀请评审表单
      title: "邀请评审",
      placeholder: "请选择要邀请的人员",
      note: "权限：评审",
      title2: "评审人员",
      num: "评审者",
      savingState: "所有更改已保存",
      savingState2: "正在保存..",
      tip1: "请输入不通过理由",
      tip2: "请选择邀请人",
      tip3: "邀请成功，其中",
      tip4: "个节点处于评审中或已通过的状态不可被邀请评审。",
      tip5: "邀请成功",
      tip6: "该文件类型未指定评审规则，请添加后再邀请评审。",
      tip7: "请选择通过/不通过",
      tip8: "评审条目通过率",
      no: "不通过",
      yes: "通过",
      all_yes: "全部选择了通过，确定继续吗？",
      all_no: "全部选择了不通过，确定继续吗？",
    },
    floatWindow: {
      // 悬浮窗
      creator: "创建人",
      workflowNone: "无数据",
      workflowView: "查看工作流",
      testcase: "测试用例",
      form: {
        // 表单
        responsibility: "责任人",
        deadline: "截止日期",
        priority: "优先级",
        placeholder: "请选择",
        placeholder2: "选择日期",
      },
    },
    rightClick: {
      // 右键菜单
      statusChange: "切换状态",
      statusChangeForm: {
        title: "状态切换",
        num: "第",
        layer: "层",
        original: "原状态",
        new: "切换至状态",
      },
      responsibility: "指定责任人",
      responsibilityForm: {
        title: "编辑字段",
        edit1: "修改",
        edit: "责任人",
      },
      baseline: "指定基线",
      targetVersion: "指定发布版本",
      node: "关联节点",
      addRelatedNode: "添加关联节点",
      others: "编辑其他字段",
      message: "锁定节点不能修改其他字段",
      othersChild: {
        priority: "优先级",
        deadline: "截止日期",
      },
      moveNode: "移动节点",
      moveNodeForm: {
        title: "移动节点",
        edit: "目标节点",
        placeholder: "请输入移动节点的id",
      },
      testPlan: "指定测试计划",
      dfmeaTable: "DFMEA表格",
    },
    dfmea: {
      step1: "规划和准备（步骤一）",
      companyName: "公司名称",
      project: "项目",
      location: "工程地点",
      openDate: "DFMEA开日日期",
      id: "DFMEA ID",
      customerName: "顾客名称",
      updateDate: "DFMEA修订日期",
      responsibility: "设计职责",
      yearProject: "年型/项目",
      group: "跨职能团队",
      secretLevel: "保密级别",
      step2: "结构分析（步骤二）",
      lastLevel: "上一较高级别",
      focusElement: "关注要素",
      nextLevel: "下一低级别特性或类型",
      details: "详情",
      step3: "功能分析（步骤三）",
      lastLevel3: "上一较高级别功能及要求",
      focusElement3: "关注要素功能及要求",
      nextLevel3: "下一较低级别功能及要求或特性",
      step4: "失效分析（步骤四）",
      fe: "对于上一较高级别要素和/或最终用户的失效影响（FE）",
      severity: "失效影响的严重度（S）",
      fm: "关注要素的失效模式（FM）",
      fc: "下一较低级别要素或特性的失效起因（FC）",
      step5: "风险分析（步骤五）和优化（步骤六）",
      pc5: "当前的对失效起因的预防措施（PC）",
      frequency5: "失效起因的频度（O）",
      dc5: "当前的失效起因/失效模式的探测措施（DC）",
      detection5: "失效起因/失效模式的探测度（D）",
      priority: "措施优先级（AP）",
      code: "筛选器代码（可选）",
      step6: "DFMEA优化（步骤六）",
      pc6: "优化后的预防措施（PC）",
      frequency6: "优化后的频度（O）",
      dc6: "优化后的探测措施（DC）",
      detection6: "优化后的探测度（D）",
      priority6: "优化后的措施优先级",
      assignee: "责任人",
      targetDate: "目标完成日期",
      status: "状态",
      method: " 采取基于证据的措施",
      associatedTasks: "关联任务",
      completeDate: "完成日期",
      note: "备注",
      step7: "结果文件化（步骤七）",
      validError:
        "步骤六中存在必填项未填写，直接离开无法保存该条优化措施。确认离开吗？",
      validError2: "步骤六中存在必填字段未填写，无法导出步骤六，确认导出吗?",
      apMessage: "请先填写严重度、频度、探测度。",
      emptyMessage: "当前节点不包含该字段，请联系项目管理员",
      editMessage: "修改当前值可能会影响文件内其他DFMEA表单，确认修改吗",
      structureTree: "结构树",
      index: "序号",
      pleaseEnterNumber: "请输入数字1-10",
      severitySettings: "严重度设置",
      severityIs: "严重度(S)为",
      cannotBeEmpty: "不能为空",
      extremelyHigh: "极高",
      extremelyLow: "极低",
      veryHigh: "非常高",
      veryLow: "非常低",
      sameMessage1: "当前表格中第",
      sameMessage2: "行与第",
      sameMessage3:
        "行的1-2-3-4-5-6-7-9-10列的数据一模一样，可能为重复填写，是否需要保留？",
      reserve: "保留",
      deleted: "删除成功",
      cancelDelete: "取消删除",
      cannotBeRestored: "删除后不可恢复, 确定删除吗？",
      associate1: "大于期望值",
      associate2: "小于期望值",
      associate3: "伴随其他多余事件",
      associate4: "方向相反",
      associate5: "部分不工作",
      associate6: "出现其他异常",
      associate7: "不工作",
      removeNodeMessage:
        "当前功能下存在关联缺陷，删除该功能后缺陷将同步删除，是否确认删除该功能？",
      functionDescription: "功能描述",
      associationMessage1: "当前结构有",
      associationMessage2: "个功能尚未关联上下级功能，有",
      associationMessage3: "个缺陷尚未关联上下级缺陷。",
      associationMessage4: "已更正",
      associationMessage5: "个严重度值,",
    },
    wordView: {
      // word视图
      unfoldBtn: "展开所有节点",
      foldBtn: "折叠所有节点",
      node: {
        desc: "描述",
        placeholder: "请添加描述",
      },
      need_has: "已覆盖",
      can_cover: "需覆盖",
      need_no: "未覆盖",
      no_need: "无需覆盖",
      null: "空",
      status: "状态",
      assignee: "责任人",
      sprintId: "Sprint",
      coverage: "覆盖度",
    },
    testPlan: {
      // 测试计划相关
      selectBtn: "请选择测试计划",
      warning: "下列测试计划未指定负责人，不可执行",
      info: "突出显示的节点在该测试计划中，请执行测试计划",
      lock: "节点被锁定",
      status: {
        notStarted: "未开始",
        inProgress: "进行中",
        completed: "已完成",
        uncompleted: "未完成",
        started: "已开始",
        ended: "已结束",
        notPublished: "未发布",
        published: "已发布",
      },
      executeStatus: {
        UN_EXECUTED: "未执行",
        APPROVED: "通过",
        BLOCKED: "阻塞",
        FAILED: "失败",
        NA: "NA",
      },
      floatWindow: {
        // 悬浮窗里的选项
        result: "执行结果",
        status: "执行状态",
        editError: "节点不在当前测试计划内，无法编辑",
        executeError: "节点不在当前测试计划内，无法执行",
      },
      testStepForm: {
        template: "测试模板",
        title: "测试步骤列表",
        editBtn: "编辑",
        forward: "前置条件",
        testStep: "测试步骤",
        antiResult: "期望结果",
      },
      message: {
        selectWarn: "请先选择测试计划",
        warn: "该节点所在版本由Sprint引入，无法删除，请前往Sprint中移除该节点",
        executionFailed: "执行失败",
        click: "点击",
        automaticallyCreat: "自动创建并关联bug",
      },
      deleteRelationBugMess: "确定删除关联bug？",
      selectRelationBugMess: "请选择关联bug",
      newBug: "新建Bug",
      notWork: "不工作",
    },
    import: "可导入",
    messages: {
      // 提示消息
      noForward: "不能前进啦",
      noBack: "已经退到底啦",
      deleteSuccess: "文件已移至回收站",
      delSuccess: "文件已回收",
      delSuccess1:
        "<p><strong>部分文件删除失败，请检查以下原因</strong></p></br><p>&nbsp1.文件中是否有节点在已开始的基线中</p></br><p>&nbsp2.对应文件类型无权限删除</p>",
      delError: "回收失败",
      delCancel: "已取消回收",
      deleteInfo: "已取消删除",
      renameSuccess: "修改成功",
      ano: "副本",
      importSuccess: "导入成功",
      importWarn1: "非导图文件",
      importWarn2: "存在空字段",
      importWarn3: "存在非法子节点",
      importWarn4: "存在未定义内容",
      importWarn5: "存在重复结点id",
      exportSuccess: "导出模板成功",
      requiredWarn1: "节点存在必填项未填写，请及时填写",
      requiredWarn2: "所有节点的必填项均已填写",
      coverageWarn1: "突出显示节点未关联",
      coverageWarn11: "请及时关联",
      coverageWarn2: "所有节点均已关联",
      emailError: "邮箱地址不能为空",
      emailError2: "请输入正确的邮箱地址",
      authorizationInfo: "请选择邀请权限",
      editSuccess: "修改成功",
      editWarning: "修改成功，部分节点在基线中被锁定，无法修改",
      error1: "暂时只支持同层级节点移动",
      success1: "移动成功",
      error2: "源文件的类型与目标文件不一致，无法执行移动操作",
      info1: "确定将",
      info2: "及其子节点，移动到当前文件吗？ 原文件将不再含有",
      info3: "及其子节点",
      error3: "多选状态下无法执行移动操作，请选择单个节点",
      success2: "改变Sprint成功",
      success3: "删除Sprint成功",
      success4: "添加基线成功",
      success5: "添加版本成功",
      success6: "添加测试计划成功",
      success7: "执行成功",
      error4: "执行失败，所选节点均不在当前选择的测试计划中。",
      info4: "有",
      info5: "个节点执行成功，",
      info6: "个节点不在所选的测试计划中。",
      warn1: "所选节点中包含锁定节点，请取消选择后再操作",
      moveWarn: "仅多选同一文件类型时，允许批量移动",
      moveSuccess: "移动成功",
      moveInfo: "移动失败",
      Copied: "已复制",
      CutNotAvailable: "多选状态下无法使用剪切功能",
      RootNodeNotSupportCutting: "根节点不支持剪切",
      Cut: "已剪切",
    },
    clone: {
      tip1: "多选状态下无法使用复制功能",
      tip2: "多选状态下无法使用粘贴功能",
      tip3: "多选状态下无法删除节点",
      tip4: "多选状态下无法添加节点",
    },
    AI: {
      tip1: "AI补充中...",
      tip2: "AI补充",
      tip3: "AI补充失败",
      tip4: "多选状态下无法进行AI补充",
      tip5: "请至少选中一个节点",
    },
    status: {
      st1: "未开始",
      st2: "进行中",
      st3: "已完成",
      st4: "已开始",
      st5: "已发布",
      st6: "未发布",
      st7: "未分配",
    },
    word: "试试Word视图, 描述更清晰！",
    p1: "共",
    p2: "条",
  },

  node: { // 节点
    top: { // 顶端按钮
      search: '搜索',
      type: '类型',
      creator: '创建人',
      status: '状态',
      responsibility: '责任人',
      moreBtn: '更多+',
      clearBtn: '重置',
      select: '筛选项',
      saveBtn: '保存筛选条件',
      confirmForm: {
        title: '请输入条件名称',
        name: '名称',
        notificationFrequency: '通知频率',
        notificationObject: '通知对象',
        every: '每',
        week: '周',
        weeks: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      },
      senior: " 高级搜索",
      simple: " 简单搜索",
    },
    batchField: {
      assignee: "责任人",
      baseline: "基线",
      version: "版本",
      testplan: "测试计划",
      sprint: "Sprint",
      storypoint: "故事点",
      msg: "请选择字段和字段值",
      alert: "批量修改当前字段，不会覆盖原值，而是新增选项值",
    },
    table: {
      // 节点table
      type: "文件类型",
      name: "节点名",
      file_name: "文件名",
      status: "状态",
      creator: "创建人",
      responsibility: "责任人",
      createdTime: "创建时间",
      updatedTime: "上次修改时间",
      fileName: "文件名",
    },
    excel: {
      tip1: "一次最多导出1000个节点",
      tip2: "请选择需要导出的节点",
      tip3: "正在导出中",
      tip4: "请选择需要修改的节点",
      tip5: "最多支持同时修改100个节点",
    },
    message: {
      message1: "不含该字段",
      message2: "未覆盖",
      message3: "已覆盖",
      message4: "切换到编辑模式",
      message5: "切换到评审模式",
      message6: "其他字段",
      message7: "可疑",
      message8: "一致",
      message9: "非复用节点",
      message10: "不能包含非复用节点",
      needCoverage: "需要覆盖",
      noNeedCoverage: "无需覆盖",
      operationSuccess: "操作成功",
      operationSuccess1: "置顶成功",
      operationSuccess2: "置底成功",
      operationSuccess3: "上移成功",
      operationSuccess4: "下移成功",
      operationSuccess5: "已经到极限啦",
      nodeCovered: "节点已经覆盖",
      nodeNotCovered: "节点已经无需覆盖",
    },
    date: {
      Mon: "一",
      Tue: "二",
      Wed: "三",
      Thur: "四",
      Fri: "五",
      Sat: "六",
      Sun: "日",
      January: "一月",
      February: "二月",
      March: "三月",
      April: "四月",
      May: "五月",
      June: "六月",
      July: "七月",
      August: "八月",
      September: "九月",
      October: "十月",
      November: "十一月",
      December: "十二月",
    },
    ai: {
      title: "AI绘图",
      placeholder1: "请输入内容",
      placeholder2: "请选择类型",
      aiDiagram1: "时序图",
      aiDiagram2: "流程图",
      aiDiagram3: "类图",
      aiDiagram4: "状态图",
      aiDiagram5: "实体关系图",
      aiDiagram6: "甘特图",
      aiDiagram7: "git图",
      aiDiagram8: "思维导图",
      alertTitle: "提示",
      alertMessage:
        "复制成功，点击上方Tab页 [插入] -> [高级] -> [Mermaid] 粘贴代码插入",
    },
    sourceNodeSync: {
      title: "同步原节点",
      target: "原节点",
      origin: "当前节点",
      tip1: "在原节点中没有对应类型相同的字段",
      tip2: "当前节点和原节点的值存在差异，移动后可能丢失数据",
      tip3: "原节点字段未完全指定",
      sureToSynchronize: "确定同步吗? 当前节点的字段数据会被原节点覆盖,",
      sureToSynchronize2: "该操作不可逆",
      success: "同步成功",
    },
    sendiorSearch: {
      errormessage: '含有非法字符, 请避免手动输入字符',
      addFilterConditions: '新增筛选条件'
    }
  },

  AI: {
    tip1: "AI创建",
    tip2: "复制全部",
    tip3: "创建文件",
    tip4: "请输入",
    tip5: "AI完善",
    tip6: "购买提示",
    tip7: "提示：AI机器人为付费功能，试用版每天可生成100条需求。完整版功能，请联系客服开通。",
    tip8: "创建需求",
    tip9: "创建测试用例",
    tip10: "生成代码",
    tip11: "完善需求",
    tip12: "完善测试用例",
    tip13: "完善代码",
    tip14: "复制成功",
    tip15: "请输入文件名",
    tip16: "请选择文件类型",
    tip17: "文件创建中, 请稍后在文件页刷新查看",
    tip18: "创建失败",
    tip19: "抱歉，你的浏览器不支持 server-sent 事件, 请更换浏览器",
    tip20: "请输入代码的语言类型",
    tip21: "输入框不能为空",
    tip22: "请问您要写关于什么的需求？",
    tip23:
      "请问您需要从哪些方面完善这份需求呢？例如，需要添加哪些具体功能，需要考虑哪些使用场景，需要满足哪些性能指标等等。",
    tip24: "请问您要写关于什么的测试用例？",
    tip25:
      "请问您需要从哪些方面完善这份测试用例呢？例如，需要测试哪些具体功能，需要测试哪些使用场景，需要测试哪些性能指标等等",
    tip26: "请问您需要完善什么需求？",
    tip27: "请问您需要完善什么测试用例？",
    tip28: "请问您需要完善什么代码？",
    tip29:
      "请问您需要从哪些方面完善这份代码呢？例如，需要添加哪些具体功能，需要考虑哪些使用场景，需要满足哪些性能指标等等。",
    tip30: "请问您的代码需求？",
    tip31: "请进一步完善这份需求",
    tip32: "我对这份需求很满意，请帮我在MappingSpace中创建一个文件",
    tip33: "请进一步完善这份测试用例",
    tip34: "我对这份测试用例很满意，请帮我在MappingSpace中创建一个文件",
    tip35: "请进一步完善这份代码",
  },

  nodeDetail: {
    // 节点详情页
    third: {
      title: "万能网页链接钥匙",
      add: "添加关联链接",
      input: "请输入关联链接",
      warn: "提示",
      addsucc: "添加成功",
      edsucc: "修改成功",
      delsucc: "删除成功",
      input_tab: "请输入名称",
      open: "在新标签页打开链接",
      del: "该操作会导致该板块下的页面全部被移除，确定要移除吗？",
      del_btn: "移除",
      or: "或",
    },
    basicInfo: {
      title: "基本信息",
      responsibility: "责任人",
      deadline: "截止日期",
      priority: "优先级",
      storyPoint: "故事点",
      structureDiagram: "架构图",
      api: "api文档",
      jira: "关联jira issue",
      ones: "关联ones工作项",
      warn: "api文档最多关联50个",
      warnjira: "jira issue最多关联50个",
      warnones: "ones工作项最多关联50个",
      add: "请输入apiDocUrl",
      addIssue: "请输入jira issue链接",
      addOnesIssue: "请输入ones工作项链接",
      errOnesIssue: "ones工作项链接不合法",
      api_status_map: {
        published: "已发布",
        hold: "维护",
        bin: "废弃",
        unsure: "待确定",
        develop: "开发",
        test: "测试",
        cor: "对接",
        ana: "异常",
        design: "设计中",
        complete: "完成",
      },
      ms1: "删除后不可恢复，请确认是否删除？",
      controllability: "可控性",
      exposure: "暴露度",
      severity: "严重度",
      safetyLevel: "功能安全等级",
      FunctionalRequirementsAndCharacteristics: "功能要求及特性",
      loseEfficacy: "失效",
    },
    publish: {
      title: "发布",
      baseline: "基线",
      testPlan: "测试计划",
      version: "版本",
      info1: "未开始的测试计划，可以添加或删除测试用例",
      info2: "进行中的测试计划，仅可添加测试用例",
    },
    testStep: {
      title: "测试步骤",
      no: "编号",
      forward: "前置条件",
      testStep: "测试步骤",
      antiResult: "预期结果",
      ViewTestStep: "查看测试步骤",
    },
    description: {
      title: "描述",
      placeholder: "请添加描述...",
      info1: "将文件拖到此处，或",
      info2: "点击上传",
    },
    relationChart: {
      title: "关联透视图",
      centerView: "中心视图",
      vmodelView: "V模型视图",
      wmodelView: "W模型视图",
      tip: "双击节点进入节点详情，右键单击节点取消关联",
      tip2: "单击节点，展开其关联节点；双击节点，进入节点详情",
      addRelatedNode: "添加关联节点",
      placeholder: "请输入节点Key或关键词",
      message: "添加成功，正在重新绘图",
      text: "执行中...",
      deleteRelation: {
        info: "您确定取消该关联吗？",
        tip: "以后不再提示",
      },
    },
    message: {
      error: "该节点已锁定，如需更改，请提交变更请求!",
      error2: "该节点所在版本由Sprint引入，无法删除，请前往Sprint中移除该节点",
      error3: "上传失败",
      info: "图片名称",
      suspect: "可疑",
      suspectTip: "与源节点内容不一致。手动点击右侧同步按钮，与源节点保持一致",
    },
    activity: {
      title: "活动",
      info: "显示：",
      tab: {
        comment: "评论",
        history: "历史记录",
        code: "Gitlab",
        code1: "Gitee",
        code2: "Github",
        code3: "Jenkins",
        code4: "Gerrit",
        review: "评审记录",
        testRecords: "测试记录",
        reuse: "复用记录",
      },
      comment: {
        placeholder: "按住Ctrl+Enter，快捷发送评论",
        info1: "请输入评论内容",
        info2: "评论成功",
        info3: "评论失败",
        place: "没有结果",
      },
      history: {
        option: "操作",
        originalData: "原数据",
        UpdatedData: "更改后",
        time: "操作时间",
        null: "无",
        teststep: "测试步骤",
        typeMap: {
          Create: "节点创建",
          Change: "字段变更",
          RelationAdd: "添加关联",
          testPlan: "测试计划",
          RelationDel: "删除关联",
        },
        tp1: "测试步骤详情",
        ms1: "留空",
        recoverMsg: "确定回滚到此图吗？",
        DeleteGanttItem: "移出甘特图",
        UpdateGanttItem: "甘特图字段变更",
        MoveGanttItem: "变更甘特图",
        CreateGanttItem: "加入甘特图",
        ganttName: "甘特图名称",
        day: "天",
        man_hour: "累计登记工时",
        plan_man_hour: "计划工时",
        progress: "进度",
        StartTime: "开始时间",
        DueTime: "结束时间",
        remove: "（已删除）",
        NodeSync: "节点同步",
        ChangeRequest: "变更通过",
      },
      jenkins: {
        time: "构建时间",
        job: "构建任务",
        result: "构建结果",
        during: "构建时长",
        code: "代码变更集",
        codeBtn: "查看变更集",
        dialog: {
          email: "变更人",
          summary: "变更描述",
          time: "变更时间",
        },
      },
      gerrit: {
        changeName: "变更名",
        operator: "发起人",
        createTime: "发起时间",
        repo: "关联仓库",
        reviewList: "审核人列表",
        status1: "状态",
        status: {
          st1: "进行中",
          st2: "已合并",
          st3: "已废弃",
        },
      },
      svn: {
        repoName: "仓库名",
        commitMessage: "提交信息",
        author: "提交人",
        revision: "提交版本号",
        createdAt: "创建时间",
      },
      code: {
        commit: {
          title: "Commit活动",
          repoName: "仓库名",
          repoTitle: "标题",
          pipelineRes: "流水线结果",
          securityScan: "安全扫描",
          operator: "操作人",
          opeTime: "操作时间",
          info: "个漏洞",
          info2: "无数据",
          info3: "评审中",
          info4: "评审通过",
        },
        merge: {
          title: "Merge Request活动",
          repoName: "仓库名",
          repoTitle: "标题",
          originToTarget: "原分支>目标分支",
          pipelineRes: "流水线结果",
          securityScan: "安全扫描",
          operator: "操作人",
          opeTime: "操作时间",
          reviewResult: "代码评审结果",
          reviewMember: "评审人员（已通过/总人数）",
        },
      },
      reuse: {
        CreateNode: "创建节点",
        sync: "同步",
        copyF: "复用",
        copyT: "复用",
      },
    },
  },

  baseline: {
    // 基线
    addBaselineForm: {
      // 新建基线表单
      title: "新建基线",
      name: "基线名称",
      desc: "基线描述",
    },
    topBar: {
      // 顶部操作
      beginBaseline: {
        title: "提示",
        content:
          "基线开始之后，节点默认被锁定，即不再允许直接修改节点。如需变更节点，请从节点处拉出分支进行变更。",
      },
      endBaseline: {
        title: "提示",
        content: "基线结束之后，节点自动解除锁定。",
      },
      pasteBaselineForm: {
        title: "新建副本",
        name: "副本名称",
        desc: "副本描述",
        note: "注：复制后，新基线将包含原基线中的所有内容",
        info: "请输入副本名称",
      },
      autoSave: "自动保存",
      deleteBaselineForm: {
        title: "提示",
        info: "确定要删除该基线吗？",
      },
    },
    basicInfo: {
      // 基本信息
      startTime: "基线开始时间",
      startPeople: "基线开始人",
      baselineDesc: "基线描述",
    },
    basicInfo2: {
      // 基本信息
      startTime: "基线创建时间",
      startPeople: "基线创建人",
    },
    basicInfo3: {
      // 基本信息
      startTime: "基线结束时间",
      startPeople: "基线结束人",
    },
    nodeTable: {
      name: "节点名",
      type: "文件类型",
      status: "状态",
      operation: "操作",
      addTime: "添加基线时间",
      operationList: {
        new: "新增",
        change: "变更",
        all: "全部",
      },
      operator: "操作人",
      operationTime: "操作时间",
      block1: "基线内容",
      block2: "基线内容删除项",
    },
    addNode: {
      // 添加节点
      title: "添加节点",
      placeholder: "请输入节点Key或关键词",
    },
    unlockNode: {
      // 解锁节点
      title: "提示",
      content:
        "取消节点锁定后，该节点可被直接更改，可能影响基线范围。确定要取消锁定吗？",
    },
    lockNode: {
      title: "提示",
      content:
        "确定要对节点进行锁定吗？节点锁定后，不再允许直接修改节点。如需变更节点，请从节点处拉出分支进行变更。",
    },
    history: {
      date: "日期",
      user: "用户",
      activity: "活动",
    },
    info: {
      addNode: "新增节点",
      delNode: "删除节点",
      modNode: "修改节点内容",
      recNode: "恢复节点",
    },
    message: {
      beginSuccess: "基线已开始",
      endSuccess: "基线已结束",
      pasteError: "创建基线副本异常，请稍后再试！",
      deleteSuccess: "删除成功",
      addSuccess: "添加成功",
      revertSuccess: "恢复成功",
      unlockSuccess: "成功取消节点锁定",
      lockSuccess: "成功锁定节点",
      NameCannotBeEmpty: "名称不能为空",
      archiveSuccess: "已存档",
      archiveFail: "无法保存为空的基线",
      versionArchiveFail: "无法保存为空的版本",
      archiving: "存档中，请稍后去存档记录中查看",
    },
    archive: {
      title1: "存档",
      title2: "基线存档",
      title3: "版本存档",
      remark: "备注",
      submit: "确认",
      cancel: "取消",
      time: "时间",
      user: "存档人",
      version: "存档版本号",
      Error1: "必填版本号",
    },
  },

  version: {
    // 版本
    addVersionForm: {
      // 新建版本表单
      title: "新建版本",
      name: "版本名称",
      baseline: "参考基线",
      testPlan: "测试计划",
      goal: "版本发布目标",
    },
    topBar: {
      // 顶部操作
      deleteVersionForm: {
        title: "提示",
        info: "确定要删除该版本吗？",
      },
    },
    basicInfo: {
      // 基本信息
      publishTime: "计划发布时间",
      actualTime: "实际发布时间",
      responsibility: "发布负责人",
      notice: "发布时通知",
      baseline: "参考基线",
      testPlan: "关联测试计划",
      sprint: "关联Sprint",
      goal: "版本发布目标",
      note: "备注",
      placeholder: "请选择日期时间",
    },
    status: "完成情况",
    nodeTable: {
      title: "标题",
      status: "状态",
      responsibility: "责任人",
      operator: "操作人",
      operationTime: "操作时间",
    },
    addNode: {
      title: "添加节点至该版本",
      placeholder: "请输入节点Key",
    },
    publishBtn: "发布",
    publishForm: {
      info: "关联的测试计划未完成，确定发布吗？",
    },
    message: {
      addSuccess: "新建版本成功",
      publishSuccess: "发布成功",
      publishWarn: "请先指定发布负责人",
      addError: "版本名称已存在",
      addError1: "新建版本失败",
    },
  },

  review: {
    tip1: "新建规则",
    tip2: "适用文件类型",
    tip3: "固定评审人员",
    tip4: "未指定，请用户自行指定",
    tip5: "通过规则",
    tip6: "一次评审结束时，通过率>=",
    tip7: "评审条目",
    tip8: "路径",
    tip9: "评审规则名称",
    tip10: "请输入名称",
    tip11: "提示",
    tip12: "确认要删除吗",
    tip13: "取 消",
    tip14: "确 定",
    tip15: "评审规则名称不能为空",
    tip16: "文件类型不能为空",
    tip17: "评审通过率不能为空且必须为数字(0-100)之间",
    tip18: "%的节点，自动通过评审",
    tip19: "删除",
    tip20: "评审条目不能为空",
    result: {
      info1: "评审结束时间",
      info2: "评审结果",
      info3: "详细评审结果",
      info4: "查看",
      info5: "通过",
      info6: "人",
      info7: "未通过",
    },
  },

  test: {
    // 测试
    testCaseID: "测试用例ID",
    attachments: "附件",
    addTestForm: {
      // 新建测试表单
      title: "新建测试计划",
      name: "测试计划名称",
      baseline: "参考基线",
      version: "关联版本",
    },
    topBar: {
      // 顶部操作
      pasteTestForm: {
        title: "复制测试计划",
        name: "测试计划名称",
        note: "注：复制后，新测试计划将包含原测试计划中的所有测试用例",
      },
    },
    basicInfo: {
      // 基本信息
      version: "待测版本",
      info: "同步版本中关联的测试用例",
      responsibility: "测试负责人",
      baseline: "参考基线",
      goal: "测试目标",
      note: "备注",
      planStartTime: "计划开始时间",
      plannedEndTime: "计划结束时间",
      actualStartTime: "实际开始时间",
      actualEndTime: "实际结束时间",
    },
    export: {
      title: "导出设置",
      name: "报告名称",
      project: "选择导出项目",
      all: "全选",
    },
    checked_options: {
      bug: "bug概览",
      tesecase: "测试用例概览",
      coverage: "覆盖度报告",
      result: "详情测试结果",
      detailBug: "Bug详情",
      api: "API测试报告",
      node: "待测节点",
      testcase: "测试用例",
    },
    status: "完成情况",
    notExecuted: "未执行",
    nodeTable: {
      title: "标题",
      result: "测试结果",
      status: "执行状态",
      bug: "关联bug",
      executor: "测试执行人",
      executor2: "执行人",
      executionTime: "执行时间",
      status1: "状态",
      assign: "责任人",
    },
    addNode: {
      title: "添加节点至该测试计划",
      place: "请输入节点Key",
    },
    newFile: {
      title: "新建文件",
      place: "请输入文件名",
      place2: "请选择文件类型",
      place3: "请选测试计划",
      place4: "请选测试用例",
    },
    setStatus: {
      title: "自定义执行状态",
      alert: "请注意，该设置对所有项目生效",
      name: "状态名称",
      type: "状态类型",
      option: "操作",
      info: "确定删除吗？",
      fileName: "文件名",
    },
    editStatus: {
      title: "编辑状态",
      name: "状态名称",
      color: "状态颜色",
    },
    addStatus: {
      title: "添加状态",
      name: "状态名称",
      type: "状态类型",
      color: "状态颜色",
    },
    report: {
      api: "API数量",
      test: "API测试用例数量",
      cov: "API测试用例覆盖率",
      auto: "自动化流程测试覆盖率",
      pie: "API 状态分布饼状图",
      detail: "API详情",
      num: "编号",
      name: "API名称",
      status: "状态",
      info1: "已编写测试用例API数量",
      info2: "未编写测试用例API数量",
      info3: "已绑定流程用例的API数量",
      info4: "未绑定流程用例的API数量",
      info5: "已通过测试",
      info6: "未通过测试",
      info7: "未测试",
    },
    message: {
      info1: "请输入报告名称",
      info2: "请至少选择一项",
      warn1: "请输入状态名称",
      warn2: "请输入状态颜色",
      warn3: "请输入状态类型",
      deleteSuccess: "删除测试计划成功",
      addSuccess: "新建测试计划成功",
      executeWarn: "请先指定测试负责人",
      pasteSuccess: "复制成功",
      pasteError: "复制失败",
    },
  },

  board: {
    // 敏捷看板共用内容
    fileFilter: "按文件",
    responsibilityFilter: "按责任人",
    status: {
      notStarted: "未开始",
      completed: "已完成",
      inProgress: "进行中",
      unAssigned: "未分配",
    },
    notice: "任务个数 , 故事点总和",
    settingPage: {
      title: "设置",
      name: "名称",
      bar: "栏",
      maxCardNum: "最大卡片数量",
      addBarBtn: "点击添加栏",
      addBarForm: {
        title: "输入名称",
        name: "名称",
      },
    },
    chart: {
      burn: "燃尽图",
      story: "剩余故事点",
      burn2: "燃起图",
      story2: "完成故事点",
      burn3: "完成故事图",
      story3: "故事点",
      assignee: "完成节点个数",
      num: "节点数量",
      task: "已完成任务",
      type: "类型",
      name: "节点名称",
      ass: "责任人",
      status: "状态",
    },
    message: {
      error: "无权限查看节点详情",
      error1: "不能移动到不同用户或文件",
      error2: "不支持移动到该状态",
      error3: "不能超出本列最大容纳卡片数量",
      info: "日期",
    },
  },

  scrum: {
    goal: "冲刺目标",
    addNewNode: "添加节点",
    stickyNode: "置顶节点",
    place: "请输入节点Key或关键词",
    sticky: "置顶",
    editScrumPage: {
      head1: "Sprint设置",
      head2: "冲刺目标",
      name: "sprint名称",
      date: "时间",
      goal: "冲刺目标",
    },
    newSprint: {
      title: "新建Sprint",
      name: "名称",
      goal: "冲刺目标",
      set: "Sprint设置",
      name2: "sprint名称",
      time: "时间",
      start: "开始日期",
      end: "结束日期",
      com: "完成Sprint",
      move: "将剩余节点移动至",
      info1: "该节点在",
      info2: "中被锁定",
    },
    clock: {
      part1: "还剩",
      part2: "天",
    },
  },

  kanban: {
    stick: "置顶节点",
    stickBtn: "置顶",
  },

  chart: {
    // 数据报表
    addChartForm: {
      // 新建数据报表表单
      title: "新建数据报表",
      name: "数据报表名称",
      desc: "数据报表描述",
    },
    topBar: {
      // 顶部操作
      deleteVersionForm: {
        title: "提示",
        info: "确认删除？",
      },
    },
    basicInfo: {
      // 基本信息
      creator: "创建人",
      createTime: "创建时间",
      readAuthority: "查看权限",
      writeAuthority: "编辑权限",
      desc: "描述",
      authorityList: {
        all: "所有项目成员",
        private: "私密",
        part: "部分项目组成员",
      },
    },
    addDiagramBtn: "添加图表",
    addDiagramForm: {
      title: "图表设置",
      name: "图表名称",
      type: "图表类型",
      typeList: {
        bar: "柱状图",
        pie: "饼状图",
        line: "折线图",
        radar: "雷达图",
        twoDTable: "二维数据报表",
        twoDCoverage: "二维覆盖度报表",
        duration: "状态平均所处时长",
        times: "状态平均跳转次数",
        condition: "筛选结果列表",
        vmodel: "V模型追溯性关系全景图",
        wmodel: "W模型追溯性关系全景图",
        l: "第",
        c: "层",
      },
      dataSource: "数据源",
      fieldSelection: "字段选择",
      node: "节点",
      file: "文件",
      datatype: "数据类型",
    },
    total: "合计：",
    allData: "全部数据",
    num: "数量",
    NodesQueried: "待查询节点",
    message: {
      deleteSuccess1: "已删除",
      deleteSuccess: "删除成功",
      saveSuccess: "保存成功",
      deleteError: "删除失败",
      info1: "该筛选条件已被数据报表中的",
      info2: "图表使用，无法删除",
      warn: "请输入正确数字",
      warn2: "编辑权限至少选中1人!",
      error: "存在必填项未填!",
      ms1: "统计文件或节点经过某个状态的平均次数",
      ms2: "统计文件或节点经过某个状态的平均时长",
      ms3: "若有50个A类型节点，其中有20个A类型节点与B类型节点建立了追溯性关系，则A所在行与B所在列所对应的覆盖度是40%",
      ms4: "创建时间段",
      ms5: "开始时间",
      ms6: "请选择",
      ms7: "结束时间",
      ms8: "数据",
      ms9: "数据指标",
      ms10: "V模型追溯性关系全景图插件已过期，请续费使用！",
      ms11: "点击续费",
      ms12: "若选定的项目中，用户需求一共有50条， 其中有10条用户需求关联了系统需求，则第一行第二列显示20% (10/50)",
      ms13: "X轴字段选择",
      ms14: "Y轴字段选择",
      ms15: "购买提示",
      ms16: "提示：V模型全景图为付费功能，请联系客服开通。",
      ms17: "天",
      ms18: "时",
      ms19: "分",
      ms20: "秒",
      ms21: "二维数据报表",
      ms22: "未关联",
      ms23: "的",
      ms24: "节点",
      ms25: "状态",
      ms26: "平均时长",
      ms27: "跳转次数",
      ms28: "合计",
      ms29: "无需覆盖节点",
      ms30: "文件类型",
      ms31: "节点名称",
      ms32: "提示：W模型全景图为付费功能，请联系客服开通。",
      ms33: "W模型追溯性关系全景图插件已过期，请续费使用！",
    },
  },

  bin: {
    // 回收站
    table: {
      type: "文件类型",
      name: "文件名称",
      deletePeople: "删除人",
      deleteDate: "删除日期",
      forceDeleteForm: {
        title: "提示",
        desc: "是否确认永久删除文件？被删除的文件将无法恢复",
      },
    },
    message: {
      revertSuccess: "文件已被恢复，可以到我的文件中查看",
      deleteSuccess: "删除成功",
      info: "已取消删除",
    },
  },

  projectDetails: {
    // 项目详情
    name: "项目名称",
    avatar: "项目头像",
    id: "项目ID",
    key: "项目KEY",
    agileType: "敏捷类型",
    gitlab: "关联代码仓库",
    eolink: "eolink配置",
    jenkins: "jenkins配置",
    editAvatarBtn: "点击修改",
    addGitlabBtn: "添加代码仓库",
    copyed: "已完成复制",
    addGitlabForm: {
      title1: "选择代码仓类型",
      note1:
        "1．您在私有 GitLab 至少具有代码仓的「Maintainer」权限，否则关联代码仓后，系统无法自动配置代码仓的 Webhook ，需要手工配置。</br> 2．私有 GitLab 版本最低支持11.11, 推荐使用12.4。",
      title2: "私有GitLab认证 (OAuth)",
      icon: "私有GitLab认证",
      note3: "认证成功请选择代码仓",
      note2: "暂无已认证的私有GitLab，您可以新建认证",
      note4:
        "1.进入SVN代码仓库的hooks 目录。例如，如果你的 SVN 仓库位于 /path/to/repo，那么你应该访问 /path/to/repo/hooks 目录。</br>2.编辑 post- commit脚本文件（post- commit.tmpl），并将如下代码添加至脚本。编辑完成之后将post- commit.tmpl文件重命名为post - commit",
      note5: "3.运行“chmod + x post - commit”命令，确保脚本具有可执行权限",
      note6: "# 仓库路径和提交后的版本号作为参数传递给脚本",
      note7: "# 使用svnlook获取commit信息",
      note8: "# 组装JSON数据",
      note9: "# 远端POST请求的URL",
      note10: "# 发送POST请求",
      addAutoBtn: "新建认证",
      addAutoForm: {
        warnMessage:
          "认证私有 GitLab 需要先在 GitLab 中新建Application，然后在当前弹窗填入信息并确认认证。",
        step1: {
          title: "在 GitLab 新建Application",
          step1:
            "打开私有 GitLab URL 后，点击页面右上角账号中的「Preferences」－ > 「Applications」；",
          step2:
            "根据下方的说明在 GitLab 填入指定字段，然后点击「Save application」即可。",
          url: "Redirect URI 需填写：",
          scopes: "Scopes 需勾选：",
        },
        step2: {
          title: "在当前弹窗填入认证信息",
          info: "新建 Application 完成后，在 Application 详情能看到 Application ID 和Secret。请结合上方信息填写以下字段。",
          field1: "请在 GitLab  复制 Application ID 并粘贴到此处",
          field2: "请在 GitLab  复制 Secret 粘贴到此处",
          field3: "私有 GitLab URL",
          placeholder: "例如 http://47.112.x.x",
        },
      },
      title3: "选择代码仓",
    },
    editEolinkSettingsBtn: "编辑配置",
    editEolinkSettingsForm: {
      tip: "账户设置=>Open API=>Open API Secret Key",
      filed: "适用文件类型：",
    },
    editJenkinsSettingsForm: {
      tip1: "填写Jenkins登录用户名",
      tip2: "从Jenkins右上角进入用户页>设置>API Token>添加新Token",
      tip3: "JenkinsJob的链接，如：https://jenkins域名/job/{Job名称}/  。多分支流水线或者文件夹需要进入具体的Job页复制链接，如https://jenkins域名/job/文件夹/job/mp/job/master/",
      tip4: "Jenkins主页地址，如https://jenkins.ytdevops.com",
    },
    message: {
      info1: "请选择活动区域",
      jenkins1: "请输入Jenkins地址",
      jenkins2: "请输入认证用户名",
      jenkins3: "请输入apiToken",
      lab: "极狐Gitlab",
      lab2: "私有GitLab",
      lab3: "公有Gitee",
      lab4: "Gitee",
      lab5: "公有Github",
      lab6: "Github",
      lab7: "SVN",
      error: "上传头像图片大小不能超过 1MB!",
      success: "上传成功",
      ing: "上传中",
      error2: "上传失败",
      info2: "请输入项目名称",
      dele: "确定要删除该关联仓库吗？",
      title: "提示",
      info3: "已取消删除",
      warn: "请至少选择一个代码仓",
      warn2: "请填写必填项",
    },
    GroupNotification: "消息群通知",
    AddConfiguration: "添加配置",
    GiteeAuthentication: "Gitee认证",
    AuthenticationSuccessful: "认证成功",
    NoAuthenticatedGitee: "暂无已认证的Gitee",
    GithubAuthentication: "Github认证",
    NoAuthenticatedGithub: "暂无已认证的Github",
    JenkinsAddress: "jenkins地址",
    AuthenticationUsername: "认证用户名",
    ConnectionFailed: "连接失败！",
    EnterBuildJobLink: "请输入要绑定的Build Job链接",
    SetIPWhitelistForDingTalk: "您需要设置钉钉机器人的IP白名单",
    MessageChannel: "消息渠道",
    SelectMessageChannel: "请选择消息渠道",
    EnterWebhookAddress: "请输入群机器人的webhook地址",
    Feishu: "飞书",
    DingTalk: "钉钉",
    WeChatWork: "企业微信",
    EnterToken: "输入token",
    WorkspaceDomainPrefix: "空间域名前缀",
    WorkspaceDomainDes:
      "空间管理>空间设置>工作空间域名，复制域名前缀后填入该输入框。如:空间域名为test123.w.eolink.com, 则空间域名前缀为test123",
  },

  user: {
    // 用户
    addUserBtn: "添加用户",
    ChangePassword: "修改密码",
    PleaseEnterYourPassword: "请输入密码",
    ConfirmPassword: "确认密码",
    PleaseEnterYourPasswordAgain: "请再次输入密码",
    passwordDoesNotMatch: "两次输入密码不一致！",
    ResetPasswordSuccessfully: "重置密码成功",
    ErrorInInput: "输入出现错误",
    addUserForm: {
      title: "添加用户",
      info1: "请选择用户或用户组",
      info2: "请选择职位",
      warn: "请选择需要添加的用户",
      name: "用户",
      position: "职位",
    },
    manager: "项目管理员",
    ordinary: "普通用户",
    userTable: {
      name: "姓名",
      role: "角色",
      placeholder: "选择角色",
      position: "职位",
      placeholder2: "未分配职位",
      positionType: {
        pm: "项目经理",
        requirement: "需求工程师",
        developer: "开发工程师",
        test: "测试工程师",
        operation: "运维工程师",
      },
      options: {
        remove: "移除联系人",
      },
      message: {
        addSuccess: "添加成功",
      },
    },
  },

  permission: {
    // 权限
    node: {
      title: "节点",
      view: "查看",
      edit: "编辑",
      create: "创建",
      move: "跨文件移动节点",
      deleteLeafNode: "删除叶子节点",
      deleteNonLeafNode: "删除非叶子节点",
    },
    file: {
      title: "文件",
      view: "查看",
      export: "导出",
      delete: "删除",
    },
    baseline: {
      title: "基线",
      create: "创建/测试/编辑",
      delete: "删除",
      unlock: "取消节点锁定",
    },
    version: {
      title: "版本",
      create: "创建/编辑",
      delete: "删除",
      publish: "发布",
    },
    testPlan: {
      title: "测试计划",
      create: "创建/编辑",
      delete: "删除",
      export: "导出",
    },
    deleteRoleBtn: "删除",
    deleteRoleForm: {
      title: "提示",
      desc: "删除该职位，将导致相关员工的职位变为未分配状态，确定要删除吗？",
    },
    addNewRoleBtn: "添加新职位",
    placeholder: "请输入职位名",
    message: {
      addRoleError: "职位名称已存在",
      error: "有子权限依赖， 无法取消勾选",
    },
  },

  fileType: {
    // 文件类型
    vmodelBtn: "配置追溯视图",
    vmodelBtn1: "配置V模型视图",
    vmodelBtn2: "配置W模型视图",
    addFileTypeBtn: "新建文件类型",
    addFileTypeForm: {
      title: "新建文件类型",
      name: "文件类型名",
      bug: "是否为bug",
      pop: "当文件为Bug时，其画布页和节点详情页包含测试计划和测试用例字段",
      pop2: "当文件为测试用例时，其节点详情页包含前置条件、测试步骤、预期结果字段；画布页浮窗上多了一个测试结果字段，及创建问题入口",
      yes: "是",
      no: "否",
      testcase: "是否为测试用例",
    },
    fileTypeTable: {
      type: "文件类型",
      icon: "图标",
      layer: "层级",
      options: {
        edit: "编辑",
        paste: "复制",
        delete: "删除",
        deleteForm: {
          info: "确定删除该文件类型吗?",
        },
      },
    },
    message: {
      deleteInfo: "已取消删除",
      deleteSuccess: "删除成功!",
    },
    permission: {
      table: {
        label1: "职位",
        label2: "无权限",
        label3: "只读",
      },
    },
    upgradeMechanism: "升级机制",
    ruleDescription: "规则描述",
    enableOrNot: "启用",
    deleteInfo: "确认删除?",
    p1: "当节点状态处于",
    p2: "且节点内容无任何变动超过",
    p3: "天时",
    p4: "向",
    p5: "发送通知。",
    p6: "升级机制启动时，仅对启用前一个月内创建、及启用后创建的节点生效。",
  },

  editFileType: {
    // 文件类型编辑页
    msg: "指定责任人",
    tip: "继承最后一列所有属性",
    title: "系统需求",
    createFieldBtn: "没有找到合适的字段？点击添加新字段",
    field: {
      title: "字段",
      table: {
        name: "字段名",
        type: "字段类型",
        required: "必填",
        floatWindow: "浮窗显示",
      },
      newFieldBtn: "新增条目",
    },
    coverage: {
      title: "覆盖度检测",
    },
    hara: {
      title: "功能安全",
      message: "严重度, 暴露度, 可控性, 功能安全等级",
    },
    dfmea: {
      message: "功能要求及特性, 失效",
      tooltips: "所有层级继承第一层设置",
    },
    workflow: {
      title: "工作流",
      title2: "模板列表",
      placeholder: "切换模板",
      addStatusBtn: "增加状态",
      newBtn: "新建状态",
      name: "状态名",
      type: "类别",
      place: "请选择状态类别",
      place2: "请输入状态名",
      info2: "初始状态节点不可删除，请先改变初始状态节点！",
      success: "模板添加成功",
      saveAsNewTemplateBtn: "另存为新模板",
      info: "让所有的状态转换到此状态",
      warn: "名称不可为空",
      saveAsNewTemplateForm: {
        title: "另存为新模板",
        name: "模板名",
      },
      saveBtn: "保存",
      saveForm: {
        title: "状态确认",
        original: "原状态",
        matched: "匹配状态",
        place: "不可为空",
      },
      message: {
        saveSuccess: "保存成功",
        ms1: "以下验证条件不通过，无法进行状态流转",
        ms2: "请检查自动化工作流设置：",
      },
      template: {
        tip1: "删除层级会一并删除该层级的所有设置，确定要删除吗？",
        tip2: "责任人",
        tip3: "故事点",
        tip4: "基线",
        tip5: "版本",
        tip6: "关联节点",
        tip7: "添加状态",
        tip8: "编辑",
        tip9: "退出编辑",
        tip10: "工作流自动化设置：",
        tip11: "自动触发",
        tip12:
          "自动触发功能可能受必填字段影响，若必填字段未完全填写，状态可能无法变更，从而导致自动触发功能失效",
        tip13: "当节点的所有子节点都推动到",
        tip14: "指定状态",
        tip15: "请指定状态",
        tip16: "请指定条件",
        tip17: "字段",
        tip18: "请指定字段",
        tip19: "请指定用户",
        tip20: "添加条件",
        tip21: "验证条件",
        tip22: "当",
        tip23: "时，才允许推动到下一个状态",
        tip24: "只有指定的",
        tip25: "用户，才允许推动到下一个状态",
        tip26: "只有",
        tip27: "和",
        tip28: "两个字段的值相同时，才允许推动到下一个状态",
        tip29: "状态时，自动推进到下一个状态",
        tip30: "当",
        tip31: "只有指定",
        tip32: "值",
        tip33: "请输入值",
        tip34: "请输入文本",
        tip35: "多行文本",
        tip36: "单行文本",
        tip37: "请输入链接",
        tip38: "链接",
        tip39: "日期",
        tip40: "请选择日期",
        tip41: "请指定标签",
        tip42: "标签",
        tip43: "请指定单选项",
        tip44: "单选项",
        tip45: "请指定多选项",
        tip46: "多选项",
        tip47: "指定用户",
        tip48: "字段1",
        tip49: "请指定字段1",
        tip50: "字段2",
        tip51: "请指定字段2",
        tip52: "指定字段",
        tip53: "指定职位",
        tip54: "职位的用户，才允许推动到下一个状态",
        tip55: "字段不为空时，才允许推动到下一个状态",
        tip56: "请指定文件类型",
        tip57: '当节点关联的所有指定类型的节点都推动到某个状态时，自动推进到下一个状态'
      },
      dialog: {
        tip1: "请指定用户职位",
        tip2: "后处理",
        tip3: "推动到下一个状态后，自动将当前节点的子节点置为",
        tip33: "推动到下一个状态后，自动将责任人指派为",
        tip4: "推动到下一状态后，自动将",
        tip5: "字段值改成",
        tip6: "当前修改未保存，是否保存？",
        tip7: "直接退出",
        tip8: "保存",
        tip9: "购买提示",
        tip10: "提示：工作流自动化为付费功能，请联系客服开通。",
        tip11: "该功能未开发，请使用其它功能",
        tip12: "满足所有条件",
        tip13: "满足任一条件",
        tip14: "工作流模板",
        tip15: "模版名称",
        tip16: "操作",
        tip17: "修改成功！",
        tip18: "修改失败！",
        tip19: "删除成功！",
        tip20: "删除失败！",
      },
    },
  },

  fieldLab: {
    // 字段库
    default_tip: "点击下方选择默认值",
    addNewFieldBtn: "添加字段",
    addNewFieldForm: {
      title: "添加新字段",
      placeholder1: "请输入字段名",
      placeholder2: "请选择字段类型",
      placeholder3: "请输入描述",
      placeholder4: "请输入单位",
      info: "请确保各项非空",
      fieldTypeList: {
        text: "单行文本",
        textarea: "多行文本",
        number: "数字",
        select: "单选项",
        multiSelect: "多选项",
        user: "单用户",
        multiUser: "多用户",
        date: "日期",
        label: "标签",
        version: "版本",
        link: "链接",
      },
    },
    info: "不能添加重复选项",
    fieldTable: {
      name: "字段名",
      type: "字段类型",
      action: "操作",
      unit: "单位",
      options: {
        delete: "删除",
        edit: "修改",
        editForm: {
          title: "修改选项字段",
          placeholder: "请输入选项内容",
          high: "高",
          medium: "中",
          low: "低",
          addBtn: "添加",
          editBtn: "修改",
        },
      },
    },
    message: {
      deleteError: "该字段已被使用，无法删除",
      addError: "该选项内容不能为空",
      ms1: "当字段变化时，发送通知给干系人",
      ms2: "该字段为系统字段，不可修改",
      ms3: "修改成功",
      ms4: "修改通知设置",
      ms5: "只有数字类型可以编辑单位",
      ms6: "修改单位",
    },
  },

  changeRequest: {
    // 变更请求
    saveAndExitBtn: "保存并退出",
    field1: "变更委员会",
    placeholder1: "未指定，请用户自行指定。",
    field2: "通过规则",
    placeholder2: "当评审通过率>=",
    placeholder3: "%时，变更请求自动通过",
    placeholder4: "%时，且",
    placeholder5: "或节点创建人手动点击确认后，变更请求通过",
    byHand: "手动合并",
    auto: "自动合并",
    info: "开启委员会后，至少选择一名人员",
    needReview: "待我评审",
    needConfirm: "待我确认",
    myIssue: "我发起的",
    allChange: "全部变更",
    title: "变更请求",
    approve: "待合并",
    reviewing: "评审中",
    merge: "已合并",
    refuse: "已拒绝",
    initiator: "变更发起人",
    option1: "变更次数最多的",
    option2: "个节点",
    option3: "%节点",
    times: "变更次数",
    id: "Key",
    pieHeader: "发起的",
  },

  gantt: {
    info1: "甘特图",
    info2: "甘特图名称",
    info3: "累计登记工时",
    info4: "天",
    infot: "天",
    info5: "周",
    info6: "时",
    info7: "分钟",
    info8: "新增工时",
    info9: "确认",
    info10: "节点移动成功",
    info11: "节点添加成功",
    info12: "成功修改时间",
    info13: "节点已成功从甘特图移除",
    info14: "进度计算成功",
    info15: "当前还没有任何数据哦，请先为甘特图添加节点",
    total: "总计",
    PleaseAdd: "请为甘特图添加节点",
    AddNode: "添加节点到甘特图",
    PleaseEnterNodeID: "请输入节点Key或关键字",
    confirmTheDeletion:
      "删除当前甘特图，将清除甘特图内所有数据资源，但不会删除节点本身。是否确认删除？",
    CreateGantt: "新建甘特图",
    selectAll: "全选",
    Name: "名称",
    Desc: "描述",
    nodeName: "节点名称",
    status: "状态",
    workHours: "计划工时",
    startTime: "计划开始时间",
    endTime: "计划结束时间",
    selectDate: "选择时间",
    GanttSettings: "甘特图设置",
    NotificationRules: "通知规则",
    UnlockGantt: "购买提示",
    Tip: "提示：甘特图为付费功能，请联系客服开通。",
    AutomaticScheduling: "自动排期",
    ScheduleFromTop: "从顶层调整",
    ScheduleFromBottom: "从底层调整",
    ScheduleFromTopTip:
      "当甘特图中的事件具有层级关系时，母事件设定好了时间范围，子事件只能在这个时间范围内完成。因此系统将自动帮您调整子事件的时间范围",
    ScheduleFromTopBottomTip:
      "当甘特图中的事件具有层级关系时，子事件设定好了时间范围，母事件的时间范围不得小于子事件的时间范围。因此系统将自动帮您调整母事件的时间范围",
    ScheduleFromTip2: "确定要对内容进行",
    ScheduleFromTip3: "吗?",
    BeforeScheduleFromTop: "调整前",
    AfterScheduleFromTop: "调整后",
    BeforeScheduleFromBottom: "调整前",
    AfterScheduleFromBottom: "调整后",
    irreversible: "该操作不可逆",
    byMonth: "按月显示",
    byGroup: "按群组显示",
    byResponsible: "按责任人显示",
    byQuarte: "按季度显示",
    byYear: "按年显示",
    NoResponsible: "无责任人",
    deletedSuccessfully: "甘特图删除成功",
    hasChanged: "当前甘特图结构发生变化，刷新前部分功能无法使用，请单击",
    missingId: "该节点缺少id",
    structureModified: "当前甘特图结构已被修改，刷新前无法添加节点",
    InsertFailed: "插入失败",
    NoCurrentlySelected: "当前未选择甘特图",
    forbiddenBottomNodeUp: "连接非法，禁止由下方节点向上连接",
    forbiddenNodesAtTheEnd: "连接非法，禁止节点尾部相连",
    forbiddenheadToTail: "连接非法，禁止节点头部向尾部连接",
    forbiddenSameConnectionStake: "连接非法，禁止向同一连接桩发起连接",
    addedSuccessfully: "甘特图添加成功",
    Quarter: "季度",
    ScaledUpToUpperLimit: "已放大至上限",
    ScaledDownToLowerLimit: "已缩小至下限",
    NewGanttChartFailed: "新建甘特图失败",
    SetUpSuccessful: "设置成功",
    SetupFailed: "设置失败",
    tipworkhours: "请先填写计划工时",
    ProgressCalculationSuccess: "进度计算成功",
    ErrorPassingReference: "错误传参",
    NoProfileAvailable: "暂无简介",
    TryAddingNodes:
      "试试添加节点到甘特图，您也可以去思维导图页面，点击右键添加。",
    PermanentlyClosed: "永久关闭",
    SetAsMilestone: "设置为里程碑",
    UnsetMilestone: "取消设置里程碑",
    Remove: "移除",
    RemovingParentNode: "移除父节点，会导致子节点一并被移除，确定移除操作吗？",
    importGantt: "甘特图导入",
    nochoseGrantTip: "请先选择一个甘特图",
    chart: {
      title: {
        title1: "状态",
        title2: "责任人",
        title3: "工时-责任人",
        title4: "状态-责任人",
        title5: "实际工时",
        title6: "工时(天)",
        title7: "差值",
        title8: "合计",
      },
    },
    table: {
      GanttChartDataSummary: "甘特图数据汇总",
    },
  },

  personal: {
    // 个人中心
    menu: {
      // 左菜单
      user: "用户管理",
      project: "项目管理",
      thirdParty: "第三方应用管理",
      OtherSettings: "其他设置",
      userGroup: "用户组管理",
    },
    edit: {
      password: "修改密码",
      info: "您已绑定",
      place: "请输入邮箱验证码",
      new: "请输入新密码",
      confirm: "请确认新密码",
      again: "请再次输入密码",
      no: "两次输入密码不一致!",
      cap: "获取验证码",
      input: "请输入验证码",
      re: "s后重发",
      success: "验证码已发送",
      success2: "密码重置成功",
      ava: "上传头像图片大小不能超过 5MB!",
      ing: "上传中",
      error: "上传失败",
      avatar: "头像",
      click: "点击修改",
      info2: "名称不可为空",
      info3: "不能更改",
      info4: "不能删除自己哦！",
    },
    userManagement: {
      // 用户管理
      table: {
        // 用户table
        name: "姓名",
        email: "邮箱",
        role: "角色",
        userType: "用户类型",
        projects: "加入项目",
        LastActiveTime: "最近活跃时间",
        options: {
          editUserInfoForm: {
            // 编辑用户资料表单
            title: "编辑用户资料",
            name: "姓名",
            email: "邮箱",
            role: "角色",
          },
        },
      },
      newUserBtn: "邀请新用户", // 添加新用户按钮
      newUserForm: {
        // 添加新用户表单
        tab1: "邀请用户",
        tab2: "批量邀请用户",
        info1: "添加用户后，用户会自动收到登录邮件",
        info2: "请下载导入模板:",
        info8: "导入模板.xlsx",
        name: "姓名",
        email: "邮箱",
        role: "角色",
        userType: "用户类型",
        legal: "合法",
        illegal: "不合法原因",
        userType: "用户类型",
        info3: "邮箱不能为空",
        info4: "请输入正确的邮箱格式",
        info5: "请输入用户名",
        info6: "请选择角色",
        info7: "邀请成功",
        info9: "请选择用户类型",
      },
      add: {
        p1: "共有",
        p2: "个用户是合法的,其他用户为不合法用户,请查看具体原因。",
        p3: "个用户是合法的",
        p4: "所有用户都为不合法用户，请仔细检查原因",
        p5: "识别到表格中没有用户",
        p6: "确定要导入吗?",
      },
    },
    userGroupManagement: {
      //用户组管理
      searchPlaceholder: "按群组名称搜索",
      newGroup: "新建群组",
      editGroup: "编辑群组",
      authorityTip: "当前用户暂无权限操作",
      delete: {
        tip: "提示",
        tipText:
          "删除用户组后，基于该用户组的权限设置会被一并删除，确认删除吗？",
        deletetext: "删除成功!",
        confirmCanael: "取消",
        confirmDelete: "删除",
      },
      addGroup: {
        groupName: "群组名称",
        groupMember: "群组成员",
        groupDescription: "群组描述",
        placeholder: {
          info1: "请输入群组名称",
          info3: "添加描述",
        },
      },
      table: {
        groupName: "群组名称",
        member: "成员",
        description: "描述",
        operate: "操作",
      },
    },
    projectManagement: {
      // 项目管理
      tab1: "当前项目",
      tab2: "已删除的项目",
      table: {
        // 项目table
        avatar: "项目头像",
        type: "项目类型",
        name: "项目名称",
        lastUpdated: "上次修改",
        deleteAt: "删除时间",
        memberNum: "团队人数",
        creator: "创建人",
        leader: "项目管理员",
        options: {
          editBtn: "管理成员",
          revertBtn: "恢复项目",
          editMemberForm: {
            // 管理成员表单
            name: "姓名",
            role: "角色",
            position: "职位",
            options: "移除责任人",
            addNewUserBtn: "添加用户",
            addNewUserForm: {
              title: "添加用户",
              name: "姓名",
              position: "职位",
            },
          },
          deleteForm: {
            // 删除项目&永久删除表单
            title: "删除项目",
            title2: "永久删除项目",
            info1: "确定要",
            info2: "永久",
            info3: "删除此项目吗？",
            desc: {
              name: "项目名称",
              lastUpdated: "上次修改",
              memberNum: "团队人数",
            },
          },
        },
      },
      message: {
        p1: "项目组已删除",
        p2: "项目组已恢复",
        p3: "请输入正确的项目名称",
        p4: "流程已发起",
      },
    },
    thirdPartyManagement: {
      // 第三方应用管理
      tab1: "我的应用",
      tab2: "收藏夹",
      title: "第三方应用",
      placeholder: "搜索应用",
      info: "个应用",
      detail: {
        // 详情页
        tab: "介绍",
        intro: "简介",
        preview: "预览",
      },
      settings: {
        // 设置页
        setBtn: "设置",
        removeBtn: "移除",
        confirmForm: {
          title: "操作确认",
          desc: "确定要移除该应用吗？",
        },
      },
      free: "免费",
      get: "获取",
      config: "配置",
      add: "授权并安装",
      breadcrumb: {
        // 面包屑内容
        app: "应用",
        settings: "设置",
      },
    },
    openApi: {
      basicInfo: {
        // 第一块
        title: "基本信息",
        id: "租户ID",
        note: "如果您认为密钥已经泄漏，建议您尽快修改密钥，修改密钥后，旧的密钥会即时失效，请及时使用新的密钥替换旧的密钥",
        resetBtn: "重置API Secret",
        add: "创建Secret",
      },
      use: {
        // 第二块
        title: "使用Open API",
        btn1: "查看接入指南",
        btn2: "查看Open API文档",
      },
      add: {
        p1: "获取租户ID和API Secret",
        p2: "点击界面右上角进入项目管理界面，侧栏底部点击<Open API>选项获取租户ID和API Secret",
        p3: "在请求中携带租户ID和API Secret",
        p4: "在Request header中添加如下header：",
        p5: "获取项目ID",
        p6: "点击选择项目后，依次点击<设置>-<关于项目>复制项目ID",
        p7: "项目下的业务操作需要携带项目ID",
        p8: "如果某些业务操作需要指定项目，则在请求中需要携带项目ID，携带方式具体参考",
        p9: "API文档",
        p10: "示例",
        p11: "（在下面的请求中，项目ID作为路径参数出现在请求中）：",
        p12: "项目ID",
        p13: "OpenAPI接入流程",
      },
      message: "重置成功！",
      message2: "创建成功！",
      message3: "复制成功！",
    },
    otherSettings: {
      watermarkFunction: "水印功能",
      share: "思维导图分享功能",
      PleaseEnter: "请输入水印内容(选填)",
      tips: "未填写时默认显示用户邮箱前缀。填写后显示用户添加内容+用户邮箱前缀",
      dangerzone: "危险操作",
      Deregistertenant: "注销租户",
      DeregistertenantDes:
        "在您继续进行操作之前，请输入您收到的邮箱验证码以确认您本人在进行操作",
      getCode: "获取验证码",
      please: "请输入验证码",
      warning: "警告!",
      warningMsg1: "该操作将删除该租户下的全部数据，请确认是否删除。",
      warningMsg2: "警告！该操作不可逆，请确认是否注销。",
      warningMsg3:
        "注销操作属于危险操作，注销之后，所有用户数据不再可恢复。请先删除租户下的所有用户，再进行上述操作，确保您的企业数据安全。",
      deregister: "注销",
      Custom: "自定义特性环境",
    },
    ones: {
      title: '认证Ones需要先在 Ones 中新建“自定义 OAuth 应用”，然后在当前弹窗填入信息并确认认证',
      subtitle1: '在 Ones 新建"自定义 OAuth 应用',
      step1before: '请参考（',
      step1middle: '此处',
      step1after: '）创建 OAuth 应用',
      step2: '根据下方的说明在Ones填入指定字段，然后点击「确定」即可',
      authenticationType: '认证类型',
      organization: '组织',
      Scope: '范围需勾选',
      projectManagement: '项目管理',
      subtitle2: '在当前弹窗填入认证信息',
      step3: '新建 Application 完成后，在 Application 详情能看到 Application ID 和Secret。请结合上方信息填写以下字段',
      pl1: '请在创建应用后复制Client ID并粘贴到此处',
      pl2: '请在创建应用后复制Client Secret并粘贴到此处',
      pl3: '自定义 OAuth 应用-> 你的域名',
    }
  },

  notice: {
    // 通知中心
    title: "通知中心",
    menu: {
      // 左菜单
      myAt: "@我的",
      myResponsibility: "指定我为责任人",
    },
    placeholder: "暂无通知",
    btn: {
      read: "全部标记为已读",
      delete: "删除已读",
    },
    card: {
      // 卡片内容
      time: "时间",
      nodeName: "节点名称",
      del: "节点已被删除",
      com: "评论",
    },
    weeklyNotification: "周报通知",
    message: "该节点已删除，无法跳转。",
    FieldAssignmentReminder: "字段指派提醒",
    assign: "任务指派",
    batchAssign: "任务批量指派",
    knowledgeQuickShare: "知识库快速分享",
    atInDocument: "知识库页面内提及",
    commentAt: "评论提及",
    review: "邀请评审",
    endReview: "评审结束",
    inviteDeleteProject: "项目删除邀请",
    endDeleteProject: "项目删除完成",
    ganttStartNotifyAssignee: "甘特图任务开始(责任人)",
    ganttStartNotifyCollector: "甘特图任务开始(收藏人)",
    ganttEndNotifyReliedAssignee: "甘特图上级依赖任务结束(责任人)",
    ganttEndNotifyReliedCollector: "甘特图上级依赖任务结束(收藏人)",
    customSingleUserFieldChange: "自定义单用户类型字段通知",
    customMultiUserFieldChange: "自定义多用户类型字段通知",
    applyAccessOnDoc: "申请知识库文章阅读权限",
    Images: "图片",
    version: "版本发布通知",
    ThisReviewDoesNotExist: "该评审不存在",
    ThisInvitationDoesNotExist: "该邀请不存在",
    TheGanttChartDoesNotExist: "该甘特图不存在",
    TheArticleDoesNotExist: "该文章不存在",
    wordComments: "关注的知识库页面有新的取词评论",
    newPageComments: "关注的知识库页面有新的页面评论",
    reviewEndInFourHours: "评审任务在四小时内结束",
    docWordCommentAt: "知识库取词评论提及",
    docCommentAt: "知识库页面评论提及",
    followDocChanged: "关注的知识库页面内容有更改",
    followDocCommented: "关注的知识库页面被评论",
    testPlanEndInOneDay: "测试计划将在24小时内结束，请及时处理",
    docInternetPermission: "您管理的项目中有文章被设置为互联网可",
    dfmeaOptimization: "DFMEA优化任务指派",
    dfmeaOptimizationEnd: "DFMEA优化措施到达截止日期",
    changeRequest: "邀请评审变更请求",
    changeRequestRelated: "相关节点发起变更请求",
    statusTimeOut: "状态停留超时",
    over: "超过",
    days: "天",
    editable: "编辑",
    readable: "查看",
  },

  addNodeDetail: {
    title: "创建副本",
    lab1: "变更描述",
    lab2: "审核人员",
    lab3: "查看详情",
    info: "变更委员会未开启， 请联系管理员",
    place: "请选择评审人",
    lab4: "详细描述",
    field: "字段",
    now: "当前值",
    desc: "描述",
    styPoint: "故事点",
    attachments: "附件",
    change: "变更值",
    info2: "评审通过率已超过",
    info3: "请确认是否同意该变更",
    lab5: "选择变更字段",
    next: "下一步",
    edit: "编辑副本",
    res: "评审结果",
    people: "审核人员",
    comment: "评论",
    pass: "通过",
    rej: "拒绝",
    reason: "拒绝理由",
    upload: "提交",
    my: "我发起的",
    wait: "待我评审",
    wait2: "待我确认",
    message1: "请输入变更描述",
    message2: "请输入拒绝理由",
    message3: "请输入副本名称",
    message4: "请选择变更字段",
    message5: "变更请求",
    message6: "节点名称",
    message7: "修改",
    message8: "立即合并",
    message9: "已通过",
    message10: "已合并",
    message11: "已拒绝",
    message12: "评审中",
    message13: "同意",
    message14: "拒绝",
    message15: "发起变更请求",
    message16: "变更详情",
    message17: "立即拒绝",
  },

  addMyFilePermission: {
    view: "查看",
    edit: "编辑",
    download: "下载",
    info1: "搜索添加成员",
    role1: "开发工程师",
    role2: "运维工程师",
  },

  addMyFileHead: {
    desc: "确认要删除条件吗",
    title: "提示",
    info: "已删除",
    info2: "删除失败",
    info3: "保存成功",
    info4: "请输入条件名称",
  },

  addNodeTestPlan: {
    addnull: "添加空步骤",
    copy: "复制",
    del: "删除",
    title: "测试步骤",
    lab1: "编号",
    lab2: "前置条件",
    place1: "请输入前置条件",
    lab3: "测试步骤",
    place2: "请输入测试步骤",
    lab4: "预期结果",
    place3: "请输入预期结果",
    btn1: "保存",
    btn2: "编辑",
    message: "保存成功",
  },

  addMindMap: {
    p1: "不能删除根节点！",
    p2: "删除该节点将导致其后的叶子节点一并被删除，确定要删除吗？",
    p3: "删除节点后，节点不可恢复，确定要删除吗？",
    p4: "快捷键列表",
    p5: "反馈",
    lab1: "测试步骤",
    lab2: "测试步骤列表",
    btn1: "保存",
    btn2: "编辑",
    lab3: "前置条件：",
    lab4: "测试步骤：",
    lab5: "预期结果：",
    info1: "新增同级节点",
    info2: "新增子节点",
    info3: "编辑文字",
    info4: "折叠/展开",
    message1: "所有更改已保存",
    message2: "批量选中时不可添加节点",
    message3: "正在保存..",
    message4: "无删除叶子节点权限",
    message5: "无删除非叶子节点权限",
    message6: "以下节点存在于已开始的基线中无法删除: ",
    message7:
      "该用例所在测试计划已完成，删除后会影响测试报告；同时删除该节点将导致其后的叶子节点一并被删除。确定删除吗？",
    message8: "该用例所在测试计划已完成，删除后会影响测试报告。确定删除吗？",
    message9:
      "该用例所在测试计划处于进行中；同时删除该节点将导致其后的叶子节点一并被删除。确定删除吗？",
    message10: "该用例所在测试计划处于进行中。确定删除吗？",
    message11: "锁定节点不可编辑",
    message12: "锁定节点不支持移动!",
    message13: "已经退到底啦",
    message14: "不能前进啦",
    message15: "导出成功",
    message16: "当前页面发生改动, 请单击",
    message17: "成功删除节点！",
    message18: "设置成功",
    message19: "设置失败",
    message20: "传入参数不是对象",
    message21: "根节点不能添加同级节点",
    message22: "请先选中一个节点",
    message23: "以下节点在基线中被锁定无法移动: ",
    place1: "请输入模板名",
    title1: "设置名称",
    title2: "提示",
    btn3: "刷新",
    tip1: "完成情况：",
    tip2: "评审条目",
    tip3: "邀请评审",
    tip4: "评审任务标题",
    tip5: "请输入评审任务标题",
    tip6: "评审截止时间",
    tip7: "选择日期时间",
    tip8: "固定评审人员",
    tip9: "执行评审",
    tip10: "选择被邀请人",
    tip11: "请填写不通过理由",
    tip12: "购买提示",
    tip13:
      "提示：AI补充为付费功能，试用版每天可使用100次。完整版功能，请联系客服开通。",
    tip14: "所选节点全都是不可评审的节点",
    tip15: "已通过的节点不可再次发起评审",
    tip16: "评审中的节点不可再次发起评审",
    tip17: "请选择节点",
    tip18: "请填写通过理由",

    saveMindmapMoveDialogTitle: "该操作需要您先登录MappingSpace",
    hasAccount: "已有账号",
    noAccount: "注册",
  },

  addFileList: {
    title1: "图片预览",
    title2: "pdf预览",
  },

  addMindWord: {
    message: "节点被锁定无法编辑节点名称",
  },

  addAddUser: {
    message: "请至少填写一条用户信息",
  },

  addAt: {
    default: "评论全文",
    depart: "（已离职）",
  },

  addLogin: {
    otherlogin: "其他登录方式",
    binding: "第三方绑定",
    complete: "完成",
    introduce: "汽车行业一站式需求、开发、测试管理平台",
    solution: "国产替代首选解决方案",
    forgetPsd: {
      title: "重置密码",
      place1: "请输入邮箱",
      place2: "请输入新密码",
      place3: "请输入验证码",
      title2: "已有账号？",
      title3: "去登录",
      title4: "没有账号？",
      getCode: "获取验证码",
      message: "邮箱地址不能为空",
      message2: "请输入正确的邮箱地址",
      message3: "密码重置成功",
      message4: "验证码已发送",
      message5: "秒后重发", // 比如60秒后重发
      message6: "180秒后重发",
      message7: "验证码已发送到邮箱",
      message8: "",
      message9: "秒后重发",
      PleaseSetPassword: "请设置密码",
      JoinNow: "立即加入",
      PleaseEnterTheCorrectEmailDddress: "请输入正确的邮箱",
    },
    loginEmail: {
      email: "邮箱验证登录",
      email2: "验证码登录",
      psd: "密码登录",
      login: "立即登录",
      message: "验证码已发送到邮箱",
    },
    loginPsd: {
      forget: "忘记密码",
      place: "请输入密码",
      place2: "请输入您的密码",
    },
    myInfo: {
      email: "我的邮箱",
      edit: "修改邮箱",
      reset: "重置密码",
    },
    register: {
      title: "注册",
      place: "确认密码",
      register: "立即注册",
      message: "请再次输入密码",
      message2: "两次输入密码不一致!",
      VerificationCodeSent: "验证码已发送至手机",
      OperateFrequentPrompts: "用户操作过于频繁",
      PleaseEnterTheCorrectCellPhoneNumber: "请输入正确手机号",
      VerificationCodeSentToCellPhone: "手机验证码",
      PleaseEnterYourCellPhoneNumber: "请输入手机号",
      agreement: "我同意云体科技公司的",
      privacyPolicy: "隐私政策",
      termsOfUse: "使用条款",
      and: "和",
      message3: "为了提供更好的服务，需要您验证手机号",
      verificationCode: "验证码",
      mobilePhoneNumber: "手机号",
    },
    resetEmail: {
      h2: "修改邮箱",
      my: "我的邮箱",
      new: "新邮箱",
      new2: "新邮箱号",
      psd: "密码",
      edit: "修改",
      return: "返回",
      reset: "修改密码",
      message: {
        p1: "修改成功",
        p2: "修改失败，邮箱已被使用过，请重试",
        p3: "修改失败，密码错误，请重试",
        p4: "修改失败，请稍后再试",
        p5: "已取消更改",
      },
      form: {
        desc: "是否确认修改邮箱为：",
        title: "提示",
      },
    },
    resetPsd: {
      title: "密码重置",
      old: "旧密码",
      new: "新密码",
      confirm: "确认新密码",
      place: "请输入您的旧密码",
      btn: "立即重置",
      message: {
        p1: "重置成功!",
        p2: "重置失败，密码错误，请重试",
        p3: "重置失败，请稍后再试",
        p4: "已取消更改",
      },
      form: {
        desc: "是否确认修改密码?",
        title: "提示",
      },
    },
    background: "背景图片来源",
    loginr: {
      name: "姓名",
      phone: "联系电话",
      company: "企业名称",
      email: "企业邮箱",
      code: "邮箱验证码",
      try: "开始试用",
      place1: "请输入企业邮箱",
      place2: "仅支持企业邮箱注册",
      place3: "请勾选协议",
    },
    WelcomeToJoin: "欢迎加入",
    WelcomeToYuntiTechnology: "欢迎来到云体科技",
  },

  addMyColla: {
    mySend: "我发出的邀请",
    myReceive: "我收到的邀请",
    allReviewRequest: "全部评审请求",
    fileType: "文件类型",
    need: "需求",
    archi: "架构",
    detailDesign: "详细设计",
    testcase: "测试用例",
    fileName: "文件名称",
    complete: "完成评审",
    creator: "创建者",
    time: "邀请时间",
    option: "操作",
    edit: "编辑",
    review: "评审",
    view: "查看",
    table: {
      title1: "评审任务标题",
      title2: "评审状态",
      title3: "发起人",
      title4: "截止时间",
      title5: "完成评审人数",
      title6: "未完成：",
      title7: "更多",
      title8: "再次发送提醒",
      title9: "提前结束评审",
      title10: "删除",
      title11: "筛选",
      title12: "全选",
      title13: "发起时间",
      title14: "未完成: ",
    },
    chart: {
      title1: "节点名称",
      title2: "责任人",
      title3: "节点评审次数",
      title4: "节点平均评审时长",
      title5: "文件类型",
      title6: "时长(天)",
    },
    message: {
      title1: "删除评审任务后，评审记录会一并删除， 确定要删除吗？",
      title2: "取消",
      title3: "已取消删除",
      title4: "提醒成功",
      title5: "结束成功",
    },
    status: {
      st1: "已完成",
      st2: "待评审",
      st3: "评审中",
      st4: "已结束",
      st5: "1次评审通过",
      st6: "2次评审通过",
      st7: "3次及以上评审通过",
      st8: "评审未通过",
      st9: "未评审",
    },
  },

  addTopBar: {
    excel: "excel模板",
  },

  addAuthentication: {
    index: "认证失败",
  },

  addEditCopy: {
    name: "副本名称",
    num: "请输入数字",
    name1: "请输入副本名称",
    node: "请输入节点名称",
    storyPoint: "故事点",
    nodeName: "节点名称",
    desc: "描述",
    limit: "故事点字段请输入正整数",
  },

  addTemplate: {
    h1: "管理模版",
    name: "模板名称",
    time: "创建时间",
    option: "操作",
    no: "不使用模板",
    message: "删除成功",
    error: "删除失败",
  },

  addModules: {
    alert: "系统检测到您已切换用户，请刷新页面后再操作",
    btn: "刷新",
  },

  addNum: "个",
  addNum2: "个",
  or: "或",
  year: "年",
  today: "今天",
  month: "月",
  quarter: "季度",
  yearMonth: "年一月",
  me: "我",
  addJs: {
    close: "永久关闭",
  },
  addAlert: {
    collapse: "收缩侧边栏",
  },
  certificate: {
    Prompt:
      "每推荐1位朋友使用，双方各增加10天免费试用时间。每添加1名用户进入租户，增加3天免费试用时间。",
    Copy: "复制下面链接，分享即可注册",
    Please: "请前往",
    CertificateManagement: "软件证书管理",
    InstallComponents: "安装组件",
    Recommend: "推荐",
    ClickInstall: "安装",
    Installed: "已安装",
    Expired: "已过期",
    PurchaseCertificate: "购买证书",
    BasicSoftware: "基础软件",
    CertificateType: "证书类型",
    MaximumNumberOfUsers: "最大固定用户数量",
    Used: "已使用",
    FloatMaximumNumberOfUsers: "最大浮动用户数量",
    ENTERPRISE_EDITION: "固定",
    FLOAT_EDITION: "浮动",
    MIXED_EDITION: "混合",
    EnterpriseEdition: "企业版",
    FreeEdition: "免费版",
    FloatEdition: "浮动制",
    ExpirationDate: "到期日",
    NoExpirationDate: "无期限",
    ClickToBuy: "点击购买",
    FeeBasedComponents: "收费组件",
    UpgradePrice: "升级价格",
    PublicCloudVersion: "公有云版本",
    Purchased: "已购买",
    Unpurchased: "未购买",
    Introduction: "介绍",
    UnpaidUsers: "点击安装后，可试用一个月",
    Preview: "预览",
    GanttDes:
      "甘特图作为项目管理的有效图表可视化工具，能进行高效的项目流程管控、明确团队项目阶段目标、对多节点项目进行统筹编排。针对敏捷研发类项目场景，甘特图为团队提供了具有顺畅工作体验的项目计划管理工具，以便于高效管理项目各个节点，并把控项目进程。",
    Price: process.env.NODE_ENV === "intl" ? "$/人月" : "￥/人月",
    PricePerSet: process.env.NODE_ENV === "intl" ? "$/套" : "￥/套",
    AccordingToTheModel: "根据模型确定",
    AutomatedWorkflow: "自动化工作流",
    AutomatedWorkflowDes:
      "自动化工作流根据业务规则设计和执行一系列重复的业务任务，以减少人际交互，提高项目运作效率。每项任务都是标准化的，因此减少了人工流程中的决策失误。也可以消除不必要的管理，工作流自动化可以在多节点项目中确保团队内任务转移及评估的高效完成。",
    VModelPanorama: "V模型全景图",
    WModelPanorama: "W模型全景图",
    VModelPanoramaDes:
      "V模型全景图用于展示不同节点类型之间的覆盖度，可以在一张图上展示一个项目中需求、架构、测试用例等的覆盖百分比，通过点击百分比，可以进行数据穿透，从而查看哪些节点未被覆盖，帮助用户建立更完整的关联追溯性， 并且支持在数据报表中以二维覆盖度报表进行展示，更方便查看节点的关联关系。",
    WModelPanoramaDes:
      "W模型全景图用于展示不同节点类型之间的覆盖度，可以在一张图上展示一个项目中需求、架构、测试用例等的覆盖百分比，通过点击百分比，可以进行数据穿透，从而查看哪些节点未被覆盖，帮助用户建立更完整的关联追溯性。",
    AIRobot: "AI机器人",
    AIRobotTip:
      "目前可选的大模型包括ChatGPT、出门问问，用户也可以提供自己的大模型接口。",
    AIRobotDes:
      "AI机器人用于帮助用户绘制架构图，创建需求和测试用例，还可对已经生成的需求和测试用例进行指定方向的优化，极大的提升了用户的工作效率，拓宽了思路。还可以在思维导图页面自动补充节点，帮助用户创建相关子项。",
    AutomatedTesting: "自动化测试",
    AutomatedTestingDes:
      "自动化测试脚本执行完成之后，自动将测试用例在MappingSpace中生成，并且自动创建测试计划，填写测试结果，生成测试报告。更详细的使用手册，请查看",
    KnowledgeBase: "知识库",
    KnowledgeBaseDes:
      "知识库是一个知识管理工具组件，通过知识库可以结构化地组织在线协作文档，促进知识的复用。同时还可以整合团队项目的知识网络，在软件项目内直接访问对应知识库，也可以在使用软件过程中调用知识库文档。",
    BasicSoftwarePurchaseTips: "基础软件购买提示",
    GanttPurchaseTips: "甘特图组件购买提示",
    AutomatedWorkflowPurchaseTips: "自动化工作流组件购买提示",
    KnowledgeBasePurchaseTips: "知识库组件购买提示",
    VModelPanoramaPurchaseTips: "V模型全景图组件购买提示",
    AIRoboticsPurchaseTips: "AI机器人组件购买提示",
    AutomationTestingPurchaseTips: "自动化测试组件购买提示",
    UnlocktheTtheVmodel: "解锁V模型全景图功能",
    UnlocktheTtheVmodelTips: "提示：V模型全景图为付费功能，请联系客服开通。",
    HARA: "HARA",
    HARADes:
      "HARA分析是功能安全领域中的一种重要分析方法，用于识别和评估潜在的危险和风险。HARA（Hazard Analysis and Risk Assessment）是指对系统、产品或服务中的危险进行分析和评估。通过HARA分析，可以提前发现和识别潜在的危险和风险，从而采取相应的措施来确保系统的功能安全性。这对于许多关键行业，如汽车、航空航天和医疗设备等领域来说，是非常重要的。",
    HARAPurchaseTips: "HARA组件购买提示",
    DFMEA: "DFMEA",
    DFMEADes:
      "DFMEA（Design Failure Mode and Effects Analysis）是功能安全分析中的一种方法，用于评估和减少设计过程中的潜在故障模式和对系统性能的影响。通过DFMEA，团队能够识别和评估设计过程中的潜在故障模式，并采取相应的措施来减少故障的发生和影响，从而提高系统的功能安全性。",
    DFMEAPurchaseTips: "DFMEA组件购买提示",
    FTA: "FTA",
    FTADesc:
      "故障树分析（英语：Fault tree analysis，简称 FTA）是由上往下的演绎式失效分析法。故障树分析主要用在安全工程以及可靠度工程的领域，用来了解系统失效的原因，并且找到最好的方式降低风险，或是确认某一安全事故或是特定系统失效的发生率。是ISO 26262功能安全中常用的工具之一。利用MappingSpace文件的思维导图结构，可以快速配置故障树，并进行相关计算。",
    TARADesc:
      "TARA是威胁分析与风险评估（Threat Analysis and Risk Assessment）的英文缩写。在ISO/SAE 21434标准中，它被视为网络安全分析的核心手段。运用TARA进行分析，可以识别出车辆可能面临的威胁和相应的风险等级，对于确定网络安全目标具有关键作用，同时也为制定网络安全需求提供了有力的指引。这将直接影响后续的开发活动。利用MappingSpace文件的思维导图结构，可以快速配置TARA进行分析。",
    TARAPurchaseTips: "TARA组件购买提示",
    FTAPurchaseTips: "FTA组件购买提示",
    FTAUnpaidUsers: "未付费用户，只能在汽车模板项目中的示例文件中使用该功能",
    ASPICETitle: "ASPICE项目模板",
    MappingGPT: "MappingGPT",
    MappingGPTPurchaseTips: "ASPICE组件购买提示",
    MGPTDesc:
      "MGPT是一款创新的AI生成产品代码和说明文档的工具，为开发者提供了一种全新的编程方式。无论您是初学者还是经验丰富的开发者，我们的AI助手都能帮助您快速生成高质量的代码和详细说明文档。通过使用我们的产品，您可以节省大量的时间和精力，提高开发效率。只需输入您的需求和要求，我们的AI助手就能自动生成相应的代码和文档，确保您的项目顺利进行。",
    ASPICEDesc:
      "ASPICE，全称是汽车软件过程改进和能力确定（Automotive Software Process Improvement and Capability Determination），是一个用于指导和评估汽车行业内嵌入式系统开发和管理过程能力的标准框架。该框架基于ISO/IEC 15504标准，专门为适应汽车行业的需求进行了定制。通过对工程和项目管理过程返回的数据进行成熟度评估，ASPICE 有助于汽车制造商和供应商在整个供应链中提升过程质量和性能。该模板中包含ASPICE项目所需项目配置及各类文档模板。",
    ASPICEPurchaseTips: "ASPICE组件购买提示",
    SecCTDesc:
      "SecCT是由伊世智能自主研发的信息安全合规测试工具。该工具符合UN/WP.29 R155、GDPR等国际法规，以及国内整车、零部件、数据安全等国家标准技术要求。SecCT通过将专业黑客的技术经验转化为标准化、流程化的自动化测试规范、测试用例和测试流程，帮助主机厂和零部件厂商构建信息安全测试能力，并提升专业测试技术水平。SecCT中的测试用例可与MappingSpace中的需求双向追溯，并在MappingSpace中展示更多安全合规测试结果。",
    SecCTPurchaseTips: "提示：SecCT为付费功能，请联系客服开通。",
    unSupportTrial: "该插件不支持试用。",
    shareCopyText: "我发现了一个很好用的ALM研发管理软件，一起来试试吧！",
    recommendedBenefit: "推荐奖励政策 ",
    third: "万能网页链接钥匙",
    thirdDes: `万能网页链接钥匙旨在无缝集成第三方网站链接于特定节点，实现信息的即时访问与便捷查看。此工具允许您将外部网页链接或HTML文档直接绑定至节点上，随后在节点详情页中无缝查看或跳转至这些资源，大大提升了操作的流畅度与效率。

我们广泛兼容各类HTML内容源，包括但不限于Figma和即时设计的分享页，MATLAB等专业工具生成的HTML文件，确保您的创意作品或科研资料无论源自何方，都能无缝融入您的项目体系与工作流程之中，为您的工作带来前所未有的高效与灵活。`,
    thirdPurchaseTips: "万能网页链接钥匙组件购买提示",
  },
  personalAside: {
    SoftwareCertificateManagement: "软件证书管理",
    checkPsw: "8位以上大小写字母、数字、特殊字符",
  },
  deletProject: {
    deletProjectBefore: "即将删除",
    deletProjectAfter: "项目，改操作将清除项目下的所有数据资源，该操作不可逆！",
    inputProjectName: "请手动输入当前待删除的项目名",
    deletProjectStep2:
      "项目删除操作至少还需要一个系统管理员同意，是否发起删除流程？",
    DoYouAgree: "是否同意删除",
    project: "项目",
    irreversible: "该操作将清除项目下所有数据资源，该操作不可逆！",
    Agree: "同意",
    Deny: "拒绝",
    ProjectDeletionProcessRejected: "该项目删除流程已被拒绝，无需再进行审核",
    ProjectHasBeenDeleted: "该项目已被删除，无需再进行审核",
  },
  Jira: {
    desBefore: "您还未绑定任何Jira Cloud账号，若要使用该应用，请点击",
    desMiddle: "此链接",
    desAfter: "完成认证操作",
    redesBefore: "您已经完成Jira Cloud账号绑定，若要重新认证，请点击",
    redesMiddle: "此链接",
    redesAfter: "进行",
    compDes: "您已完成Jira Cloud的认证操作，快去使用该应用吧！",
  },
  Ones: {
    desBefore: '您已经完成Ones配置, 如果需要重新配置请点击',
    desMiddle: "此处",
  },
  thirdPartyDetail: {
    des: "请在进入具体项目后，前往 “设置>关于项目 进行配置！",
    click: "点击",
    this: "此处",
    config: "查看如何配置",
    noAccess: "没有操作权限，请联系系统管理员！",
  },
  moveFile: {
    sureToMove: "确定移动吗？",
  },
  globleMixin: {
    ExportCompleted: "导出完成",
    ExportFailed: "导出失败",
  },
  myMind: {
    CurrentFileIsDeleted: "当前文件已删除",
  },
  workSpace: {
    UpcomingExpirationAlert: "即将到期提示",
    enterOfficalWeb: "进入官网",
    WeChatContent: "欢迎关注“云体科技”公众号，了解更多产品一手信息！",
    welComeTitle1: "亲爱的",
    welComeTitle2: "，欢迎来到MappingSpace！",
    welComeContent: "接下来，我们将为您介绍一位得力助手。",
    fun1Title: "满足ASPICE标准的追溯性",
    fun1Content:
      "基于ASPICE 3.1 V模型，或者ASPICE 4.0 W模型，随时查看研发任务的追溯性。",
    fun1Gif: `${config.oss_url}/Frontend/gif/1_cn.gif`,
    fun2Title: "一键创建满足标准的研发过程文档",
    fun2Content: "内置海量模板，也可自定义企业级模板，文档编写效率提升50%。",
    fun2Gif: `${config.oss_url}/Frontend/gif/2_cn.gif`,
    fun3Title: "结构清晰的测试用例管理",
    fun3Content:
      "基于思维导图创建测试用例，清晰查看测试步骤，批量关联待测需求，执行测试计划，自动生成测试报告。",
    fun3Gif: `${config.oss_url}/Frontend/gif/3_cn.gif`,
    fun4Title: "在线需求评审",
    fun4Content:
      "批量邀请评审，定制评审条目，在线执行评审，查看评审结果，自动跟踪评审问题点直至关闭。",
    fun4Gif: `${config.oss_url}/Frontend/gif/4_cn.gif`,
    fun5Title: "基线与变更评审",
    fun5Content:
      "创建基线，添加节点至基线，开始基线即冻结需求，提交变更评审需求，基线与变更流程有机结合。",
    fun5Gif: `${config.oss_url}/Frontend/gif/5_cn.gif`,
    fun6Title: "功能安全与信息安全流程",
    fun6Content:
      "进入“汽车项目模板”中的示例文件，视图-安全，体验功能安全的独特模块，HARA、DFMEA、FTA、TARA，安全尽在掌握。",
    fun6Gif: `${config.oss_url}/Frontend/gif/6_cn.gif`,
    fun7Title: "AI研发加速器",
    fun7Content:
      "点击右下角，唤出AI机器人，体验业界最新的AI生成技术，知识库中也可以使用AI功能哦。",
    fun7Gif: `${config.oss_url}/Frontend/gif/7_cn.gif`,
    fun8Title: "线下文件快速导入",
    fun8Content:
      "点击新建，批量导入，快速导入线下Word、Excel文档。温馨提示：导入Excel时，若找不到需要的字段，可在项目中自由配置。",
    fun8Gif: `${config.oss_url}/Frontend/gif/8_cn.gif`,
    fun9Title: "功能强大的插件",
    fun9Content:
      "点击右上角头像，进入软件证书管理，挑选心仪的付费组件，点击安装，体验不同插件的独特魅力。",
    fun9Gif: `${config.oss_url}/Frontend/gif/9_cn.gif`,
    fun10Title: "团队协作",
    fun10Content:
      "点击右上角头像，进入用户管理，邀请你的小伙伴一起，在MappingSpace中高效协作。",
    fun10Gif: `${config.oss_url}/Frontend/gif/10_cn.gif`,
    fun11Title: "推广大使",
    fun11Content:
      "好东西当然是要分享啦，快将这个软件分享给需要的人吧，我们也给您准备了推广大使专享礼品。",
    fun11Gif: `${config.oss_url}/Frontend/gif/11_cn.gif`,
    update: {
      pic1: `${config.oss_url}/Frontend/update/1-zh.png`,
      pic2: `${config.oss_url}/Frontend/update/2-zh.gif`,
      pic3: `${config.oss_url}/Frontend/update/3-zh.gif`,
      pic4: `${config.oss_url}/Frontend/update/4-zh.gif`,
      pic5: `${config.oss_url}/Frontend/update/5-zh.gif`,
      pic6: `${config.oss_url}/Frontend/update/6-zh.gif`,
      pic7: `${config.oss_url}/Frontend/update/7-zh.png`,
      title1: '二维覆盖度报表',
      des1: '可基于需求-架构-详细设计-测试用例的追溯关系，实时展示二维追溯性报表，满足ASPICE审查要求',
      title2: '万能网页展示器',
      des2: '新增Matlab/Simulink、PLM、Figma、即时设计等多种软件的打通，可在节点详情页面，实时查看其他软件中的动态',
      title3: '模型视图',
      des3: '一半树形结构，一半模型视图，并可将SysML模型元素与架构直接关联，建立架构任务与SysML的精细化追溯',
      title4: '树形视图',
      des4: '以树形结构，查看节点详情页面，进一步拓宽Word视图的边界',
      title5: '高级搜索',
      des5: '针对节点查询，支持且、或、非、包含等元素的组合查询，大幅提高查询效率',
      title6: '复用节点同步',
      des6: '针对需求、架构等节点被复用到其他项目的场景，增加节点同步功能，无论复用后如何修改，都可随时让复用需求和原需求重新保持一致',
      title7: '复用图谱',
      des7: '新增需求复用图谱功能，通过可视化的图形页面，实时查看需求被复用的次数，以及复用后的修改情况',
      newVersion: '全新版本发布',
      newVersionDes: '更高效、更直观的系统功能, 全面提升您的工作效率和操作体验',
      seeIntroduction: '查看新版本介绍',
      share: '分享'
    }
  },
  dataChart: {
    PleaseEnterReportName: "请输入报表名称",
    XAxisFields: "X轴字段",
    YAxisFields: "Y轴字段",
    Quantity: "数量",
  },
  switch: {
    PutAway: "收起",
    Expand: "展开",
  },
  chartTop: {
    sureDelete: "确定删除吗？删除后不可恢复",
    noPermission: "您没有查看该报表权限",
  },
  baseChart: {
    selectCorrectTimeRange: "请选择正确的时间范围",
  },
  vmodelChart: {
    node: "节点",
    is: "的",
    notAssociated: "未关联",
  },
  knowledge: {
    knowledge_ai: {
      title1: "AI智能编写",
      title2: "AI高级智能编写",
      title3: "请再次完善您的提示词",
      title4: "上一步AI的回答",
      placeholder1: "请输入内容",
      placeholder2: "请选择操作",
      placeholder3: "可以修改提示词再次生成图片",
      placeholder4: "选填",
      placeholder5: "必填",
      code_lang: "代码语言",
      btn1: "再次生成",
      btn2: "不满意",
      btn3: "满意",
      bumanyi_form1: "作为一名",
      bumanyi_form2: "我希望对上述内容进行",
      bumanyi_form3: "以达成",
      bumanyi_form4: "目的",
      bumanyi_form5: "除此之外，我还有一项要求：",
      message1: "请选择操作类型",
      message2: "请输入代码语言",
      message3: "请填写必填项",
      op1: "扩展",
      op2: "润色",
      op3: "绘图",
      op4: "概括",
      op5: "编写用例",
      op6: "生成代码",
      op7: "生成架构图",
    },
    delete_at: "@您的消息已被删除",
    user: "用户",
    userGroup: "用户组",
    NodeunlockedexpiredTip: "该节点已解除锁定，变更请求已失效",
    ArticleOutline: "文章大纲",
    NoPagesSelected: "未选中任何页面",
    SorryNoPermission: "抱歉，您暂无权限查看此文章内容",
    RequestPermission: "申请查看权限",
    ViewAccessRequested: "已申请查看权限，请稍后重试",
    PleaseEnterContent: "请输入内容",
    noPermission: "无权限",
    KnowledgeBaseRecycleBin: "知识库回收站",
    UnlockKnowledgeBaseFeatures: "购买提示",
    KnowledgeBasePaidFeature: "提示：知识库为付费功能，请联系客服开通。",
    TheCurrentKnowledgeBaseChanged: "当前知识库目录发生变化，请单击",
    SomeFeaturesDisabledUntilThen: "刷新前部分功能会被禁用",
    NewArticle: "新文章",
    Loading: "加载中",
    EnteringKnowledgeBase: "正在进入知识库",
    SourceHanSans: "思源黑体",
    MicrosoftYaHei: "微软雅黑",
    SimSun: "宋体",
    SimHei: "黑体",
    KaiTi: "楷体",
    FangSong: "仿宋",
    lineSpacing: "倍行距",
    body: "正文",
    Heading1: "一级标题",
    Heading2: "二级标题",
    Heading3: "三级标题",
    Heading4: "四级标题",
    Heading5: "五级标题",
    Heading6: "六级标题",
    Undo: "回退",
    Redo: "前进",
    Format: "格式刷",
    Bold: "加粗",
    Italic: "斜体",
    Underline: "下划线",
    ParagraphFormat: "段落格式",
    Strikethrough: "删除线",
    Blockquote: "块引用",
    InsertCode: "插入代码",
    InsertCodeBlock: "插入代码段",
    Font: "字体",
    FontSize: "字体大小",
    NumberedList: "编号列表",
    BulletedList: "项目列表",
    TextDirectio: "文本方向",
    Alignment: "对齐方式",
    FontColor: "字体颜色",
    BackgroundColor: "背景颜色",
    Image: "图像",
    Video: "视频",
    AddLink: "添加链接",
    InsertFormula: "插入公式",
    ClearFontFormatting: "清除字体格式",
    InsertTable: "插入表格",
    Subscript: "下标",
    Superscript: "上标",
    DecreaseIndent: "向左缩进",
    IncreaseIndent: "向右缩进",
    HeadingSize: "标题大小",
    AlignLeft: "居左对齐",
    AlignRight: "居右对齐",
    AlignCenter: "居中对齐",
    Justify: "两端对齐",
    HighlightBlock: "高亮",
    PleaseConfirmTheLocation: "请确定要插入表格的位置",
    Creator: "创建人",
    LatestVersion: "最新版本",
    Exit: "退出",
    articleTitle: "请填写文章标题",
    sureExit: "当前页面有图片未上传完毕，退出有可能造成图片丢失，确定退出吗？",
    GenerateSharingLink: "生成分享链接",
    tooLarge: "文件过大，无法上传",
    InsertOperation: "插入操作",
    InsertRight: "右侧插入列",
    InsertLeft: "左侧插入列",
    InsertAbove: "向上插入行",
    InsertBelow: "向下插入行",
    MergeCells: "合并单元格",
    SplitCells: "拆分单元格",
    DeleteColumn: "删除列",
    DeleteRow: "删除行",
    DeleteTable: "删除表格",
    SelectText: "请先选择文字",
    LastEditedTime: "最后编辑时间",
    CommentSection: "评论区",
    NoComment: "暂无评论",
    CommentNotEmpty: "评论不能为空",
    WordExtractComment: "取词评论",
    Comment: "评 论",
    WordExtractCommentSection: "取词评论区",
    Abandoned: "废弃",
    Reply: "回复",
    RightClickInfo: "右键段落或者文本可进行链接分享，取词评论等操作",
    QuestionOverview: "问题总览",
    OneClickConversion: "一键转化思维导图",
    ViewMindMap: "查看思维导图",
    Deleted: "已删除",
    CreateMindMap: "新建思维导图",
    Unfavorite: "取消收藏",
    Favorite: "收藏",
    QuickShare: "快捷分享",
    CopyExternalLink: "复制外部链接",
    TransferOwnership: "转移所有权",
    OverwriteMindMap: "覆盖原思维导图",
    DeleteExceptRoot: "原思维导图除根节点外，其他节点均会被删除。",
    LinkArticleHeadings: "文章各级标题会与新生成的节点建立联系。",
    PreserveOriginalMap: "保留原思维导图",
    PreserveRemoveLink: "原思维导图会被保留，但与文章所有的联系会被移除。",
    LinkNewMindMap: "文章将与新建的思维导图建立联系。",
    ShareWithinTenant: "分享文章给租户内其他用户查看",
    ShareWithUsers: "分享给用户",
    QuickShareNote: "注意：快捷分享有效时间为24小时",
    ConfirmSharing: "确认分享",
    Permissions: "权限",
    PageOwner: "页面所有人",
    Private: "私密",
    InternallyPublic: "内部公开",
    InternalPermissionSettings: "内部权限设置",
    Add: "添加",
    SelectAtLeastOneUser: "请至少选择一位用户",
    InternetPermissionSettings: "互联网权限设置",
    InternetUsersCanView: "互联网用户可查看",
    InternetUsersCanEdit: "互联网用户可编辑",
    SyncSubpagePermissions: "一键同步子页面权限",
    ExternalLink: "外部链接",
    CopyText: "【MappingSpace】我创建了一个页面给你，快来看看吧！ ",
    Copy: "复制",
    Move: "移动",
    SelectUsers: "选择用户",
    ConfirmTransfer: "确认转移",
    OverviewOfQuestions: "问题总览",
    SelectFileType: "请选择要转化的文件类型",
    PermissionInconsistencyWarning:
      "当前页面权限与父级权限不一致，是否确认修改？",
    SelectTargetUser: "请选择目标用户",
    TransferSuccessful: "转移成功",
    ConfirmOverwrite: "确定要覆盖吗？",
    DeleteNodesWarning: "原思维导图除根节点外，其他节点均会被删除",
    Overwrite: "覆盖",
    CancelOverwrite: "取消覆盖",
    RefreshSuccessful: "刷新成功",
    RefreshFailed: "刷新失败",
    ConversionSuccessful: "转化成功",
    GoToMapPage: "点击查看导图前往导图页面",
    ReturnToKnowledgeBase: "返回知识库",
    TransformToMindMap:
      "您的页面内容将根据目录标题转化为思维导图，转化成功后您可以在MappingSpace中查看",
    TransformingIntoMindMap: "正在转化为思维导图……",
    BookmarkSuccessful: "收藏成功",
    ExportSuccessful: "导出成功",
    ConfirmPageDeletion1: "确定删除该页面吗？",
    ConfirmPageDeletion2: "子页面会被一起删除，",
    ConfirmPageDeletion3: "删除后，可以在知识库回收站恢复",
    Restore: "恢复",
    PermanentlyDelete: "彻底删除",
    Title: "标题",
    Operator: "操作人",
    DeletionTime: "删除时间",
    SelectAtLeastOnePage: "请至少选中一个页面",
    ConfirmPermanentDeletion:
      "确定彻底删除选中页面吗？删除后，该页面将永久消失",
    internalSharingLinks: "生成内部分享链接",
    externalSharingLinks: "生成外部分享链接",
    OneClickToMindMap: "一键转思维导图",
    PermissionSetting: "权限设置",
    Unfollow: "取消关注",
    Follow: "关注",
    ViewHistoricalVersions: "查看历史版本",
    RegenerateMindMap: "重新生成思维导图",
    regenerateNewOne: "原思维导图已被删除，重新生成新的思维导图。",
    MoveAcrossProjectPages: "跨项目页面移动",
    TargetProject: "目标项目",
    TargetParentPage: "目标父级页面",
    includeChildren: "子页面一起移动",
    includeChildren2: "子页面一起复制",
    RootDirectory: "根目录",
    KeepOriginalPage: "保留原页面",
    SameProjectPageCopy: "同项目页面复制",
    PleaseSelect: "请选择",
    TargetItemCannotBeEmpty: "目标项目不能为空",
    SharingSucceeded: "分享成功",
    FileTypeCannotBeEmpty: "文件类型不能为空",
    Exporting: "正在导出中",
    DeletionCanceled: "已取消删除",
    DeletingPageSucceeded: "删除页面成功",
    MovingAPage: "移动页面仅会移动标题和页面内容， 确认不保留原页面？",
    wanning: "警告",
    PageCopySucceeded: "页面复制成功",
    PageMovedSuccessfully: "页面移动成功",
    HistoricalVersions: "历史版本",
    VersionComparison: "版本对比",
    CurrentVersion: "当前版本",
    LastEditedBy: "最后编辑人",
    AddedContent: "新增内容",
    DeletedContent: "已删除内容",
    RestoreThisPage: "恢复此页面",
    JumpMindMap: "跳转思维导图",
    JumpToKnowledgeBase: "跳转知识库",
    InternetReadable: "互联网可查看",
    InternetEditable: "互联网可编辑",
    ArticleAuth: "文章权限查阅",
    copied: "复制成功",
    copiedLink: "已复制链接",
    pleaseFocus: "请聚焦编辑器确定插入位置",
    template: "模板",
    saveAsTemplate: "另存为模板",
    userNum: "人使用",
    evaluate: "评价",
    myCreations: "我的模板",
    myCollections: "收藏",
    myPurchase: "已购买",
    teamTemplate: "团队模板",
    PROJECT_MANAGEMENT: "项目管理",
    R_D_DESIGN: "研发管理",
    PRODUCT_TEST: "产品测评",
    BUSINESS_ANALYSE: "商业分析",
    FINANCIAL_MANAGEMENT: "财务管理",
    HUMAN_RESOURCES: "人力资源",
    OTHERS: "其它",
    more: "更多",
    p1: "暂无评价,请添加",
    p2: "该用户未填写评价",
    p3: "请添加详细评价",
    p4: "评分不能为空",
    degree1: "非常差",
    degree2: "差",
    degree3: "一般",
    degree4: "好",
    degree5: "非常好",
    vrate: "评分",
    deleteTemplateInfo: "是否确认删除该模板?",
  },
  mindmap: {
    search: "请输入关键词",
    ChangeImpactAnalysis: "变更影响分析",
    ChangeImpactScope: "变更影响范围",
    EditAgain: "再次编辑",
    PleaseEnterChangeImpactAnalysis: "请输入变更影响分析",
    PleaseSelectReviewers: "请选择评审人员",
    CurrentNodeDeleted: "当前节点已删除",
    SpecifySprint: "指定Sprint",
    SpecifyGanttChart: "指定甘特图",
    NodeRemovedFromGantt: "节点已成功从甘特图移除",
    MoveNodeToGanttSuccess: "成功移动节点到甘特图",
    AddNodeToGanttSuccess: "成功添加节点到甘特图",
    NodeExistInGantt: "已经存在甘特图中，不能重复添加",
    ConfirmMoveNode1: "确定将该节点移动到",
    ConfirmMoveNode2: "节点下吗？",
    ConfirmMoveNode3:
      "移动后，当前文件将不再含有该节点及其子节点。若该节点状态，在目标文件中不存在，则自动转为对应层级的初始状态。",
    JoinKanban: "加入Kanban",
    RemovefromKanban: "移出Kanban",
  },
  pubKnowledge: {
    RaiseQuestion: "提出问题",
    SubmitQuestion: "提交问题",
    QuestionDescription: "问题描述",
    PhoneNumber: "手机号",
    Email: "邮箱",
    RequireResponseContact: "如需回复请填写联系方式",
    Submit: "提交",
    FillQuestionDescription: "请填写问题描述",
    QuestionSubmittedSuccessfully: "提问成功",
    registerTip: "注册MappingSpace，解锁更多功能。",
    Unsubscribe: "取消通知",
    subscribe: "页面变更通知",
    subscribeSuccessful: "订阅成功",
    UnsubscribeSuccessful: "取消订阅成功",
    checkEmail: "请输入正确的邮箱账号。",
    inputEamil: "请输入邮箱账号。",
  },
  quick: {
    FilterWeekends: "过滤周末",
    CancelFilter: "取消过滤",
    Total: "总计",
    AddedSuccessfully: "添加成功",
    BurnDownChart: "燃尽曲线",
    IdealBurnDownChart: "理想燃尽曲线",
    SprintDataSummary: "敏捷看板数据汇总",
    nodeStatus: "节点状态",
    Joinsuccessfully: "加入成功",
    Removalsuccessful: "移出成功",
  },
  release: {
    IncompleteNodesCurrentVersion: "当前版本中存为未完成节点，确定要发布吗？",
    ConfirmOperation: "操作确认",
  },
  corax: {
    StaticCodeScanPieChart: "静态代码扫描饼状图",
    LinesOfCode: "代码行数",
    NumberOfFiles: "文件数",
    ScanningCriteria: "扫描标准",
    ScanningTime: "扫描用时",
    PlatformLinks: "平台链接",
    DefectDetails: "缺陷详情",
    GlobalNumber: "全局编号",
    BugNumber: "缺陷编号",
    DefectName: "缺陷名",
    DefectDescription: "缺陷描述",
    DefectLocation: "缺陷位置",
    Severity: "严重程度",
    StaticCodeScanReport: "静态代码扫描报告（Corax）",
    coraxConfiguration: "corax配置",
    ViewCoraxReport: "查看Corax报告",
    CoraxReports: "Corax报告",
    ScanningTasks: "扫描任务",
    DefectDistribution: "缺陷分布",
    High: "高",
    Medium: "中",
    Low: "低",
    Note: "注",
    LastUpdated: "最后一次更新时间",
    ReportingChart: "汇报图表",
    confirmDeletion:
      "删除测试计划后，不可再恢复，但不影响已发现的bug。确认删除吗？",
    introducedBySprint:
      "该节点所在版本由Sprint引入，无法删除，请前往Sprint中移除该节点",
  },
  // 富文本编辑器
  editor: {
    paragraph: "zh-Hans",
  },
  DFMEA: {
    ReceivedTasks: "我收到的任务",
    MyAssignedTasks: "我指派的任务",
    OptimizationSteps: "全部优化措施",
    ImprovedPrevention: "优化后的预防措施",
    CompletionDate: "目标完成日期",
    Priority: "措施优先级",
    Status: "状态",
    Assignee: "责任人",
    Initiator: "发起人",
    More: "更多",
    Reminder: "再次发送提醒",
    Completed: "已完成",
    NotYetDecided: "尚未决策",
    NotYetExecuted: "尚未执行",
    NotYetDetermined: "尚未确定",
    NoExecute: "不执行",
    AssociatedTasks: "关联任务",
    ms1: "购买提示",
    ms2: "提示：DFMEA为付费功能，请联系客服开通。",
    ms3: "再次发送提醒成功。",
    ms4: "DFMEA视图下禁止移动节点",
  },
  // 模板库
  templateLibrary: {
    SuccessfullySaved: "另存为模板成功",
    tips: "提示",
    warning1: "当前模版删除后无法恢复，是否确认删除该模版？",
    templateLibrary: "模板库",
    searchTemplate: "搜索模板",
    creator: "创建人",
    used: " 人已使用",
    preview: "预览",
    use: "使用",
    myCreated: "我创建的",
    myfavorites: "我收藏的",
    customTemplate: "团队模板",
    projectManagement: "项目管理",
    designing: "研发设计",
    resource: "资源管理",
    supply: "供应链管理",
    communication: "沟通与协作",
    sales: "销售管理",
    other: "其他",
    previous: "上一个",
    next: "下一个",
    useThisTemplate: "使用该模板",
    details: "详情",
    comments: "评价",
    nocomments: "暂无评价，请添加",
    description: "描述",
    addcomment: "请添加详细评价",
    score: "请输入评分",
    comments1: "评价",
    vrate: "评分",
    grade: "分",
    p1: "暂无描述",
    allTags: "所有标签",
    tagName: "标签名",
    addTags: "新增标签",
    tag: "相关标签",
    Visibility: "可见范围",
    PleaseEnterTemplateDescription: "请输入模板描述",
    PleaseEnterTheTagName: "请输入标签名",
    tagTip1: "请前往模板库 => 团队模板, 管理标签",
  },
  wordTemplate: "https://automind-oss.ytdevops.com/static/Word导入模板.docx",
  excelTemplate: "https://automind-oss.ytdevops.com/static/Excel导入模板.xlsx",
  ganttExcelTemplate:
    "https://automind-oss.ytdevops.com/static/甘特图Excel导入模板.xlsx",
  importUserTemplate:
    "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/static/%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E7%94%A8%E6%88%B7%E6%A8%A1%E6%9D%BF%20%281%29.xlsx",
  UserGuide:
    "https://ms.ytdevops.com/pubKnowledge/1db7562f-6f55-4d30-907c-390163fe43b8",
  openApiGuide:
    "https://ms.ytdevops.com/pubKnowledge/815d96c6-78b6-4e78-8813-00bf79473f07",
  countryPhoneList: [
    {
      label: "+86   中国",
      value: "中国",
    },
    {
      label: "+886   中国台湾",
      value: "中国台湾",
    },
    {
      label: "+670   东帝汶民主共和国",
      value: "东帝汶民主共和国",
    },
    {
      label: "+236   中非共和国",
      value: "中非共和国",
    },
    {
      label: "+45   丹麦",
      value: "丹麦",
    },
    {
      label: "+380   乌克兰",
      value: "乌克兰",
    },
    {
      label: "+998   乌兹别克斯坦",
      value: "乌兹别克斯坦",
    },
    {
      label: "+256   乌干达",
      value: "乌干达",
    },
    {
      label: "+598   乌拉圭",
      value: "乌拉圭",
    },
    {
      label: "+235   乍得",
      value: "乍得",
    },
    {
      label: "+967   也门",
      value: "也门",
    },
    {
      label: "+374   亚美尼亚",
      value: "亚美尼亚",
    },
    {
      label: "+972   以色列",
      value: "以色列",
    },
    {
      label: "+964   伊拉克",
      value: "伊拉克",
    },
    {
      label: "+98   伊朗",
      value: "伊朗",
    },
    {
      label: "+501   伯利兹",
      value: "伯利兹",
    },
    {
      label: "+238   佛得角",
      value: "佛得角",
    },
    {
      label: "+7   俄罗斯",
      value: "俄罗斯",
    },
    {
      label: "+359   保加利亚",
      value: "保加利亚",
    },
    {
      label: "+385   克罗地亚",
      value: "克罗地亚",
    },
    {
      label: "+1671   关岛",
      value: "关岛",
    },
    {
      label: "+220   冈比亚",
      value: "冈比亚",
    },
    {
      label: "+354   冰岛",
      value: "冰岛",
    },
    {
      label: "+224   几内亚",
      value: "几内亚",
    },
    {
      label: "+245   几内亚比绍",
      value: "几内亚比绍",
    },
    {
      label: "+423   列支敦士登",
      value: "列支敦士登",
    },
    {
      label: "+242   刚果共和国",
      value: "刚果共和国",
    },
    {
      label: "+243   刚果民主共和国",
      value: "刚果民主共和国",
    },
    {
      label: "+218   利比亚",
      value: "利比亚",
    },
    {
      label: "+231   利比里亚",
      value: "利比里亚",
    },
    {
      label: "+1   加拿大",
      value: "加拿大",
    },
    {
      label: "+233   加纳",
      value: "加纳",
    },
    {
      label: "+241   加蓬",
      value: "加蓬",
    },
    {
      label: "+36   匈牙利",
      value: "匈牙利",
    },
    {
      label: "+27   南非",
      value: "南非",
    },
    {
      label: "+267   博茨瓦纳",
      value: "博茨瓦纳",
    },
    {
      label: "+974   卡塔尔",
      value: "卡塔尔",
    },
    {
      label: "+250   卢旺达",
      value: "卢旺达",
    },
    {
      label: "+352   卢森堡",
      value: "卢森堡",
    },
    {
      label: "+62   印尼",
      value: "印尼",
    },
    {
      label: "+91   印度",
      value: "印度",
    },
    {
      label: "+502   危地马拉",
      value: "危地马拉",
    },
    {
      label: "+593   厄瓜多尔",
      value: "厄瓜多尔",
    },
    {
      label: "+291   厄立特里亚",
      value: "厄立特里亚",
    },
    {
      label: "+963   叙利亚",
      value: "叙利亚",
    },
    {
      label: "+53   古巴",
      value: "古巴",
    },
    {
      label: "+996   吉尔吉斯斯坦",
      value: "吉尔吉斯斯坦",
    },
    {
      label: "+253   吉布提",
      value: "吉布提",
    },
    {
      label: "+57   哥伦比亚",
      value: "哥伦比亚",
    },
    {
      label: "+506   哥斯达黎加",
      value: "哥斯达黎加",
    },
    {
      label: "+237   喀麦隆",
      value: "喀麦隆",
    },
    {
      label: "+688   图瓦卢",
      value: "图瓦卢",
    },
    {
      label: "+993   土库曼斯坦",
      value: "土库曼斯坦",
    },
    {
      label: "+90   土耳其",
      value: "土耳其",
    },
    {
      label: "+1758   圣卢西亚",
      value: "圣卢西亚",
    },
    {
      label: "+1869   圣基茨和尼维斯",
      value: "圣基茨和尼维斯",
    },
    {
      label: "+239   圣多美和普林西比",
      value: "圣多美和普林西比",
    },
    {
      label: "+1784   圣文森特和格林纳丁斯",
      value: "圣文森特和格林纳丁斯",
    },
    {
      label: "+508   圣皮埃尔和密克隆群岛",
      value: "圣皮埃尔和密克隆群岛",
    },
    {
      label: "+290   圣赫勒拿岛",
      value: "圣赫勒拿岛",
    },
    {
      label: "+378   圣马力诺",
      value: "圣马力诺",
    },
    {
      label: "+592   圭亚那",
      value: "圭亚那",
    },
    {
      label: "+255   坦桑尼亚",
      value: "坦桑尼亚",
    },
    {
      label: "+20   埃及",
      value: "埃及",
    },
    {
      label: "+251   埃塞俄比亚",
      value: "埃塞俄比亚",
    },
    {
      label: "+686   基里巴斯",
      value: "基里巴斯",
    },
    {
      label: "+992   塔吉克斯坦",
      value: "塔吉克斯坦",
    },
    {
      label: "+221   塞内加尔",
      value: "塞内加尔",
    },
    {
      label: "+381   塞尔维亚",
      value: "塞尔维亚",
    },
    {
      label: "+232   塞拉利昂",
      value: "塞拉利昂",
    },
    {
      label: "+357   塞浦路斯",
      value: "塞浦路斯",
    },
    {
      label: "+248   塞舌尔",
      value: "塞舌尔",
    },
    {
      label: "+52   墨西哥",
      value: "墨西哥",
    },
    {
      label: "+228   多哥",
      value: "多哥",
    },
    {
      label: "+1767   多米尼克",
      value: "多米尼克",
    },
    {
      label: "+43   奥地利",
      value: "奥地利",
    },
    {
      label: "+58   委内瑞拉",
      value: "委内瑞拉",
    },
    {
      label: "+880   孟加拉",
      value: "孟加拉",
    },
    {
      label: "+244   安哥拉",
      value: "安哥拉",
    },
    {
      label: "+1264   安圭拉岛",
      value: "安圭拉岛",
    },
    {
      label: "+376   安道尔",
      value: "安道尔",
    },
    {
      label: "+691   密克罗尼西亚",
      value: "密克罗尼西亚",
    },
    {
      label: "+505   尼加拉瓜",
      value: "尼加拉瓜",
    },
    {
      label: "+234   尼日利亚",
      value: "尼日利亚",
    },
    {
      label: "+227   尼日尔",
      value: "尼日尔",
    },
    {
      label: "+977   尼泊尔",
      value: "尼泊尔",
    },
    {
      label: "+970   巴勒斯坦",
      value: "巴勒斯坦",
    },
    {
      label: "+1242   巴哈马",
      value: "巴哈马",
    },
    {
      label: "+92   巴基斯坦",
      value: "巴基斯坦",
    },
    {
      label: "+1246   巴巴多斯",
      value: "巴巴多斯",
    },
    {
      label: "+675   巴布亚新几内亚",
      value: "巴布亚新几内亚",
    },
    {
      label: "+595   巴拉圭",
      value: "巴拉圭",
    },
    {
      label: "+507   巴拿马",
      value: "巴拿马",
    },
    {
      label: "+973   巴林",
      value: "巴林",
    },
    {
      label: "+55   巴西",
      value: "巴西",
    },
    {
      label: "+226   布基纳法索",
      value: "布基纳法索",
    },
    {
      label: "+257   布隆迪",
      value: "布隆迪",
    },
    {
      label: "+30   希腊",
      value: "希腊",
    },
    {
      label: "+680   帕劳",
      value: "帕劳",
    },
    {
      label: "+682   库克群岛",
      value: "库克群岛",
    },
    {
      label: "+1345   开曼群岛",
      value: "开曼群岛",
    },
    {
      label: "+49   德国",
      value: "德国",
    },
    {
      label: "+39   意大利",
      value: "意大利",
    },
    {
      label: "+677   所罗门群岛",
      value: "所罗门群岛",
    },
    {
      label: "+690   托克劳",
      value: "托克劳",
    },
    {
      label: "+371   拉脱维亚",
      value: "拉脱维亚",
    },
    {
      label: "+47   挪威",
      value: "挪威",
    },
    {
      label: "+420   捷克共和国",
      value: "捷克共和国",
    },
    {
      label: "+373   摩尔多瓦",
      value: "摩尔多瓦",
    },
    {
      label: "+212   摩洛哥",
      value: "摩洛哥",
    },
    {
      label: "+377   摩纳哥",
      value: "摩纳哥",
    },
    {
      label: "+673   文莱",
      value: "文莱",
    },
    {
      label: "+679   斐济",
      value: "斐济",
    },
    {
      label: "+268   斯威士兰王国",
      value: "斯威士兰王国",
    },
    {
      label: "+421   斯洛伐克",
      value: "斯洛伐克",
    },
    {
      label: "+386   斯洛文尼亚",
      value: "斯洛文尼亚",
    },
    {
      label: "+94   斯里兰卡",
      value: "斯里兰卡",
    },
    {
      label: "+65   新加坡",
      value: "新加坡",
    },
    {
      label: "+687   新喀里多尼亚",
      value: "新喀里多尼亚",
    },
    {
      label: "+64   新西兰",
      value: "新西兰",
    },
    {
      label: "+81   日本",
      value: "日本",
    },
    {
      label: "+56   智利",
      value: "智利",
    },
    {
      label: "+850   朝鲜",
      value: "朝鲜",
    },
    {
      label: "+855   柬埔寨",
      value: "柬埔寨",
    },
    {
      label: "+1473   格林纳达",
      value: "格林纳达",
    },
    {
      label: "+299   格陵兰",
      value: "格陵兰",
    },
    {
      label: "+995   格鲁吉亚",
      value: "格鲁吉亚",
    },
    {
      label: "+32   比利时",
      value: "比利时",
    },
    {
      label: "+222   毛里塔尼亚",
      value: "毛里塔尼亚",
    },
    {
      label: "+230   毛里求斯",
      value: "毛里求斯",
    },
    {
      label: "+676   汤加",
      value: "汤加",
    },
    {
      label: "+966   沙特阿拉伯",
      value: "沙特阿拉伯",
    },
    {
      label: "+33   法国",
      value: "法国",
    },
    {
      label: "+594   法属圭亚那",
      value: "法属圭亚那",
    },
    {
      label: "+689   法属波利尼西亚",
      value: "法属波利尼西亚",
    },
    {
      label: "+596   法属西印度群岛",
      value: "法属西印度群岛",
    },
    {
      label: "+298   法罗群岛",
      value: "法罗群岛",
    },
    {
      label: "+48   波兰",
      value: "波兰",
    },
    {
      label: "+1787   波多黎各",
      value: "波多黎各",
    },
    {
      label: "+387   波黑",
      value: "波黑",
    },
    {
      label: "+66   泰国",
      value: "泰国",
    },
    {
      label: "+263   津巴布韦",
      value: "津巴布韦",
    },
    {
      label: "+504   洪都拉斯",
      value: "洪都拉斯",
    },
    {
      label: "+509   海地",
      value: "海地",
    },
    {
      label: "+61   澳大利亚",
      value: "澳大利亚",
    },
    {
      label: "+853   澳门",
      value: "澳门",
    },
    {
      label: "+353   爱尔兰",
      value: "爱尔兰",
    },
    {
      label: "+372   爱沙尼亚",
      value: "爱沙尼亚",
    },
    {
      label: "+1876   牙买加",
      value: "牙买加",
    },
    {
      label: "+1649   特克斯和凯科斯群岛",
      value: "特克斯和凯科斯群岛",
    },
    {
      label: "+1868   特立尼达和多巴哥",
      value: "特立尼达和多巴哥",
    },
    {
      label: "+591   玻利维亚",
      value: "玻利维亚",
    },
    {
      label: "+674   瑙鲁",
      value: "瑙鲁",
    },
    {
      label: "+46   瑞典",
      value: "瑞典",
    },
    {
      label: "+41   瑞士",
      value: "瑞士",
    },
    {
      label: "+590   瓜德罗普",
      value: "瓜德罗普",
    },
    {
      label: "+681   瓦利斯和富图纳群岛",
      value: "瓦利斯和富图纳群岛",
    },
    {
      label: "+678   瓦努阿图",
      value: "瓦努阿图",
    },
    {
      label: "+262   留尼汪",
      value: "留尼汪",
    },
    {
      label: "+375   白俄罗斯",
      value: "白俄罗斯",
    },
    {
      label: "+1441   百慕大",
      value: "百慕大",
    },
    {
      label: "+350   直布罗陀",
      value: "直布罗陀",
    },
    {
      label: "+500   福克兰群岛",
      value: "福克兰群岛",
    },
    {
      label: "+965   科威特",
      value: "科威特",
    },
    {
      label: "+269   科摩罗和马约特",
      value: "科摩罗和马约特",
    },
    {
      label: "+225   科特迪瓦",
      value: "科特迪瓦",
    },
    {
      label: "+51   秘鲁",
      value: "秘鲁",
    },
    {
      label: "+216   突尼斯",
      value: "突尼斯",
    },
    {
      label: "+370   立陶宛",
      value: "立陶宛",
    },
    {
      label: "+252   索马里",
      value: "索马里",
    },
    {
      label: "+962   约旦",
      value: "约旦",
    },
    {
      label: "+264   纳米比亚",
      value: "纳米比亚",
    },
    {
      label: "+683   纽埃岛",
      value: "纽埃岛",
    },
    {
      label: "+95   缅甸",
      value: "缅甸",
    },
    {
      label: "+40   罗马尼亚",
      value: "罗马尼亚",
    },
    {
      label: "+1   美国",
      value: "美国",
    },
    {
      label: "+1340   美属维京群岛",
      value: "美属维京群岛",
    },
    {
      label: "+1684   美属萨摩亚",
      value: "美属萨摩亚",
    },
    {
      label: "+856   老挝",
      value: "老挝",
    },
    {
      label: "+254   肯尼亚",
      value: "肯尼亚",
    },
    {
      label: "+358   芬兰",
      value: "芬兰",
    },
    {
      label: "+249   苏丹",
      value: "苏丹",
    },
    {
      label: "+597   苏里南",
      value: "苏里南",
    },
    {
      label: "+44   英国",
      value: "英国",
    },
    {
      label: "+1284   英属维京群岛",
      value: "英属维京群岛",
    },
    {
      label: "+31   荷兰",
      value: "荷兰",
    },
    {
      label: "+599   荷属安的列斯",
      value: "荷属安的列斯",
    },
    {
      label: "+258   莫桑比克",
      value: "莫桑比克",
    },
    {
      label: "+266   莱索托",
      value: "莱索托",
    },
    {
      label: "+63   菲律宾",
      value: "菲律宾",
    },
    {
      label: "+503   萨尔瓦多",
      value: "萨尔瓦多",
    },
    {
      label: "+685   萨摩亚",
      value: "萨摩亚",
    },
    {
      label: "+351   葡萄牙",
      value: "葡萄牙",
    },
    {
      label: "+976   蒙古",
      value: "蒙古",
    },
    {
      label: "+34   西班牙",
      value: "西班牙",
    },
    {
      label: "+229   贝宁",
      value: "贝宁",
    },
    {
      label: "+260   赞比亚",
      value: "赞比亚",
    },
    {
      label: "+240   赤道几内亚",
      value: "赤道几内亚",
    },
    {
      label: "+84   越南",
      value: "越南",
    },
    {
      label: "+994   阿塞拜疆",
      value: "阿塞拜疆",
    },
    {
      label: "+93   阿富汗",
      value: "阿富汗",
    },
    {
      label: "+213   阿尔及利亚",
      value: "阿尔及利亚",
    },
    {
      label: "+355   阿尔巴尼亚",
      value: "阿尔巴尼亚",
    },
    {
      label: "+971   阿拉伯联合酋长国",
      value: "阿拉伯联合酋长国",
    },
    {
      label: "+968   阿曼",
      value: "阿曼",
    },
    {
      label: "+54   阿根廷",
      value: "阿根廷",
    },
    {
      label: "+297   阿鲁巴",
      value: "阿鲁巴",
    },
    {
      label: "+82   韩国",
      value: "韩国",
    },
    {
      label: "+852   香港",
      value: "香港",
    },
    {
      label: "+389   马其顿",
      value: "马其顿",
    },
    {
      label: "+960   马尔代夫",
      value: "马尔代夫",
    },
    {
      label: "+265   马拉维",
      value: "马拉维",
    },
    {
      label: "+60   马来西亚",
      value: "马来西亚",
    },
    {
      label: "+692   马绍尔群岛",
      value: "马绍尔群岛",
    },
    {
      label: "+356   马耳他",
      value: "马耳他",
    },
    {
      label: "+261   马达加斯加",
      value: "马达加斯加",
    },
    {
      label: "+223   马里",
      value: "马里",
    },
    {
      label: "+961   黎巴嫩",
      value: "黎巴嫩",
    },
    {
      label: "+382   黑山共和国",
      value: "黑山共和国",
    },
  ],
  countryPhoneDefault: "+86",
};
