<template>
  <div id="app" @dragover.prevent @drop.prevent>
    <router-view v-if="isRouterAlive"></router-view>
    <waterMask v-if="token && watermask_switch"></waterMask>
  </div>
</template>

<script>
import Stomp from "stompjs";
import SockJS from "sockjs-client";
import { mapGetters, mapMutations } from "vuex";
import { unSeen } from "@/network/notification/index.js";
import { getToken } from "@/utils/auth";
import waterMask from "@/components/waterMask";
import { getWatermark } from "@/network/watermask";
import { sessionSave, sessionRead, sessionRemove } from "@/utils/session";

export default {
  name: "App",
  components: {
    waterMask,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  computed: {
    ...mapGetters(["token", "userAccountId", "watermask_switch"]),
    p_id() {
      return this.get_pid();
    },
    cookie_token() {
      return getToken();
    },
  },
  watch: {
    // 将vuex中的修改即时的同步到localstorage
    "$store.state": {
      handler: function () {
        localStorage.setItem("store", JSON.stringify(this.$store.state));
      },
      deep: true,
    },
    p_id(newv, oldv) {
      if (oldv) {
        this.$store.commit("SET_BASELINE_ID", "");
        this.$store.commit("SET_BASELINE_STATUS", "");
        this.$store.commit("SET_BASELINE_EDIT", false);
      }
      if (newv && this.token) {
        this.closeWebsocket();
        this.initWebsocket();
      }
    },
    cookie_token(val, oldVal) {
      if (!oldVal && val) {
        this.initWebsocket();
      }
      //如果原先由，现在没有了，说明退出登录，断开websocket
      if (oldVal && !val) {
        this.closeWebsocket();
      }
    },
  },
  created() {
    if (localStorage.getItem("store")) {
      sessionStorage.setItem(
        "role",
        JSON.stringify(
          JSON.parse(localStorage.getItem("store"))
            ? JSON.parse(localStorage.getItem("store")).user.roles
            : []
        )
      );
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(localStorage.getItem("store"))
        )
      );
    }
    window.addEventListener("storage", (e) => {
      this.$store.commit(
        "REFRESE_STATE",
        JSON.parse(localStorage.getItem("store"))
          ? JSON.parse(localStorage.getItem("store")).user
          : {}
      );
    });
    // 定义路径映射对象，键为手机端路径，值为PC端路径
    const routeMap = {
      "/login/login_password": "/phoneloginp",
      "/login/login_email": "/phonelogine",
      "/login/register": "/phoneloginr",
      "/login/forget_psd": "/phoneforget_psd",
      "/login/thirdparty": "/phonethirdparty",
    };
    
    this.$router.afterEach(()=>{
      if (this._isMobile()) {
        document.addEventListener("DOMContentLoaded", function () {
          document.body.style.overflowY = "auto";
        });
        // 如果是手机端
        this.isPhoneRoutes(routeMap);
      } else {
        // 如果是pc端
        this.isNoPhoneRoutes(routeMap);
      }
    })
  },
  mounted() {
    if (this.cookie_token) {
      getWatermark().then((res) => {
        this.SET_WATERMASK({
          waterMaskSwitch: res.switchFlag ? true : false,
          waterMaskValue: res.content ? res.content : "",
        });
      });
    }
  },
  methods: {
    ...mapMutations(["SET_WATERMASK"]),
    async initWebsocket() {
      await getWatermark();
      let socket = new SockJS(
        `${this.wsurl}${
          this.wsurl ? "" : "/prod-api"
        }/file-manage-service/sendServer?accountId=${this.userAccountId}`
      );
      this.stompClient = Stomp.over(socket);
      this.stompClient.connect(
        {
          token: this.token,
          projectId: this.get_pid(),
          scene: "NOTIFICATION_CENTER",
        }, //传递token
        (frame) => {
          // 测试topic
          this.stompClient.subscribe(
            `/user/queue/NOTIFICATION_CENTER`,
            (res) => {
              unSeen().then((res) => {
                if (res.unSeenNumber) {
                  this.$store.commit("SET_NOTICE", true);
                } else {
                  this.$store.commit("SET_NOTICE", false);
                }
                this.$store.commit("SET_NOTICE_NUM", res.unSeenNumber);
              });
            }
          );
        },
        (err) => {
          console.log("错误：" + err);
        }
      );
      this.stompClient.heartbeat.outgoing = 20000; //若使用STOMP 1.1 版本，默认开启了心跳检测机制（默认值都是10000ms）
      this.stompClient.heartbeat.incoming = 0; //客户端不从服务端接收心跳包
    },
    closeWebsocket() {
      if (this.stompClient) {
        try {
          this.stompClient.disconnect(() => {});
        } catch (e) {
          console.log(e);
        }
      }
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    // 判断是否是手机端，如果是，返回true
    _isMobile() {
      return !!navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    },
    // 手机端
    isPhoneRoutes(routeMap) {
      // 手机端路由跳转逻辑
      if (window.location.pathname.indexOf("phone") !== -1) {
        // 如果当前路由已经是手机端路由，不执行跳转
        return;
      }
      // 如果不是手机端路由，根据当前路由跳转至相应手机端路由
      const phoneRoute = routeMap[window.location.pathname];
      if (phoneRoute) {
        this._redirectWithParams({ name: phoneRoute });
      } else if (
        window.location.pathname.indexOf("/login/acceptInvitation") !== -1
      ) {
        this._redirectWithParams({ name: "/phone_acceptInvitation" });
      }
    },
    // PC端
    isNoPhoneRoutes(routeMap) {
      // pc端路由跳转逻辑
      if (window.location.pathname.indexOf("phone") === -1) {
        // 如果当前路由已经是pc端路由，不执行跳转
        return;
      }
      // 如果不是移动端路由，根据当前路由跳转至相应移动端路由
      const pcRoute = Object.keys(routeMap).find(
        (key) => routeMap[key] === window.location.pathname
      );
      if (pcRoute) {
        this._redirectWithParams({ name: pcRoute });
      } else if (
        window.location.pathname.indexOf("/phone_acceptInvitation") !== -1
      ) {
        this._redirectWithParams({ name: "/login/acceptInvitation" });
      }      
    },
    // 路径判断
    _redirectWithParams(route) {
      if (window.location.pathname.indexOf("/login/acceptInvitation/") !== -1) {
        const url = window.location.pathname.replace(
          "/login/acceptInvitation/",
          "/phone_acceptInvitation/"
        );
        this.$router.push({ path: url });
      } else if (
        window.location.pathname.indexOf("/phone_acceptInvitation/") !== -1
      ) {
        const url = window.location.pathname.replace(
          "/phone_acceptInvitation/",
          "/login/acceptInvitation/"
        );
        this.$router.push({ path: url });
      } else {
        if (window.location.search) {
          // 如果当前路由有参数，则传递参数进行跳转
          this.$router.push({ path: route.name + window.location.search });
        } else {
          // 如果当前路由没有参数，则直接跳转
          this.$router.push({ path: route.name });
        }
      }
    },
  },
};
</script>
<style>
#app {
  font-family: "思源黑体" !important;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}
html {
  overflow: -moz-hidden-unscrollable;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
body::-webkit-scrollbar {
  display: none;
}
a {
  text-decoration: none;
}
</style>
