import request from "@/utils/request"
import serviceConfig from '@/network/serviceConfig'
// 获取项目组下面的测试计划
export function get_test_plans(projectId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/testPlans`,
    method: "get",
  })
}

// 新建测试计划
export function create_test_plans(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/testPlans`,
    method: "post",
    data,
  })
}

// 修改测试计划
export function edit_test_plan(projectId, testPlanId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/testPlans/${testPlanId}`,
    method: "put",
    data,
  })
}

// 删除测试计划
export function delete_test_plan(projectId, testPlanId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/testPlans/${testPlanId}`,
    method: "delete"
  })
}

// 进入执行模式
export function enter_process(projectId, testPlanId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/testPlans/${testPlanId}/process`,
    method: 'put'
  })
}
// 获取测试报告字段
export function testReportFields(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/testReportFields`,
    method: 'get'
  })
}
// 获取测试报告字段
export function testOverviewFields(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/testOverviewFields/${params.type}`,
    method: 'get'
  })
}
//复制测试计划
export function copy_test_plan(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/testPlans/${params.testPlanId}/copy`,
    method: 'post',
    data: params.data
  })
}
// 获取测试概览
export function testOverview(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/testPlans/${params.testPlanId}/testOverview/${params.testOverviewType}`,
    data: params.data,
    method: 'post'
  })
}
// 获取测试概览选项
export function testOverviewOptions(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/testPlans/${params.testPlanId}/testOverviewOptions/${params.testOverviewType}`,
    method: 'get'
  })
}
// 获取测试概览选项
export function savetestOverviewOptions(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/testPlans/${params.testPlanId}/testOverviewOptions`,
    data: params.data,
    method: 'put'
  })
}
// 刷新关联版本中的测试用例
export function refreshTestCaseFromVersion(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/testPlans/${params.testPlanId}/refreshTestCaseFromVersion`,
    method: 'put'
  })
}

export function updateTestPlanBatch(projectId, nodeKey, testPlanIds) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/${nodeKey}/testPlans`,
    method: 'put',
    data: testPlanIds
  })
}

export function testCaseStatus(projectId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/testCaseStatus`,
    method: 'get'
  })
}

export function add_testCaseStatus(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/testCaseStatus`,
    method: 'post',
    data: data
  })
}

export function del_testCaseStatus(projectId, statusId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/testCaseStatus/${statusId}`,
    method: 'Delete',
  })
}

export function change_testCaseStatus(projectId, statusId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/testCaseStatus/${statusId}`,
    method: 'PUT',
    data: data
  })
}
export function get_single_testPlans(projectId, testPlanId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/testPlans/${testPlanId}`,
    method: 'get',
  })
}